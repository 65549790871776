import React from 'react'
import { useDispatch } from 'react-redux'

import { clearAppointmentConfirmationError } from '../../../store/duck/appointmentConfirmation'
import ErrorScreen from '../ErrorScreen'

const AppointmentConfirmationErrorScreen = (props) => {
  const dispatch = useDispatch()

  const onNavigate = () => {
    dispatch(clearAppointmentConfirmationError())
  }

  return <ErrorScreen onNavigate={onNavigate} {...props} />
}

export default AppointmentConfirmationErrorScreen
