/* eslint-disable simple-import-sort/imports */
// english namespaces imports
import AbbreviationsEnUS from './en-US/Abbreviations.json'
import ClientsEnUS from './en-US/Clients.json'
import CommonEnUS from './en-US/Common.json'
import ErrorEnUS from './en-US/Error.json'
import MembershipEnUS from './en-US/Membership.json'
import PaymentEnUS from './en-US/Payment.json'
import UtilsEnUS from './en-US/Utils.json'

// spanish (castelian) namespaces imports
import AbbreviationsCaES from './ca-ES/Abbreviations.json'
import ClientsCaES from './ca-ES/Clients.json'
import CommonCaES from './ca-ES/Common.json'
import ErrorCaES from './ca-ES/Error.json'
import MembershipCaES from './ca-ES/Membership.json'
import PaymentCaES from './ca-ES/Payment.json'
import UtilsCaES from './ca-ES/Utils.json'

// spanish namespaces imports
import AbbreviationsEsES from './es-ES/Abbreviations.json'
import ClientsEsES from './es-ES/Clients.json'
import CommonEsES from './es-ES/Common.json'
import ErrorEsES from './es-ES/Error.json'
import MembershipEsES from './es-ES/Membership.json'
import PaymentEsES from './es-ES/Payment.json'
import UtilsEsES from './es-ES/Utils.json'

// portuguese (brazil) namespaces imports
import AbbreviationsPtBR from './pt-BR/Abbreviations.json'
import ClientsPtBR from './pt-BR/Clients.json'
import CommonPtBR from './pt-BR/Common.json'
import ErrorPtBR from './pt-BR/Error.json'
import MembershipPtBR from './pt-BR/Membership.json'
import PaymentPtBR from './pt-BR/Payment.json'
import UtilsPtBR from './pt-BR/Utils.json'

// portuguese namespaces imports
import AbbreviationsPtPT from './pt-PT/Abbreviations.json'
import ClientsPtPT from './pt-PT/Clients.json'
import CommonPtPT from './pt-PT/Common.json'
import ErrorPtPT from './pt-PT/Error.json'
import MembershipPtPT from './pt-PT/Membership.json'
import PaymentPtPT from './pt-PT/Payment.json'
import UtilsPtPT from './pt-PT/Utils.json'

export const TRANSLATIONS_EN_US = {
  Common: CommonEnUS,
  Clients: ClientsEnUS,
  Membership: MembershipEnUS,
  Utils: UtilsEnUS,
  Error: ErrorEnUS,
  Abbreviations: AbbreviationsEnUS,
  Payment: PaymentEnUS,
}

export const TRANSLATIONS_ES_ES = {
  Common: CommonEsES,
  Clients: ClientsEsES,
  Membership: MembershipEsES,
  Utils: UtilsEsES,
  Error: ErrorEsES,
  Abbreviations: AbbreviationsEsES,
  Payment: PaymentEsES,
}

export const TRANSLATIONS_CA_ES = {
  Common: CommonCaES,
  Clients: ClientsCaES,
  Membership: MembershipCaES,
  Utils: UtilsCaES,
  Error: ErrorCaES,
  Abbreviations: AbbreviationsCaES,
  Payment: PaymentCaES,
}

export const TRANSLATIONS_PT_PT = {
  Common: CommonPtPT,
  Clients: ClientsPtPT,
  Membership: MembershipPtPT,
  Utils: UtilsPtPT,
  Error: ErrorPtPT,
  Abbreviations: AbbreviationsPtPT,
  Payment: PaymentPtPT,
}

export const TRANSLATIONS_PT_BR = {
  Common: CommonPtBR,
  Clients: ClientsPtBR,
  Membership: MembershipPtBR,
  Utils: UtilsPtBR,
  Error: ErrorPtBR,
  Abbreviations: AbbreviationsPtBR,
  Payment: PaymentPtBR,
}
