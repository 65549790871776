import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  getInvoice,
  updateHasAgreedToPay,
} from '../../../../store/duck/finance'
import { useNavigateWithQueryString } from '../../../../utils'
import KioskScreen from '../../KioskScreen'
import PayButtonSection from '../PayButtonSection'
import InvoiceSummary from './InvoiceSummary'
import InvoiceTable from './InvoiceTable'
import InvoiceTitle from './InvoiceTitle'
import MultiInvoiceSummary from './MultiInvoiceSummary'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(var(--app-height) - 278px)',
      },
      overflowY: 'auto',
      maxHeight: 'calc(var(--app-height) - 317px)',
      ...theme.constants.scrollbar,
    },
  }),
  { name: 'InvoiceScreen' },
)

const InvoiceScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const invoice = useSelector(getInvoice)

  const invoices = invoice ? invoice.invoices || [invoice] : []

  const proceed = () => {
    dispatch(updateHasAgreedToPay(true))
    navigateWithQueryString({ url: '/payment/billing-address' })
  }

  return (
    <KioskScreen alignItems="flex-start" justifyContent="flex-start">
      <Grid
        container
        item
        className={classes.tableContainer}
        direction="column"
        wrap="nowrap"
      >
        {invoices.map((invoice) => (
          <React.Fragment key={invoice.invoiceNo}>
            <InvoiceTitle invoice={invoice} />
            <InvoiceTable invoice={invoice} />
            {invoices.length > 1 && <InvoiceSummary invoice={invoice} />}
          </React.Fragment>
        ))}
        <MultiInvoiceSummary invoice={invoice} />
      </Grid>
      <PayButtonSection onProceed={proceed} />
    </KioskScreen>
  )
}

export default InvoiceScreen
