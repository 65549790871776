import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { moment, useInterval } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
import KioskButtonWithLoader from './KioskButtonWithLoader'

const JoinWaitlistButton = (props) => {
  const { t } = useTranslation('Common')

  const { waitListEnabled, workingHours } =
    useSelector(getCurrentBusiness) || {}

  const [currentTime, setCurrentTime] = useState(moment())

  useInterval(() => {
    setCurrentTime(moment())
  }, 5000)

  if (
    !waitListEnabled ||
    !workingHours ||
    currentTime.isBefore(workingHours.from) ||
    currentTime.isAfter(workingHours.to)
  ) {
    return null
  }

  return (
    <KioskButtonWithLoader color="secondary" {...props}>
      {t('Common:JOIN_WAITLIST')}
    </KioskButtonWithLoader>
  )
}

export default JoinWaitlistButton
