import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper } from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'

const useStyles = makeStyles(
  (theme) => ({
    popper: {
      [theme.breakpoints.up('md')]: {
        width: 650,
      },
    },
    content: {
      padding: theme.spacing(2, 10, 5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 2, 5),
      },
    },
    icon: {
      fontSize: '3.8rem',
      color: theme.colors.alertSuccess,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(3),
      width: 265,
      height: 56,
    },
  }),
  { name: 'EmailSuccessPopper' },
)

const EmailSuccessPopper = ({ onProceed, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <PuiPopper
      classes={{
        paper: classes.popper,
      }}
      {...rest}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.content}
        direction="column"
      >
        <SuccessAlert className={classes.icon} />
        <Typography variant="body2">{t('Common:EMAIL_SENT')}</Typography>
        <KioskButtonWithLoader className={classes.button} onClick={onProceed}>
          {t('Common:FINISH_ACTION')}
        </KioskButtonWithLoader>
      </Grid>
    </PuiPopper>
  )
}

export default EmailSuccessPopper
