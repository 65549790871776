import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { update } from 'ramda'
import { QuestionView } from '@pbt/pbt-ui-components'

import { getAnswerInputTypes } from '../../store/duck/constants'
import {
  fetchQuestionsLog,
  getQuestionsIsLoading,
  getQuestionsLog,
  saveQuestionsLog,
} from '../../store/duck/questions'
import { getCurrentAppointment } from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      padding: theme.spacing(4, 5, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    text: {
      fontSize: '1.6rem',
    },
    stepperText: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    questionViewRoot: {
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    questionViewText: {
      color: theme.colors.primaryText,
      fontSize: '2.6rem',
      fontWeight: 500,
    },
    questionViewRadioLabel: {
      fontSize: '1.8rem',
    },
  }),
  { name: 'QuestionsScreen' },
)

const QuestionsScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const questionsList = useSelector(getQuestionsLog)
  const isLoading = useSelector(getQuestionsIsLoading)
  const appointment = useSelector(getCurrentAppointment) || {}
  const AnswerInputTypes = useSelector(getAnswerInputTypes)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questions, setQuestions] = useState(questionsList)

  const appointmentId = appointment.id

  const onLogSaved = () => {
    navigateWithQueryString({ url: '/documents' })
  }

  const onLogLoaded = () => {
    if (questionsList.length === 0) {
      navigateWithQueryString({ url: '/documents' })
    }
  }

  const callbackWhenSaved = useCallbackWhenLoaded(
    onLogSaved,
    getQuestionsIsLoading,
  )
  const callbackWhenLoaded = useCallbackWhenLoaded(
    onLogLoaded,
    getQuestionsIsLoading,
  )

  useEffect(() => {
    callbackWhenLoaded()
    dispatch(fetchQuestionsLog(appointmentId))
  }, [appointmentId])

  useEffect(() => {
    setQuestions(questionsList)
  }, [questionsList])

  const onBack = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const onProceed = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      callbackWhenSaved()
      dispatch(saveQuestionsLog(appointmentId, questions))
    }
  }

  const onQuestionAnswered = (newQuestion) => {
    const oldQuestionIndex = questions.findIndex(
      ({ id }) => id === newQuestion.id,
    )
    const newQuestions = update(oldQuestionIndex, newQuestion, questions)

    setQuestions(newQuestions)
  }

  const currentQuestion = questions[currentQuestionIndex]
  const questionAnswered = (currentQuestion?.answers || []).some(
    (answer) => answer.selected,
  )
  const isFirstQuestion = currentQuestionIndex === 0

  return (
    <KioskScreen
      hideBackButton={isFirstQuestion}
      justifyContent="flex-start"
      proceedButtonDisabled={!questionAnswered}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      proceedButtonLoading={isLoading}
      onBack={isFirstQuestion ? undefined : onBack}
      onProceed={onProceed}
    >
      <Grid
        container
        item
        alignItems={isLoading ? 'center' : 'stretch'}
        className={classes.root}
        direction="column"
        justifyContent={isLoading ? 'center' : 'flex-start'}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              align={isMobile ? 'left' : 'center'}
              className={classes.stepperText}
            >
              {`${t('Common:QUESTION')} ${currentQuestionIndex + 1}/${
                questions.length
              }`}
            </Typography>
            <QuestionView
              AnswerInputTypes={AnswerInputTypes}
              classes={{
                root: classes.questionViewRoot,
                text: classes.questionViewText,
                radioLabel: classes.questionViewRadioLabel,
              }}
              question={currentQuestion}
              onAnswered={onQuestionAnswered}
            />
          </>
        )}
      </Grid>
    </KioskScreen>
  )
}

export default QuestionsScreen
