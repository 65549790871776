import * as R from 'ramda'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

export const getErrorMessage = (
  error?: ApiError | string | null,
  defaultMessage?: string,
): string | null =>
  R.path(['response', 'data', 'description'], error) ||
  R.path(['response', 'data', 'error', 'message'], error) ||
  R.path(['message'], error) ||
  R.path(['originalMessage'], error) ||
  error?.toString?.() ||
  defaultMessage ||
  Defaults.DEFAULT_RESPONSE_ERROR_MESSAGE
