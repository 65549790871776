import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LocationOn } from '@mui/icons-material'
import { Grid, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AddressUtils, PhoneUtils, Utils } from '@pbt/pbt-ui-components'
import { Chain, Phone } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      paddingTop: theme.spacing(3),
    },
    text: {
      fontSize: '1.8rem',
    },
    button: {
      marginTop: theme.spacing(4),
      width: 285,
    },
    icon: {
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'ContactUsScreen' },
)

interface ContactUsScreenProps {
  readonly children: React.ReactNode
  readonly hideMessageButton?: boolean
}

const ContactUsScreen = ({
  hideMessageButton = false,
  children,
}: ContactUsScreenProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const business = useSelector(getCurrentBusiness)

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      justifyContent="flex-start"
    >
      {children}
      {!hideMessageButton && business?.email && (
        <KioskButtonWithLoader
          className={classes.button}
          // @ts-ignore
          component="a"
          href={`mailto:${business.email}`}
        >
          {t('Common:SEND_US_MESSAGE')}
        </KioskButtonWithLoader>
      )}
      {business?.website && (
        <Grid container item alignItems="center" direction="column" mt={5}>
          <Chain className={classes.icon} />
          <Link
            className={classes.text}
            color="secondary"
            href={Utils.toExternalUrl(business.website)}
            rel="noopener noreferrer"
            target="_blank"
            underline="always"
          >
            {Utils.getUrlHost(business.website)}
          </Link>
        </Grid>
      )}
      {business?.phone && (
        <Grid container item alignItems="center" direction="column" mt={5}>
          <Phone className={classes.icon} />
          <Typography
            className={classes.text}
            component="a"
            href={`callto:${business.email}`}
            variant="body2"
          >
            {PhoneUtils.getPhoneNumber(business.phone)}
          </Typography>
        </Grid>
      )}
      {business?.address && (
        <Grid container item alignItems="center" direction="column" mt={5}>
          <LocationOn className={classes.icon} />
          <Typography align="center" className={classes.text} variant="body2">
            {AddressUtils.formatAddress(business, true)}
          </Typography>
        </Grid>
      )}
    </KioskScreen>
  )
}

export default ContactUsScreen
