import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

// @ts-ignore
import { getPatientsIsLoading } from '../../../store/duck/patients'
import {
  checkInForAppointment,
  getCurrentAppointment,
} from '../../../store/duck/schedules'
import { Appointment } from '../../../types/entities/appointments'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
// @ts-ignore
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
// @ts-ignore
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
// @ts-ignore
import AppointmentInfoSection from '../../common/AppointmentInfoSection'
// @ts-ignore
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      width: 224,
      marginTop: theme.spacing(5),
    },
  }),
  { name: 'CheckInScreen' },
)

const CheckInScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const navigateWithQueryString = useNavigateWithQueryString()
  const theme = useTheme()

  const appointment = useSelector(getCurrentAppointment) as Appointment | null

  const onCheckedIn = () => {
    navigateWithQueryString({ url: '/success' })
  }

  const callbackWhenLoadedAppointment = useCallbackWhenLoaded(
    onCheckedIn,
    getPatientsIsLoading,
  )

  const onFinish = (arrived: boolean) => {
    if (appointment) {
      callbackWhenLoadedAppointment()
      dispatch(checkInForAppointment(appointment.id, arrived))
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      title={t('Common:HAVE_YOU_ARRIVED_FOR_YOUR_APPOINTMENT')}
    >
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-around"
        sx={{
          padding: {
            xs: theme.spacing(2),
            sm: theme.spacing(11, 4, 2),
          },
        }}
      >
        <AppointmentInfoSection appointment={appointment} />
        <KioskButtonWithLoader
          className={classes.button}
          onClick={() => onFinish(true)}
        >
          {t('Common:I_HAVE_ARRIVED')}
        </KioskButtonWithLoader>
        <KioskButtonWithLoader
          className={classes.button}
          onClick={() => onFinish(false)}
        >
          {t('Common:NOT_YET')}
        </KioskButtonWithLoader>
      </Grid>
    </KioskScreen>
  )
}

export default CheckInScreen
