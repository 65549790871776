import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, GridProps, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Nil,
  NumberUtils,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import { getWellnessPlansVersion } from '../../../store/duck/wellnessPlans'

const useStyles = makeStyles(
  (theme) => ({
    totalText: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
    },
  }),
  { name: 'MembershipTotalsLabel' },
)

interface MembershipTotalsLabelProps {
  readonly alignItems?: GridProps['alignItems']
  readonly className?: string
  readonly classes?: ClassesType<typeof useStyles>
  readonly showLabel?: boolean
  readonly totals: Record<string, number>
}

const MembershipTotalsLabel = ({
  alignItems = 'flex-end',
  className,
  classes: classesProp,
  totals,
  showLabel = true,
}: MembershipTotalsLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const wellnessPlansVersion: WellnessPlanVersion | Nil = useSelector(
    getWellnessPlansVersion,
  )

  const allPlans = wellnessPlansVersion?.plans || []

  const getPriceTypeName = (priceTypeId: string) =>
    allPlans.find((plan) => plan.priceTypeId === priceTypeId)?.priceType
      ?.displayName || ''

  const priceTypeIds = Object.keys(totals).filter(
    (priceTypeId) => totals[priceTypeId],
  )

  return (
    <Grid
      container
      item
      xs
      alignItems={alignItems}
      className={className}
      direction="column"
    >
      {priceTypeIds.map((priceTypeId, index) => {
        const priceType = getPriceTypeName(priceTypeId)
        const price = NumberUtils.formatMoney(totals[priceTypeId])

        return (
          <Typography className={classes.totalText} key={priceTypeId}>
            {showLabel && (index === 0 ? `${t('Common:TOTAL')}: ` : '')}
            {`${price}/${priceType}`}
          </Typography>
        )
      })}
    </Grid>
  )
}

export default MembershipTotalsLabel
