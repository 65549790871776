import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { prop } from 'ramda'
import { Check as CheckIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentBusiness } from '../../../store/duck/businesses'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      cursor: 'pointer',
      padding: theme.spacing(1, 2),
      height: 40,
      backgroundColor: theme.colors.tableBackground,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      flex: 1,
    },
    check: {
      marginRight: theme.spacing(1),
      color: prop('buttonsColor') || theme.colors.alertLabelError,
    },
  }),
  { name: 'WellnessPlanBenefitRow' },
)

const WellnessPlanBenefitRow = ({ className, name, benefit }) => {
  const business = useSelector(getCurrentBusiness) || {}
  const { customColorsEnabled, buttonsColor } = business
  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <CheckIcon className={classes.check} />
      <Dotdotdot clamp={1}>
        <Typography variant="body2">{name || benefit.name}</Typography>
      </Dotdotdot>
    </Grid>
  )
}

export default WellnessPlanBenefitRow
