import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils, TextWithTooltip } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../../store/duck/businesses'
import { getFinanceBalance } from '../../../../store/duck/finance'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      borderTop: theme.constants.tableBorder,
      marginTop: theme.spacing(2),
    },
    text: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    totalText: {
      fontWeight: 500,
      color: theme.colors.primaryText,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summary: {
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
      padding: theme.spacing(2),
      backgroundColor: theme.colors.tableEvenItem,
    },
    summaryItem: {
      flexShrink: 0,
    },
    footnoteContainer: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'MultiInvoiceSummary' },
)

const MultiInvoiceSummary = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const business = useSelector(getCurrentBusiness)
  const balance = useSelector(getFinanceBalance)

  const {
    previousBalance,
    subtotal,
    discount,
    serviceFeeAmount,
    totalTax,
    totalWithServiceFee,
  } = balance || {}
  const { invoiceNotes } = business || {}

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item xs className={classes.footnoteContainer}>
        {invoiceNotes && (
          <TextWithTooltip
            link
            noIcon
            display="inline"
            tooltipText={invoiceNotes}
            variant="lowAccent3"
          >
            {t('Common:FOOTNOTE')}
          </TextWithTooltip>
        )}
      </Grid>
      <Grid
        container
        item
        className={classes.summary}
        direction="column"
        justifyContent="center"
        wrap="nowrap"
      >
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:PREVIOUS_BALANCE')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(previousBalance)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:SUBTOTAL')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(subtotal)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:DISCOUNT')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(discount, true)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>{t('Common:TAX')}</Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(totalTax)}
          </Typography>
        </Grid>
        <Divider className={classes.divider} />
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:SERVICE_FEE')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(serviceFeeAmount)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classNames(classes.text, classes.totalText)}>
            {t('Common:TOTAL_WITH_SERVICE_FEE')}
          </Typography>
          <Typography className={classNames(classes.text, classes.totalText)}>
            {NumberUtils.formatMoney(totalWithServiceFee)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MultiInvoiceSummary
