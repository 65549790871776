import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCurrentBusiness } from '../../store/duck/businesses'
import { useNavigateWithQueryString } from '../../utils'
import KioskButtonWithLoader from './KioskButtonWithLoader'

const KioskMembershipSignUpButton = (props) => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const currentBusiness = useSelector(getCurrentBusiness)

  const wellnessPlansEnabledInBusiness = currentBusiness?.wellnessPlansEnabled
  const hasActiveWellnessPlansInBusiness =
    currentBusiness?.hasActiveWellnessPlans
  const wellnessPlansInKioskEnabled =
    currentBusiness?.wellnessPlansInKioskEnabled

  if (
    !wellnessPlansEnabledInBusiness ||
    !hasActiveWellnessPlansInBusiness ||
    !wellnessPlansInKioskEnabled
  ) {
    return null
  }

  const handleClick = () => {
    navigateWithQueryString({ url: '/membership/browse-plans' })
  }

  return (
    <KioskButtonWithLoader color="secondary" onClick={handleClick} {...props}>
      {t('Common:SIGN_UP_FOR_WELLNESS_PLAN')}
    </KioskButtonWithLoader>
  )
}

export default KioskMembershipSignUpButton
