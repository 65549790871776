import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { Utils } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../store/duck/clients'
import { getAppointmentTypes } from '../../store/duck/constants'
import {
  getIsCheckInFlow,
  getIsJoinWaitlistFlow,
  getIsScheduleAppointmentFlow,
} from '../../store/duck/flow'
import { updateCurrentPatient } from '../../store/duck/patients'
import { getCurrentAppointment } from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import PatientsList from '../patient/PatientsList'
import KioskScreen from './KioskScreen'

const MissingPetsScreen = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const client = useSelector(getCurrentClient)
  const appointment = useSelector(getCurrentAppointment)
  const isCheckIn = useSelector(getIsCheckInFlow)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)
  const isScheduleAppointment = useSelector(getIsScheduleAppointmentFlow)
  const AppointmentTypes = useSelector(getAppointmentTypes)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const BoardingType = Utils.findConstantIdByName('Boarding', AppointmentTypes)

  const isBoarding = appointment?.type?.id === BoardingType

  const proceed = () => {
    if (isCheckIn) {
      const patient = Utils.findById(appointment?.patient?.id, client.patients)
      dispatch(updateCurrentPatient(patient))
      if (patient.needsUpdate) {
        navigateWithQueryString({ url: '/patient-details' })
      } else if (isBoarding) {
        navigateWithQueryString({ url: '/patient-diet-and-allergies' })
      } else {
        navigateWithQueryString({ url: '/questions' })
      }
    } else if (isWaitlist) {
      navigateWithQueryString({ url: '/select-patient' })
    } else if (isScheduleAppointment) {
      navigateWithQueryString({ url: '/appointment-message/confirmation' })
    }
  }

  return (
    <KioskScreen
      justifyContent={isMobile ? 'flex-start' : 'center'}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      title={t(
        'Common:MAKE_SURE_WE_HAVE_ALL_PETS_IN_YOUR_FAMILY_ANYONE_MISSING',
      )}
      onProceed={proceed}
    >
      <PatientsList />
    </KioskScreen>
  )
}

export default MissingPetsScreen
