import AppointmentConfirmationMessageScreen from './screens/AppointmentConfirmationMessageScreen'
import AppointmentTypeScreen from './screens/AppointmentTypeScreen'
import AppointmentZoomLinkMessageScreen from './screens/AppointmentZoomLinkMessageScreen'
import CheckInScreen from './screens/checkin/CheckInScreen'
import ClientAddressScreen from './screens/ClientAddressScreen'
import ClientDetailsScreen from './screens/ClientDetailsScreen'
import ClientNotFoundScreen from './screens/ClientNotFoundScreen'
import ClientTypeSelectionScreen from './screens/ClientTypeSelectionScreen'
import { ConsentFormScreen } from './screens/consent-forms/ConsentFormScreen'
import { ConsentFormThankYouScreen } from './screens/consent-forms/ConsentFormThankYouScreen'
import DocumentsScreen from './screens/documents/DocumentsScreen'
import EmailScreen from './screens/EmailScreen'
import ErrorScreen from './screens/ErrorScreen'
import GdprTermsEsScreen from './screens/GdprTermsEsScreen'
import KioskCalendarScreen from './screens/KioskCalendarScreen'
import KioskPatientDetailsScreen from './screens/KioskPatientDetailsScreen'
import MessageSentSuccessScreen from './screens/MessageSentSuccessScreen'
import MissingPetsScreen from './screens/MissingPetsScreen'
import NewClientScreen from './screens/NewClientScreen'
import PatientDietAndAllergiesScreen from './screens/PatientDietAndAllergiesScreen'
import PatientMicrochippedScreen from './screens/PatientMicrochippedScreen'
import PhoneNumberScreen from './screens/PhoneNumberScreen'
import PrimaryComplaintScreen from './screens/PrimaryComplaintScreen'
import QuestionsScreen from './screens/QuestionsScreen'
import SelectPatientScreen from './screens/SelectPatientScreen'
import SuccessScreen from './screens/SuccessScreen'
import TelemedicineScreen from './screens/TelemedicineScreen'
import UserFeedback from './screens/UserFeedback'
import WelcomeScreen from './screens/welcome/WelcomeScreen'

/* root path: / */

export default [
  { Component: CheckInScreen, path: 'check-in' },
  { Component: DocumentsScreen, path: 'documents' },
  { Component: TelemedicineScreen, path: 'telemedicine' },
  { Component: ClientTypeSelectionScreen, path: 'client-type-selection' },
  { Component: ClientDetailsScreen, path: 'client-details' },
  { Component: ClientAddressScreen, path: 'client-address' },
  { Component: ConsentFormScreen, path: 'consent-forms' },
  { Component: ConsentFormThankYouScreen, path: 'consent-forms-complete' },
  { Component: SelectPatientScreen, path: 'select-patient' },
  { Component: MissingPetsScreen, path: 'any-more-pets' },
  { Component: KioskPatientDetailsScreen, path: 'patient-details' },
  {
    Component: PatientDietAndAllergiesScreen,
    path: 'patient-diet-and-allergies',
  },
  { Component: PatientMicrochippedScreen, path: 'patient-microchipped' },
  { Component: AppointmentTypeScreen, path: 'appointment-type' },
  {
    Component: AppointmentConfirmationMessageScreen,
    path: 'appointment-message/confirmation',
  },
  {
    Component: AppointmentZoomLinkMessageScreen,
    path: 'appointment-message/zoom-link',
  },
  {
    Component: MessageSentSuccessScreen,
    path: 'success/communications/message',
  },
  { Component: SuccessScreen, path: 'success' },
  { Component: PrimaryComplaintScreen, path: 'primary-complaint' },
  { Component: KioskCalendarScreen, path: 'calendar' },
  { Component: NewClientScreen, path: 'new-client' },
  { Component: GdprTermsEsScreen, path: 'gdpr-terms-es' },
  { Component: QuestionsScreen, path: 'questions' },
  { Component: ClientNotFoundScreen, path: 'not-found' },
  { Component: PhoneNumberScreen, path: 'phone' },
  { Component: EmailScreen, path: 'email' },
  { Component: ErrorScreen, path: 'error' },
  { Component: UserFeedback, path: 'nps/:token', isRoot: true },
  { Component: WelcomeScreen, path: '/*', isRoot: true },
]
