import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

// @ts-ignore
import * as API from '../../api'
import type { RootState } from '../index'
import { fetchBusinessSuccess } from './businesses'

type FeedbackBusinessInfo = {
  address1: string
  address2: string
  city: string
  googleReviewLink: string
  logo: string
  name: string
  primaryPhone: string
  secondaryPhone: string
  website: string
  zip: string
}

type NPSData = {
  feedbackBusinessInfo?: Partial<FeedbackBusinessInfo>
  feedbackDate?: string
  modificationKey?: string
  score?: number
  vetTechName?: string
  veterinarianName?: string
}

export type UpdateNPSData = {
  modificationKey: string
  score: number
}

export const FETCH_NPS_DATA = 'FETCH_NPS_DATA'
export const FETCH_NPS_DATA_SUCCESS = 'FETCH_NPS_DATA_SUCCESS'
export const FETCH_NPS_DATA_FAILURE = 'FETCH_NPS_DATA_FAILURE'

export const UPDATE_NPS_DATA = 'UPDATE_NPS_DATA'
export const UPDATE_NPS_DATA_SUCCESS = 'UPDATE_NPS_DATA_SUCCESS'
export const UPDATE_NPS_DATA_FAILURE = 'UPDATE_NPS_DATA_FAILURE'

export const fetchNPSData = (token: string, score: number) => ({
  type: FETCH_NPS_DATA,
  token,
  score,
})
export const fetchNPSDataSuccess = (npsData: NPSData) => ({
  type: FETCH_NPS_DATA_SUCCESS,
  npsData,
})
export const fetchNPSDataFailure = (error: ApiError) => ({
  type: FETCH_NPS_DATA_FAILURE,
  error,
})

export const updateNPSData = (token: string, updateNpsData: UpdateNPSData) => ({
  type: UPDATE_NPS_DATA,
  updateNpsData,
  token,
})
export const updateNPSDataSuccess = (updateNpsData: UpdateNPSData) => ({
  type: UPDATE_NPS_DATA_SUCCESS,
  updateNpsData,
})
export const updateNPSDataFailure = (error: ApiError) => ({
  type: UPDATE_NPS_DATA_FAILURE,
  error,
})

type NPSState = {
  error?: string
  loading: boolean
  npsData?: NPSData
}

const INITIAL_STATE: NPSState = {
  error: undefined,
  loading: false,
  npsData: undefined,
}

export const npsReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
): NPSState => {
  switch (action.type) {
    case FETCH_NPS_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_NPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        npsData: action.npsData,
      }
    case UPDATE_NPS_DATA:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_NPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        npsData: {
          ...state.npsData,
          feedbackDate: new Date().toDateString(),
          modificationKey: action.updateNpsData.modificationKey,
          score: action.updateNpsData.score,
        },
      }
    default:
      return state
  }
}

export const getNPS = (state: RootState): NPSState => state.nps
export const getNPSData = (state: RootState): NPSData | undefined =>
  getNPS(state).npsData
export const getNPSDataLoading = (state: RootState): boolean =>
  getNPS(state).loading

export function* sagaFetchNPSData({
  token,
  score,
}: ReturnType<typeof fetchNPSData>) {
  try {
    const npsData: NPSData = yield call(API.fetchNPSData, token, score)
    const { feedbackBusinessInfo } = npsData || {}
    yield put(fetchNPSDataSuccess(npsData))
    if (feedbackBusinessInfo) {
      yield put(fetchBusinessSuccess(feedbackBusinessInfo))
    }
  } catch (error) {
    yield put(fetchNPSDataFailure(error as ApiError))
    const {
      response: { status, data },
    } = (error as ApiError) || { response: {} }
    if (status === 403) {
      yield put(fetchBusinessSuccess(data))
    }
  }
}

export function* sagaUpdateNPSData({
  token,
  updateNpsData,
}: ReturnType<typeof updateNPSData>) {
  try {
    const npsData: UpdateNPSData = yield call(API.updateNPSData, {
      token,
      updateNpsData,
    })
    yield put(updateNPSDataSuccess(npsData))
  } catch (error) {
    yield put(updateNPSDataFailure(error as ApiError))
  }
}

function* watchFetchNPS() {
  yield takeLatest(FETCH_NPS_DATA, sagaFetchNPSData)
  yield takeLatest(UPDATE_NPS_DATA, sagaUpdateNPSData)
}

export function* npsSaga() {
  yield all([watchFetchNPS()])
}
