import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  ImageScalingUtils,
  moment,
  PatientAvatar,
  Utils,
} from '@pbt/pbt-ui-components'

import Features from '../../constants/features'
import { getCurrentBusiness } from '../../store/duck/businesses'
import { getFeatureToggle, getSpecies } from '../../store/duck/constants'
import { getIsJoinWaitlistFlow } from '../../store/duck/flow'
import { getWaitTimeString } from '../../utils'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    appointmentDetails: {
      padding: theme.spacing(1, 2, 1, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    text: {
      fontSize: '1.8rem',
    },
    patientAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
  }),
  { name: 'AppointmentInfoSection' },
)

const AppointmentInfoSection = ({ appointment }) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const Species = useSelector(getSpecies)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)

  const { estimatedWaitTimeInKioskEnabled } =
    useSelector(getCurrentBusiness) || {}
  const isEstimatedWaitTimeInKioskFeatureToggleEnabled = useSelector(
    getFeatureToggle(Features.WAIT_LIST_ESTIMATED_TIME),
  )

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  if (!appointment) {
    return null
  }

  const client = appointment.client || {}
  const patient = appointment.patient || {}
  const veterinarian =
    appointment.vet ||
    appointment.personResponsibilities?.[0]?.person ||
    appointment.personRoles?.[0]?.person
  const appointmentStart = moment(appointment.scheduledStartDatetime)
  const time = appointmentStart.format(DateFormat.FULL_TIME_WITH_MERIDIAN)
  const waitTimeString = getWaitTimeString(appointment)
  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient.photo,
    patient.photoThumbnail,
    AVATAR_SIZE,
  )

  return (
    <Grid
      container
      item
      alignItems={isMobile ? 'flex-start' : 'center'}
      className={classes.appointmentDetails}
      direction={isMobile ? 'column-reverse' : 'row'}
    >
      <Grid item xs>
        <Typography className={classes.text} variant="h5">
          {isWaitlist
            ? patient.name || ''
            : `${time} | ${patient.name || ''} ${client.lastName || ''}`}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body2">
          {isWaitlist
            ? !isEstimatedWaitTimeInKioskFeatureToggleEnabled ||
              estimatedWaitTimeInKioskEnabled
              ? `${t('Common:ESTIMATED_WAIT_TIME_VALUE')}: ${waitTimeString}`
              : ''
            : veterinarian
            ? t('Common:WITH_PERSON', {
                personName: `${veterinarian.firstName} ${veterinarian.lastName}`,
              })
            : ''}
        </Typography>
      </Grid>
      <Grid item>
        <PatientAvatar
          small
          animal={Utils.getConstantName(patient.species, Species)}
          classes={{ root: classes.patientAvatar }}
          src={avatarSrc}
        />
      </Grid>
    </Grid>
  )
}

export default AppointmentInfoSection
