export const getPatchedAppointment = (appointment) => ({
  ...appointment,
  type: appointment.type.id,
  client: appointment.client.id,
  patient: appointment.patient.id,
  state: appointment.state.id,
  spaceId: appointment.assignedSpace?.id,
  personRoles: appointment.personRoles?.map(({ person, role }) => ({
    person: person.id,
    role: role.id,
  })),
  personResponsibilities: appointment.personResponsibilities?.map(
    ({ person, responsibility }) => ({
      person: person.id,
      responsibility: responsibility.id,
    }),
  ),
})
