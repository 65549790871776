import React from 'react'

import AppointmentMessageScreen, {
  AppointmentMessageType,
} from './AppointmentMessageScreen'

const AppointmentZoomLinkMessageScreen = () => (
  <AppointmentMessageScreen
    appointmentMessageType={AppointmentMessageType.ZOOM_LINK}
  />
)

export default AppointmentZoomLinkMessageScreen
