import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTextArea, useFields } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../store/duck/clients'
import {
  editPatient,
  getCurrentPatient,
  getPatientsIsLoading,
} from '../../store/duck/patients'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      maxWidth: 520,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    input: {
      fontSize: '1.8rem',
    },
    label: {
      marginTop: theme.spacing(3),
      fontSize: '1.8rem',
    },
  }),
  { name: 'PatientDietAndAllergiesScreen' },
)

const PatientDietAndAllergiesScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const patient = useSelector(getCurrentPatient)
  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getPatientsIsLoading)

  const {
    fields: { diet, allergies },
    validate,
    reset,
  } = useFields(
    [
      { name: 'diet', label: t('Common:DIET'), initialValue: patient.diet },
      {
        name: 'allergies',
        label: t('Common:ALLERGIES'),
        initialValue: patient.allergies,
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [patient])

  const onProceed = () => {
    if (validate()) {
      const newPatient = {
        ...(patient || {}),
        diet: diet.value,
        allergies: allergies.value,
      }

      dispatch(editPatient(client.id, newPatient))
    }
  }

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      justifyContent="flex-start"
      proceedButtonLoading={isLoading}
      title={t('Common:CONFIRM_PATIENTS_DIET_AND_ALLERGIES', {
        patientName: patient.name || '',
      })}
      onProceed={onProceed}
    >
      <PuiTextArea
        classes={{
          label: classes.label,
          input: classes.input,
        }}
        field={diet}
        label={diet.label}
      />
      <PuiTextArea
        classes={{
          label: classes.label,
          input: classes.input,
        }}
        field={allergies}
        label={allergies.label}
      />
    </KioskScreen>
  )
}

export default PatientDietAndAllergiesScreen
