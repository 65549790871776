import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { getAppointmentTypesMap } from '../../store/duck/appointmentTypes'
import { getCurrentBusiness } from '../../store/duck/businesses'
import { getAppointmentTypes } from '../../store/duck/constants'
import {
  getCurrentAppointment,
  updateCurrentAppointment,
} from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(8, 3, 2),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
    },
  }),
  { name: 'TelemedicineScreen' },
)

const TelemedicineScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const AppointmentTypes = useSelector(getAppointmentTypes)
  const appointmentTypesMap = useSelector(getAppointmentTypesMap)
  const business = useSelector(getCurrentBusiness) || {}
  const appointment = useSelector(getCurrentAppointment) || {}
  const TelemedicineType = Utils.findConstantIdByName(
    'Telemedicine',
    AppointmentTypes,
  )

  const onProceed = () => {
    const newAppointment = {
      ...appointment,
      type: R.pipe(
        R.values,
        R.find(R.propEq('eventTypeId', TelemedicineType)),
        R.prop('id'),
      )(appointmentTypesMap),
    }
    dispatch(updateCurrentAppointment(newAppointment))
    navigateWithQueryString({ url: '/client-type-selection' })
  }

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      justifyContent="flex-start"
      proceedButtonLabel={t('Common:SCHEDULE_NOW')}
      title={t('Common:TELEHEALTH')}
      onProceed={onProceed}
    >
      <Typography
        dangerouslySetInnerHTML={{ __html: business.telemedicineText }}
        variant="body2"
      />
    </KioskScreen>
  )
}

export default TelemedicineScreen
