import { useLocation, useNavigate } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { omit } from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

export const useNavigateWithQueryString = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return ({ url = '', search: searchProp, ignoreParams, options }) => {
    const search = searchProp || location.search
    const cleanSearch = ignoreParams
      ? `?${stringify(omit(ignoreParams, parse(search)))}`
      : search

    return navigate(`${url}${cleanSearch}`, options)
  }
}

export const getPortalUrl = (path = '') => {
  const { hostname } = window.location
  const domain = Utils.isLocalEnvironment()
    ? 'stage.rhapsody.vet'
    : hostname.replace('kiosk.', '')
  return `https://portal.${domain}/${path}`
}

export const supportsFullscreen = () =>
  document.fullscreenEnabled ||
  document.webkitFullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.msFullscreenEnabled

export const enterFullscreen = () => {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    /* Firefox */
    document.documentElement.mozRequestFullScreen()
  } else if (document.documentElement.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    document.documentElement.webkitRequestFullscreen()
  } else if (document.documentElement.msRequestFullscreen) {
    /* IE/Edge */
    document.documentElement.msRequestFullscreen()
  }
}

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen()
  }
}

export const isFullscreen = () =>
  document.fullscreenElement ||
  document.mozFullscreenElement ||
  document.webkitFullscreenElement ||
  document.msFullscreenElement

export const getServiceFeeAmount = (amount, serviceFee) =>
  Utils.round((amount * serviceFee) / (1 + serviceFee), 2)

export const getWaitTimeString = (appointment) =>
  appointment?.waitTime
    ? `${appointment.waitTime.amount} ${(
        appointment.waitTime.unit || ''
      ).toLowerCase()}`
    : 'unknown'
