import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse } from 'query-string'
import { PuiTextField, Shadow, useFields } from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import {
  emailPayment,
  getFinanceIsLoading,
  getIsAuthorize,
} from '../../../store/duck/finance'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import EmailSuccessPopper from '../../dialog/EmailSuccessPopper'
import PaymentDetailsScreen from './PaymentDetailsScreen'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
    },
    icon: {
      fontSize: '3.8rem',
      marginRight: theme.spacing(2),
      color: theme.colors.alertSuccess,
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
    button: {
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        width: 'fit-content',
        '&:not(:first-of-type)': {
          marginTop: theme.spacing(2),
        },
      },
      width: 'auto',
      height: 56,
    },
  }),
  { name: 'PaymentSuccessScreen' },
)

const PaymentSuccessScreen = () => {
  const classes = useStyles()
  const location = useLocation()
  const { token } = parse(location.search)
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Payment'])

  const isAuthorize = useSelector(getIsAuthorize)
  const isLoading = useSelector(getFinanceIsLoading)

  const rootRef = useRef()

  const [emailSuccessPopperOpen, setEmailSuccessPopperOpen] = useState(false)

  const onEmailSent = () => {
    setEmailSuccessPopperOpen(true)
  }

  const setCallbackWhenEmailed = useCallbackWhenLoaded(
    onEmailSent,
    getFinanceIsLoading,
  )

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const {
    fields: { email },
    validate,
  } = useFields([
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email', 'required'],
      initialValue: '',
    },
  ])

  const emailCopy = () => {
    if (validate()) {
      setCallbackWhenEmailed()
      dispatch(emailPayment(token, email.value))
    }
  }

  const proceed = () => {
    navigateWithQueryString({ url: '/payment/finish' })
  }

  const text = isAuthorize
    ? t('Payment:YOUR_AUTHORIZATION_WAS_SUCCESSFUL')
    : t('Payment:YOUR_PAYMENT_WAS_PROCESSED')

  return (
    <PaymentDetailsScreen showRemainingBalance ref={rootRef}>
      <Grid container item alignItems="center">
        <SuccessAlert className={classes.icon} />
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
      <PuiTextField
        fullWidth
        field={email}
        inputProps={{ maxLength: 100 }}
        label={`${email.label}*`}
        type="email"
      />
      <Grid
        container
        item
        className={classes.buttonContainer}
        direction={isMobile ? 'column' : 'row'}
      >
        <KioskButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={emailCopy}
        >
          {t('Common:EMAIL_ME_COPY')}
        </KioskButtonWithLoader>
        <KioskButtonWithLoader
          className={classes.button}
          color="secondary"
          onClick={proceed}
        >
          {t('Common:NO_THANKS_I_AM_ALL_SET')}
        </KioskButtonWithLoader>
      </Grid>
      <EmailSuccessPopper
        anchorEl={rootRef.current}
        open={emailSuccessPopperOpen}
        onClose={() => setEmailSuccessPopperOpen(false)}
        onProceed={proceed}
      />
      <Shadow open={emailSuccessPopperOpen} />
    </PaymentDetailsScreen>
  )
}

export default PaymentSuccessScreen
