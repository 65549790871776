import React from 'react'

import PhoneNumberScreen from '../PhoneNumberScreen'

const MembershipPhoneNumberScreen = (props) => (
  <PhoneNumberScreen
    enableUpdateClientFlow={false}
    failureRoute="/membership/email"
    successRoute="/membership/sign-up"
    {...props}
  />
)

export default MembershipPhoneNumberScreen
