import axios from 'axios'
import { normalize } from 'normalizr'
import { ErrorTypes, Utils } from '@pbt/pbt-ui-components'

export const getAPIPath = (path) => `/apigw-rhapsody/${path}`

export const request = (path, params = {}, auth = true, schema) => {
  const requestParameters = { ...params }
  if (auth) {
    requestParameters.headers = {
      ...(requestParameters.headers || {}),
    }
  }

  if (Utils.isLocalEnvironment() && window.pbt.getDevEnv()) {
    requestParameters.headers = {
      ...(requestParameters.headers || {}),
      'X-PBT-DevEnv': window.pbt.getDevEnv(),
    }
  }

  return axios({ ...requestParameters, url: path })
    .then((response = {}) => {
      const { data } = response
      return schema ? normalize(data, schema) : data
    })
    .catch((error) => {
      throw new ErrorTypes.ApiError(error)
    })
}
