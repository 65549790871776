import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { prop } from 'ramda'
import { Check as CheckIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentBusinessButtonsColor } from '../../../../store/duck/businesses'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      flex: 1,
    },
    check: {
      marginRight: theme.spacing(1),
      color: prop('buttonsColor') || theme.colors.alertLabelError,
    },
    benefitNameText: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'PlanDetailsBenefitRowMobile' },
)

const PlanDetailsBenefitRowMobile = ({ benefit }) => {
  const buttonsColor = useSelector(getCurrentBusinessButtonsColor)
  const classes = useStyles({ buttonsColor })

  return (
    <Grid container item alignItems="center" className={classes.root}>
      <CheckIcon className={classes.check} />
      <Typography className={classes.benefitNameText} variant="body2">
        {benefit.name}
      </Typography>
    </Grid>
  )
}

export default PlanDetailsBenefitRowMobile
