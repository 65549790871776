import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CurrencyTextField,
  NumberUtils,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  getFinanceBalance,
  getFinancePaymentAmount,
  updateHasAgreedToPay,
  updatePaymentAmount,
} from '../../../store/duck/finance'
import { getServiceFeeAmount, useNavigateWithQueryString } from '../../../utils'
import PayButtonSection from './PayButtonSection'
import PaymentDetailsScreen from './PaymentDetailsScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 300,
    },
    title: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
    },
    text: {
      fontSize: '1.8rem',
    },
    boldText: {
      fontWeight: 500,
    },
    currencyTextField: {
      maxWidth: 100,
    },
    input: {
      textAlign: 'right',
    },
  }),
  { name: 'PayBalanceScreen' },
)

const PayBalanceScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const balance = useSelector(getFinanceBalance)
  const business = useSelector(getCurrentBusiness)
  const paymentAmount = useSelector(getFinancePaymentAmount)

  const { serviceFee = 0 } = business || {}
  const { currentBalance } = balance || {}

  const {
    fields: { amount },
    validate,
  } = useFields([
    {
      name: 'amount',
      validators: ['required'],
      initialValue: paymentAmount
        ? paymentAmount - getServiceFeeAmount(paymentAmount, serviceFee)
        : currentBalance,
    },
  ])

  const amountValue = Number(amount.value)
  const serviceFeeAmount = serviceFee
    ? Utils.round(amountValue * serviceFee, 2)
    : 0
  const totalPayment = serviceFeeAmount + amountValue

  useEffect(() => {
    dispatch(updatePaymentAmount(totalPayment))
  }, [totalPayment])

  const proceed = () => {
    if (validate()) {
      dispatch(updateHasAgreedToPay(true))
      dispatch(updatePaymentAmount(totalPayment))
      navigateWithQueryString({ url: '/payment/billing-address' })
    }
  }

  return (
    <PaymentDetailsScreen footer={<PayButtonSection onProceed={proceed} />}>
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Typography className={classes.title}>
            {t('Common:PAYMENT_AMOUNT')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography className={classes.text} variant="body2">
            {t('Common:PAYMENT')}:
          </Typography>
          <CurrencyTextField
            className={classes.currencyTextField}
            field={amount}
            inputProps={{
              className: classes.input,
            }}
            margin="none"
          />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography className={classes.text} variant="body2">
            {t('Common:SERVICE_FEE')}:
          </Typography>
          <Typography className={classes.text} variant="body2">
            {NumberUtils.formatMoney(serviceFeeAmount)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography
            className={classNames(classes.boldText, classes.text)}
            variant="body2"
          >
            {t('Common:TOTAL_PAYMENT')}:
          </Typography>
          <Typography
            className={classNames(classes.boldText, classes.text)}
            variant="body2"
          >
            {NumberUtils.formatMoney(totalPayment)}
          </Typography>
        </Grid>
      </Grid>
    </PaymentDetailsScreen>
  )
}

export default PayBalanceScreen
