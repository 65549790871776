import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  fetchPaymentLinkData,
  getFinanceError,
  getFinanceHasAgreedToPay,
  getFinanceIsFetchingLinkData,
} from '../../../store/duck/finance'
import FullscreenCircularProgress from '../../FullscreenCircularProgress'
import KioskWrapper from '../../KioskWrapper'
import paymentRoutes from './paymentRoutes'

const ROOT_URL = '/payment'

const PaymentWrapperScreen = () => {
  const location = useLocation()
  const { token } = parse(location.search)
  const dispatch = useDispatch()
  const currentBusiness = useSelector(getCurrentBusiness)
  const isFetchingLinkData = useSelector(getFinanceIsFetchingLinkData)
  const error = useSelector(getFinanceError)
  const hasAgreedToPay = useSelector(getFinanceHasAgreedToPay)

  useEffect(() => {
    dispatch(fetchPaymentLinkData(token))
  }, [token])

  const showSpinner = (!currentBusiness || isFetchingLinkData) && !error

  if (showSpinner) {
    return <FullscreenCircularProgress />
  }

  return (
    <KioskWrapper
      redirectCondition={!hasAgreedToPay}
      rootUrl={ROOT_URL}
      routes={paymentRoutes}
    />
  )
}

export default PaymentWrapperScreen
