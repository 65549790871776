import React from 'react'
import { Grid } from '@mui/material'
import { equals } from 'ramda'
import { DateUtils, moment } from '@pbt/pbt-ui-components'

import KioskSelectableButton from '../../buttons/KioskSelectableButton'

const CalendarSectionSlotList = ({
  getSlotDisabled,
  selectedSlot,
  slots,
  onSlotClick,
}) =>
  slots.map((slot) => {
    const isSelected = equals(selectedSlot, slot)
    const slotFrom = moment(slot.from)
    const slotTo = moment(slot.to)

    const text = DateUtils.getHourRange(slotFrom, slotTo)

    return (
      <Grid item key={text} xs={6}>
        <KioskSelectableButton
          disabled={getSlotDisabled(slot)}
          isSelected={isSelected}
          onClick={() => onSlotClick(slot)}
        >
          {text}
        </KioskSelectableButton>
      </Grid>
    )
  })

export default CalendarSectionSlotList
