import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

import { useNavigateWithQueryString } from '../../utils'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    content: {
      padding: theme.spacing(2, 1),
    },
  }),
  { name: 'ClientTypeSelectionScreen' },
)

const ClientTypeSelectionScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const goToSearch = () => {
    navigateWithQueryString({ url: '/phone' })
  }

  const createNewClient = () => {
    navigateWithQueryString({ url: '/new-client' })
  }

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      title={t('Common:HAVE_YOU_VISITED_US_BEFORE')}
    >
      <KioskButtonWithLoader className={classes.button} onClick={goToSearch}>
        {t('Common:I_AM_RETURNING_CLIENT')}
      </KioskButtonWithLoader>
      <KioskButtonWithLoader
        className={classes.button}
        color="secondary"
        onClick={createNewClient}
      >
        {t('Common:I_AM_NEW_CLIENT')}
      </KioskButtonWithLoader>
    </KioskScreen>
  )
}

export default ClientTypeSelectionScreen
