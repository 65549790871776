import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ImageScalingUtils, PatientAvatar, Utils } from '@pbt/pbt-ui-components'

import { getSpecies } from '../../store/duck/constants'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    patientCard: {
      [theme.breakpoints.up('sm')]: {
        width: 382,
      },
      flexShrink: 0,
      padding: theme.spacing(1, 2),
      borderRadius: 8,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 1px 4px 0 rgba(60,56,56,0.2)',
      border: '2px solid transparent',
    },
    patientCardSelectable: {
      cursor: 'pointer',
    },
    patientCardSelected: {
      borderColor: '#5798B2',
    },
    patientAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      marginRight: theme.spacing(2),
    },
    patientName: {
      fontSize: '1.8rem',
    },
  }),
  { name: 'PatientCard' },
)

const PatientCard = ({ className, isSelected, patient, onSelected }) => {
  const classes = useStyles()
  const Species = useSelector(getSpecies)

  const isSelectable = Boolean(onSelected)
  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient.photo,
    patient.photoThumbnail,
    AVATAR_SIZE,
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.patientCard, {
        [classes.patientCardSelected]: isSelected,
        [classes.patientCardSelectable]: isSelectable,
      })}
      onClick={isSelectable ? () => onSelected(patient) : undefined}
    >
      <PatientAvatar
        small
        animal={Utils.getConstantName(patient.species, Species)}
        classes={{ root: classes.patientAvatar }}
        src={avatarSrc}
      />
      <Typography className={classes.patientName} variant="h5">
        {patient.name}
      </Typography>
    </Grid>
  )
}

export default PatientCard
