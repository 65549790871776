import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux'
import { CountryCode, PhoneInputI18n, PhoneUtils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
import PhoneNumberInputUS from './PhoneNumberInputUS'

const US_LOCAL_PHONE_LENGTH = 10

const PhoneInput = forwardRef(function PhoneInput({ field, ...rest }, ref) {
  const business = useSelector(getCurrentBusiness)
  const { countryCatalogCode } = business
  const isUSLocalFormat =
    !countryCatalogCode ||
    countryCatalogCode === CountryCode.US ||
    countryCatalogCode === CountryCode.CA
  const inputRef = useRef()

  useImperativeHandle(ref, () => ({
    getPhoneLength: () =>
      isUSLocalFormat
        ? US_LOCAL_PHONE_LENGTH
        : inputRef.current?.state.selectedCountry.format?.match(/[+.]/g)
            .length || US_LOCAL_PHONE_LENGTH,
    getFormattedNumber: () => inputRef.current?.state.formattedNumber,
  }))
  return (
    <PhoneInputI18n
      country={countryCatalogCode}
      customInputComponent={PhoneNumberInputUS}
      field={{
        ...field,
        value: isUSLocalFormat
          ? PhoneUtils.formatPhoneNumber(field.value)
          : field.value,
      }}
      ref={inputRef}
      {...rest}
    />
  )
})

export default PhoneInput
