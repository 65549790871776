import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Communications } from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import { getCommunicationsLastSentMessageTransport } from '../../store/duck/communications'
import { useNavigateWithQueryString } from '../../utils'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(5, 3),
    },
    title: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '2.6rem',
    },
    successIcon: {
      fontSize: '4rem',
      color: theme.colors.alertSuccess,
    },
    button: {
      width: 223,
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'MessageSentSuccessScreen' },
)

const MessageSentSuccessScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const lastSentMessageTransport = useSelector(
    getCommunicationsLastSentMessageTransport,
  )

  const finish = () => {
    navigateWithQueryString({ url: '/' })
  }

  const successMessage =
    lastSentMessageTransport === Communications.MessageTransport.EMAIL
      ? `${t('Common:EMAIL_WAS_SENT')}!`
      : `${t('Common:MESSAGE_WAS_SENT')}!`

  return (
    <KioskScreen classes={{ content: classes.content }}>
      <SuccessAlert className={classes.successIcon} />
      <Typography className={classes.title}>{successMessage}</Typography>
      <KioskButtonWithLoader className={classes.button} onClick={finish}>
        {t('Common:FINISH_ACTION')}
      </KioskButtonWithLoader>
    </KioskScreen>
  )
}

export default MessageSentSuccessScreen
