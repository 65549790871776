import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import {
  ButtonWithLoader,
  NumberUtils,
  PuiPopper,
} from '@pbt/pbt-ui-components'

import {
  getFinanceIsLoading,
  getIsAuthorize,
} from '../../../store/duck/finance'

const useStyles = makeStyles(
  (theme) => ({
    popper: {
      [theme.breakpoints.up('md')]: {
        width: 650,
      },
    },
    button: {
      marginTop: theme.spacing(5),
      width: 265,
      height: 60,
      fontSize: '1.8rem',
      borderRadius: 33,
    },
    container: {
      padding: theme.spacing(10, 5, 5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(10, 2, 5),
      },
    },
  }),
  { name: 'ConfirmPaymentPopper' },
)

const ConfirmPaymentPopper = ({ amount, onProceed, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Payment'])

  const isLoading = useSelector(getFinanceIsLoading)
  const isAuthorize = useSelector(getIsAuthorize)

  const amountString = NumberUtils.formatMoney(amount)
  const text = isAuthorize
    ? t('Payment:YOU_ARE_ABOUT_TO_AUTHORIZE_HOLD_OF_AMOUNT', {
        amount: amountString,
      })
    : t('Payment:YOU_ARE_ABOUT_TO_SUBMIT_PAYMENT_OF_AMOUNT', {
        amount: amountString,
      })

  const buttonText = isAuthorize
    ? t('Common:SUBMIT')
    : t('Common:SUBMIT_PAYMENT')

  return (
    <PuiPopper
      classes={{
        popper: classes.popper,
      }}
      {...rest}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.container}
        direction="column"
      >
        <Typography align="center" variant="body2">
          {text}
        </Typography>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={onProceed}
        >
          {buttonText}
        </ButtonWithLoader>
      </Grid>
    </PuiPopper>
  )
}

ConfirmPaymentPopper.propTypes = {
  amount: PropTypes.number.isRequired,
  onProceed: PropTypes.func.isRequired,
}

export default ConfirmPaymentPopper
