import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { DateUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../../../store/duck/clients'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
  }),
  { name: 'InvoiceTitle' },
)

const InvoiceTitle = ({ invoice, className }) => {
  const classes = useStyles()
  const client = useSelector(getCurrentClient)
  const { t } = useTranslation('Common')

  const { type, invoiceNo, patient, creationDate, invoices } = invoice || {}
  const isMultiInvoice = invoices?.length > 0

  return (
    <Text
      strong
      className={classNames(className, classes.title)}
      variant="subheading2"
    >
      {isMultiInvoice
        ? t('Common:MULTIPLE_INVOICES')
        : invoice
        ? `${type} ${invoiceNo} ${DateUtils.formatDate(creationDate)} ${
            patient ? `| ${patient.name}` : ''
          }`
        : Utils.getPersonString(client)}
    </Text>
  )
}

InvoiceTitle.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object,
}

export default InvoiceTitle
