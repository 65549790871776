import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils } from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import { WellnessPlanLevels } from '../../../constants/wellnessPlansConstants'
import {
  getConstantsIsLoading,
  getFeatureToggle,
} from '../../../store/duck/constants'
import {
  getMembershipIsPortalEmbed,
  getWellnessPlansIsFetching,
  getWellnessPlansVersion,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import KioskLinkButton from '../../buttons/KioskLinkButton'
import KioskScreen from '../KioskScreen'
import WellnessPlanBenefitsTable from './WellnessPlanBenefitsTable'
import { getPlanByLevel } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    title: {
      textAlign: 'left',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
    benefitsContainerNoMargin: {
      marginTop: 0,
    },
    progress: {
      marginTop: 'calc(50vh - 20px)',
      alignSelf: 'center',
    },
    button: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 'none',
        marginRight: 0,
      },
      maxWidth: 265,
      flex: 1,
      marginRight: theme.spacing(2),
    },
    additionalLabelContainer: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    wrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    baseFeeText: {
      paddingLeft: theme.spacing(2),
    },
    linkButton: {
      width: 'fit-content',
      fontSize: '1.4rem',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
  }),
  { name: 'MembershipPlanOverviewScreen' },
)

const MembershipPlanOverviewScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const isFetching = useSelector(getWellnessPlansIsFetching)
  const isLoadingConstants = useSelector(getConstantsIsLoading)
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion)
  const isWpOneTimeFeeEnabled = useSelector(
    getFeatureToggle(Features.WP_ONE_TIME_FEE),
  )

  const baseLevelPlan = getPlanByLevel(
    WellnessPlanLevels.BASE,
    wellnessPlansVersion?.plans,
  )
  const baseLevelPlanPrice = NumberUtils.formatMoney(baseLevelPlan?.price)
  const baseLevelPlanPriceType = baseLevelPlan?.priceType?.displayName || ''
  const planOneTimeFeeAmount = NumberUtils.formatMoney(
    wellnessPlansVersion?.oneTimeFeeAmount,
  )

  const handleProceed = () => {
    navigateWithQueryString({ url: '/membership/sign-up' })
  }

  const goToFullDetails = () => {
    navigateWithQueryString({ url: '/membership/plan-details' })
  }

  if (isFetching || isLoadingConstants) {
    return <CircularProgress className={classes.progress} />
  }

  return (
    <KioskScreen
      hideBackButton
      alignItems="flex-start"
      classes={{
        additionalLabelContainer: classes.additionalLabelContainer,
        root: classes.root,
        title: classes.title,
      }}
      justifyContent="flex-start"
      proceedButtonLabel={
        isPortalEmbed ? t('Common:SIGN_CLIENT_UP_NOW') : t('Common:SIGN_UP')
      }
      title={isPortalEmbed ? t('Membership:ADD_MEMBERSHIP_PLAN') : ''}
      onProceed={handleProceed}
    >
      <Grid container item className={classes.wrapper} direction="column">
        {isPortalEmbed && (
          <Typography className={classes.baseFeeText} variant="body2">
            {t('Membership:HELP_CLIENT_UNDERSTAND_OPTIONS_AVAILABLE')}
            {baseLevelPlan?.price > 0 &&
              ` | ${
                isWpOneTimeFeeEnabled && wellnessPlansVersion?.oneTimeFeeAmount
                  ? t('Membership:EACH_SIGN_UP_HAS_BASE_AND_OTF_FEE_OF', {
                      planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
                      planOneTimeFeeAmount,
                    })
                  : t('Membership:EACH_SIGN_UP_HAS_BASE_FEE_OF', {
                      planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
                    })
              }`}
          </Typography>
        )}
        <KioskLinkButton
          className={classes.linkButton}
          color="secondary"
          onClick={goToFullDetails}
        >
          {t('Common:VIEW_FULL_DETAILS')}
        </KioskLinkButton>
        <WellnessPlanBenefitsTable />
      </Grid>
    </KioskScreen>
  )
}

export default MembershipPlanOverviewScreen
