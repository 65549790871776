import ClientEmailVerificationScreen from './ClientEmailVerificationScreen'

/* root path: /client */

export default [
  {
    Component: ClientEmailVerificationScreen,
    path: 'email-verification/:token',
    isRoot: true,
  },
]
