import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { getCurrentClient } from '../../../store/duck/clients'
import { useNavigateWithQueryString } from '../../../utils'
import KioskLinkButton from '../../buttons/KioskLinkButton'
import KioskScreen from '../KioskScreen'
import WellnessPlanBenefitsTable from './WellnessPlanBenefitsTable'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'left',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
    content: {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    button: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
        fontSize: '1.4rem',
      },
    },
    benefitsTable: {
      marginTop: theme.spacing(6),
    },
  }),
  { name: 'MembershipBrowsePlansScreen' },
)

const MembershipBrowsePlansScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const client = useSelector(getCurrentClient)

  const onProceed = () => {
    if (client) {
      navigateWithQueryString({ url: '/membership/sign-up' })
    } else {
      navigateWithQueryString({ url: '/membership/phone' })
    }
  }

  const goToFullDetails = () => {
    navigateWithQueryString({ url: '/membership/plan-details' })
  }

  return (
    <KioskScreen
      alignItems="flex-start"
      classes={{
        content: classes.content,
        title: classes.title,
      }}
      justifyContent="flex-start"
      proceedButtonLabel="Continue"
      title={t('Membership:BROWSE_OUR_PLANS')}
      onProceed={onProceed}
    >
      <KioskLinkButton
        className={classes.button}
        color="secondary"
        onClick={goToFullDetails}
      >
        {t('Common:VIEW_FULL_DETAILS')}
      </KioskLinkButton>
      <WellnessPlanBenefitsTable />
    </KioskScreen>
  )
}

export default MembershipBrowsePlansScreen
