import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { parse } from 'query-string'
import { prop, sortBy } from 'ramda'
import { AddButton, Text } from '@pbt/pbt-ui-components'

import Features from '../../../../constants/features'
import { WellnessPlanLevels } from '../../../../constants/wellnessPlansConstants'
import { getCurrentBusinessIsOmniChannel } from '../../../../store/duck/businesses'
import { getCurrentClient } from '../../../../store/duck/clients'
import { getFeatureToggle } from '../../../../store/duck/constants'
import { getWellnessPlansVersion } from '../../../../store/duck/wellnessPlans'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import MembershipTotalsLabel from '../MembershipTotalsLabel'
import {
  getPlanByLevel,
  // @ts-ignore
} from '../wellnessPlanUtils'
import {
  MembershipSummaryTableRow,
  StyledMembershipSummaryTableRow,
  Totals,
} from './MembershipSummaryTableRow'

const useStyles = makeStyles(
  () => ({
    plusButton: {
      width: 40,
      height: 40,
    },
    plusIcon: {
      width: 35,
      height: 35,
    },
    addText: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    totalsText: {
      textAlign: 'right',
    },
  }),
  { name: 'MembershipSummaryTable' },
)

interface MembershipSummaryTableProps {
  readonly totals: Totals
  readonly totalsPerPatient: Record<string, Totals>
}

export const MembershipSummaryTableV2 = ({
  totals,
  totalsPerPatient,
}: MembershipSummaryTableProps) => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const client = useSelector(getCurrentClient)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(Features.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const showAddPet = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const { search } = useLocation()
  const { portalEmbed } = parse(search)
  const isPortalEmbed = Boolean(portalEmbed)

  const allPlans = wellnessPlansVersion?.plans || []
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)
  const showBaseLevelPlanPrice = baseLevelPlan?.price > 0

  const memberships = sortBy(prop('member'), client?.memberships || [])

  const onAddPatientRequested = () => {
    navigateWithQueryString({
      url: '/membership/patient-details',
      options: {
        replace: true,
        state: {
          redirectUrl: '/membership/plan-summary',
        },
      },
    })
  }

  const StyledRoot = styled(Grid)(({ theme }) => ({
    width: '100%',
    maxHeight: `calc(var(--app-height) - ${
      (isPortalEmbed ? 232 : 405) + (showBaseLevelPlanPrice ? 21 : 0)
    }px)`,
    [theme.breakpoints.down('sm')]: {
      borderTop: theme.constants.tabBorder,
      maxHeight: `calc(var(--app-height) - 410px)`,
    },
  }))

  return (
    <StyledRoot>
      <Grid container py={2}>
        <Grid item pl={4} xs={4.5}>
          <Text variant="lowAccent2">{t('Common:PET')}</Text>
        </Grid>
        <Grid item pl={1} xs={4.5}>
          <Text variant="lowAccent2">{t('Common:PLAN')}</Text>
        </Grid>
        <Grid container item justifyContent="right" pr={3} xs={3}>
          <Text variant="lowAccent2">
            {t('Common:BILLING_AMMOUNT_FREQUENCY')}
          </Text>
        </Grid>
      </Grid>
      <Stack>
        {memberships.map((membership) => (
          <MembershipSummaryTableRow
            key={membership.patient.id}
            membership={membership}
            totalsPerPatient={totalsPerPatient}
          />
        ))}
        {showAddPet && (
          <StyledMembershipSummaryTableRow>
            <Grid pl={4} py={1.5}>
              <AddButton
                addText={t('Common:ADD_ANOTHER_PET')}
                classes={{
                  plusButton: classes.plusButton,
                  addText: classes.addText,
                  plusIcon: classes.plusIcon,
                }}
                onAdd={onAddPatientRequested}
              />
            </Grid>
          </StyledMembershipSummaryTableRow>
        )}
      </Stack>
      <Grid container item flexDirection="column" mt={1} pr={3}>
        <MembershipTotalsLabel
          alignItems="flex-end"
          classes={{
            totalText: classes.totalsText,
          }}
          totals={totals}
        />
        <Text alignSelf="flex-end" variant="lowAccent2">
          {t('Common:PETS_BILLED_SEPERATELY')}
        </Text>
      </Grid>
    </StyledRoot>
  )
}
