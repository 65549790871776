import React from 'react'
import { useSelector } from 'react-redux'

import { getLastError } from '../../store/duck/errors'
import { getCurrentFlow } from '../../store/duck/flow'
import HomeButton from '../buttons/HomeButton'
import kioskRoutes from '../kioskRoutes'
import KioskWrapper from '../KioskWrapper'

const KioskWrapperScreen = () => {
  const currentFlow = useSelector(getCurrentFlow)
  const lastError = useSelector(getLastError)
  return (
    <>
      <HomeButton />
      <KioskWrapper
        redirectCondition={!currentFlow && !lastError}
        routes={kioskRoutes}
      />
    </>
  )
}

export default KioskWrapperScreen
