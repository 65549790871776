import { Appointment } from '../types/entities/appointments'

export const isPatientCheckedIn = (
  isWaitlist: boolean,
  isCheckIn: boolean,
  appointment: Appointment,
) => {
  if (isWaitlist || isCheckIn) {
    return Boolean(appointment?.isCheckedIn)
  }
  return false
}
