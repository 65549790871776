import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  Defaults,
  moment,
  useInterval,
} from '@pbt/pbt-ui-components'

import {
  fetchWaitlist,
  getSchedulesIsLoading,
  getWaitlist,
} from '../../../store/duck/schedules'
import Header from '../../Header'
import WaitListItem from '../wait-list/WaitListItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: 1,
      height: '100%',
      flex: 1,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(5),
    },
    businessLogo: {
      justifyContent: 'flex-start',
    },
    header: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    waitlist: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(3),
      },
      flex: 1,
      padding: theme.spacing(3),
      marginTop: theme.spacing(6),
      borderRadius: 4,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
      overflowY: 'auto',
      minHeight: 'calc(var(--app-height) - 194px)',
      maxHeight: 'calc(var(--app-height) - 194px)',
    },
    text: {
      fontWeight: 500,
    },
  }),
  { name: 'InClinicWaitlistScreen' },
)

const InClinicWaitlistScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getSchedulesIsLoading)
  const waitlist = useSelector(getWaitlist) || []

  const currentDate = moment()

  useEffect(() => {
    dispatch(fetchWaitlist())
  }, [])

  useInterval(() => {
    dispatch(fetchWaitlist(true))
  }, Defaults.REFRESH_INTERVAL)

  return (
    <Grid container className={classes.root} direction="column" wrap="nowrap">
      <Grid
        container
        item
        className={classes.header}
        justifyContent="space-between"
      >
        <Grid item>
          <Header className={classes.businessLogo} />
        </Grid>
        <Grid item>
          <Typography align="right" className={classes.text} variant="body2">
            {currentDate.format(DateFormat.DAY_OF_WEEK_MONTH_DAY)}
          </Typography>
          <Typography align="right" className={classes.text} variant="body2">
            {currentDate.format(DateFormat.FULL_TIME_WITH_MERIDIAN)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.waitlist}
        direction="column"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        wrap="nowrap"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          waitlist.map((appointment) => (
            <WaitListItem
              bigScreen
              appointment={appointment}
              classes={{ root: classes.item }}
              key={appointment.id}
            />
          ))
        )}
      </Grid>
    </Grid>
  )
}

export default InClinicWaitlistScreen
