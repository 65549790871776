/* eslint-disable no-console */
import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, StateLabel, Utils } from '@pbt/pbt-ui-components'
import { LanguageCodes } from '@pbt/pbt-ui-components/src/localization'

import { MASTER, PROD, STAGE } from '../constants/environmentVariables'
import i18nKiosk from '../locales/i18n'

const useStyles = makeStyles(
  () => ({
    devEnvLabel: {
      zIndex: 9999,
      position: 'fixed',
      top: 0,
      right: 0,
    },
  }),
  { name: 'WindowVariableInjector' },
)

const devEnvs = [STAGE, PROD, MASTER]

const setDevEnvironment = (env) => {
  if (devEnvs.includes(env)) {
    localStorage.setItem('devEnv', env)
    window.location.reload()
  } else {
    console.error(
      `Invalid dev environment ${env}, allowed options are: ${devEnvs.join(
        ', ',
      )}`,
    )
  }
}

const getDevEnvironment = () => localStorage.getItem('devEnv') || STAGE

const getFTValue = (name) => {
  const value = JSON.parse(localStorage.getItem('devFeatureToggles')) || {}
  return name ? value[name] : value
}

const setFTValue = (name, value) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans allowed`)
    return
  }

  const data = {
    ...getFTValue(),
    [name]: value,
  }

  localStorage.setItem('devFeatureToggles', JSON.stringify(data))
  window.location.reload()
}

const clearFTValues = () => {
  localStorage.removeItem('devFeatureToggles')
  window.location.reload()
}

const setLanguage = (value) => {
  LanguageUtils.updateLanguage({
    languageValue: value,
    i18n: i18nKiosk,
    SupportedLngsValuesList: LanguageCodes,
  })
}

const setDebugI18n = (value) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans allowed`)
    return
  }

  localStorage.setItem('enabledI18nDebug', value)
  window.location.reload()
}

const LABEL_HEIGHT = 21

const WindowVariableInjector = () => {
  const classes = useStyles()

  useEffect(() => {
    window.pbt = {
      version: process.env.REACT_APP_SENTRY_RELEASE || '0.0.0',
      getDevEnv: Utils.isProduction() ? undefined : getDevEnvironment,
      setDevEnv: Utils.isProduction() ? undefined : setDevEnvironment,
      setFTValue: Utils.isProduction() ? undefined : setFTValue,
      getFTValue: Utils.isProduction() ? undefined : getFTValue,
      clearFTValues: Utils.isProduction() ? undefined : clearFTValues,
      setLanguage: Utils.isProduction() ? undefined : setLanguage,
      setDebugI18n: Utils.isProduction() ? undefined : setDebugI18n,
    }
  }, [])

  if (Utils.isProduction()) {
    return null
  }

  const devEnv = getDevEnvironment()
  const devFeatureToggles = getFTValue()

  const showDevLabel = devEnv !== STAGE

  return (
    <>
      {showDevLabel && (
        <StateLabel className={classes.devEnvLabel} warning={devEnv === PROD}>
          {devEnv}
        </StateLabel>
      )}
      {Object.keys(devFeatureToggles).map((key, index) => {
        const top = (index + (showDevLabel ? 1 : 0)) * LABEL_HEIGHT

        return (
          <StateLabel
            warning
            className={classes.devEnvLabel}
            key={key}
            style={{ top }}
          >
            {`${key}: ${devFeatureToggles[key]}`}
          </StateLabel>
        )
      })}
    </>
  )
}

export default WindowVariableInjector
