import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import ContactUsScreen from './ContactUsScreen'

const PaymentExpiredScreen = () => {
  const { t } = useTranslation('Payment')
  return (
    <ContactUsScreen>
      <Text strong align="center" variant="subheading2" whiteSpace="pre-wrap">
        {t('Payment:OH_NO_IT_LOOKS_LIKE_YOUR_LINK_HAS_EXPIRED')}
      </Text>
    </ContactUsScreen>
  )
}

export default PaymentExpiredScreen
