import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, moment, Text } from '@pbt/pbt-ui-components'

import { getAppointmentConfirmationAppointment } from '../../../store/duck/appointmentConfirmation'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
      },
    },
    caption: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
        padding: theme.spacing(2),
      },
    },
    letUsKnow: {
      fontWeight: 400,
      marginTop: theme.spacing(1.5),
      fontSize: '1.6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
  }),
  { name: 'AppointmentConfirmationCancelledScreen' },
)

const AppointmentConfirmationCancelledScreen = () => {
  const classes = useStyles()
  const location = useLocation()
  const { t } = useTranslation('Common')

  const appointment = useSelector(getAppointmentConfirmationAppointment)

  const { state } = location || {}

  const appointmentStart = moment(appointment?.scheduledStartDatetime)
  const veterinarian = appointment?.personRoles?.[0]?.person

  return state?.isSelfCancellation ? (
    <KioskScreen
      classes={{
        title: classes.title,
      }}
      justifyContent="flex-start"
      title={t(
        'Common:YOUR_APPOINTMENT_IS_CANCELLED_THANKS_FOR_LETTING_US_KNOW',
      )}
    />
  ) : (
    <KioskScreen
      classes={{
        title: classes.title,
      }}
      justifyContent="flex-start"
    >
      <Text strong align="center" className={classes.caption} variant="h2">
        {t('Common:YOUR_APPOINTMENT_ON_DATE', {
          date: appointmentStart.format(DateFormat.DAY_OF_WEEK_MONTH_DAY),
        })}
        <br />
        {veterinarian
          ? `${t('Common:WITH').toLowerCase()} ${veterinarian?.firstName} ${
              veterinarian?.lastName
            } ${t('Common:WAS_CANCELLED')}.`
          : ` ${t('Common:WAS_CANCELLED')}.`}
      </Text>
      <Text
        align="center"
        className={classes.letUsKnow}
        strong={false}
        variant="h2"
      >
        {t('Common:LET_US_KNOW_IF_YOU_HAVE_QUESTIONS')}
      </Text>
    </KioskScreen>
  )
}

export default AppointmentConfirmationCancelledScreen
