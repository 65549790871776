import React from 'react'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  () => ({
    root: {
      marginTop: 'calc(50vh - 40px)',
    },
  }),
  { name: 'FullscreenCircularProgress' },
)

const FullscreenCircularProgress = () => {
  const classes = useStyles()

  return <CircularProgress className={classes.root} />
}

export default FullscreenCircularProgress
