import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { postBraintreeMembershipSignup } from '../../api'
import { BraintreePaymentData } from '../../types/entities/memberships'
import type { RootState } from '../index'

export type BraintreeState = {
  braintreeMembershipPlanActive: boolean
  braintreeToken: string | Nil
  customerMessage: string | Nil
  error: string | Nil
  isLoading: boolean
}

export const INITIAL_STATE: BraintreeState = {
  error: null,
  isLoading: false,
  braintreeToken: null,
  braintreeMembershipPlanActive: false,
  customerMessage: null,
}

const braintreeSlice = createSlice({
  name: 'braintree',
  initialState: INITIAL_STATE,
  reducers: {
    updateBraintreeToken: (state, action: PayloadAction<string | Nil>) => {
      state.braintreeToken = action.payload
    },
    createBraintreeMembershipPayment: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        braintreePaymentData: BraintreePaymentData
        membershipToken: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    createBraintreeMembershipPaymentSuccess: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = false
      state.braintreeMembershipPlanActive = action.payload
    },
    createBraintreeMembershipPaymentFailure: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = 'braintree payment failed'
      if (action.payload.error.responseBody.creditCardError) {
        state.customerMessage =
          action.payload.error.responseBody.creditCardError.customerMessage
      } else if (action.payload.error.responseBody.transactionError) {
        state.customerMessage =
          action.payload.error.responseBody.transactionError.customerMessage
      }
    },

    clearBraintreeSubscriptionError: (state) => {
      state.error = ''
    },
    clearBrainTreeErrorCustomerMessage: (state) => {
      state.customerMessage = ''
    },
    updateBraintreeMembershipSubscriptionStatus: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.braintreeMembershipPlanActive = action.payload
    },
  },
})

export const braintreeReducer = braintreeSlice.reducer

export const {
  updateBraintreeToken,
  createBraintreeMembershipPayment,
  createBraintreeMembershipPaymentSuccess,
  createBraintreeMembershipPaymentFailure,
  updateBraintreeMembershipSubscriptionStatus,
  clearBraintreeSubscriptionError,
  clearBrainTreeErrorCustomerMessage,
} = braintreeSlice.actions

export const getBraintreeToken = (state: RootState) =>
  state.braintree.braintreeToken
export const getBraintreeSubscriptionStatus = (state: RootState) =>
  state.braintree.braintreeMembershipPlanActive

export const getBrainTreeStateError = (state: RootState) =>
  state.braintree.error

export const getBrainTreeErrorCustomerMessage = (state: RootState) =>
  state.braintree.customerMessage

export function* sagaCreateBraintreeMembershipPayment({
  payload: { braintreePaymentData, membershipToken },
}: ReturnType<typeof createBraintreeMembershipPayment>) {
  try {
    // @ts-ignore
    const response = yield call(
      postBraintreeMembershipSignup,
      membershipToken,
      braintreePaymentData,
    )
    yield put(createBraintreeMembershipPaymentSuccess(response))
  } catch (error) {
    yield put(
      createBraintreeMembershipPaymentFailure({ error: error as ApiError }),
    )
  }
}
function* watchBraintreeMembership() {
  yield takeLatest(
    createBraintreeMembershipPayment.type,
    sagaCreateBraintreeMembershipPayment,
  )
}
export function* braintreeSaga() {
  yield all([watchBraintreeMembership()])
}
