import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Grid } from '@mui/material'
import {
  Communications,
  PhoneUtils,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import PhoneInput from '../PhoneInput'

const ToInput = forwardRef(function ToInput(
  { transport, initialMobilePhone, initialEmail, ...rest },
  ref,
) {
  const phoneSelect = transport === Communications.MessageTransport.SMS
  const initialTo = phoneSelect ? initialMobilePhone : initialEmail
  const initialToValidator = phoneSelect ? 'phone' : 'email'

  const {
    fields: { to },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'to',
        validators: ['required', initialToValidator],
        initialValue: initialTo,
      },
    ],
    false,
  )

  useImperativeHandle(ref, () => ({
    validate,
    value: phoneSelect ? PhoneUtils.parsePhoneNumber(to.value) : to.value,
  }))

  useEffect(() => {
    reset()
    to.setValue(initialTo)
  }, [transport])

  return (
    <Grid item ref={ref}>
      {transport === Communications.MessageTransport.SMS ? (
        <PhoneInput
          autoFocus
          field={{
            ...to,
            label: 'Mobile number*',
          }}
          {...rest}
        />
      ) : (
        <PuiTextField
          autoFocus
          field={to}
          inputProps={{ maxLength: 100 }}
          label="Email*"
          type="email"
          {...rest}
        />
      )}
    </Grid>
  )
})

export default ToInput
