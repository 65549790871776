import { useNavigateWithQueryString } from '.'
import useCallbackWhenLoaded from './useCallbackWhenLoaded'

const useDisplayMessageSentSuccess = (isSendingMessageSelector) => {
  const navigateWithQueryString = useNavigateWithQueryString()

  const onSendMessageSuccess = () => {
    navigateWithQueryString({ url: '/success/communications/message' })
  }

  return useCallbackWhenLoaded(onSendMessageSuccess, isSendingMessageSelector)
}

export default useDisplayMessageSentSuccess
