import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse } from 'query-string'
import { prop } from 'ramda'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getWellnessPlansVersion } from '../../../store/duck/wellnessPlans'
import WellnessPlanReviewTablePlanRow from './WellnessPlanReviewTablePlanRow'
import { getEnabledPlans, useGroupedWellnessPlans } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'calc(var(--app-height) - 380px)',
        marginBottom: 0,
      },
    },
    plansContainer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      [theme.breakpoints.up('sm')]: {
        border: theme.constants.tableBorder,
      },
      [theme.breakpoints.down('sm')]: {
        flexShrink: 0,
      },
    },
    plansHeaderContainer: {
      flexShrink: 0,
      padding: theme.spacing(1, 2),
      backgroundColor: theme.colors.tableBackground,
      borderBottom: theme.constants.tableBorder,
      [theme.breakpoints.down('sm')]: {
        position: 'sticky',
        top: -1,
      },
    },
    plansHeaderText: {
      color: prop('buttonsColor') || theme.colors.title,
      fontSize: '1.6rem',
      fontWeight: 500,
    },
  }),
  { name: 'WellnessPlanBenefitsTable' },
)

const WellnessPlanBenefitsTable = ({ classes: classesProp }) => {
  const { search } = useLocation()
  const { portalEmbed } = parse(search)
  const isPortalEmbed = Boolean(portalEmbed)
  const { t } = useTranslation(['Common', 'Membership'])

  const business = useSelector(getCurrentBusiness) || {}
  const { customColorsEnabled, buttonsColor } = business
  const classes = useStyles({
    classes: classesProp,
    isPortalEmbed,
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const plans = getEnabledPlans(wellnessPlansVersion)

  const groupWellnessPlans = useGroupedWellnessPlans()
  const { packages = [], extras = [], tiers = [] } = groupWellnessPlans(plans)

  if (plans.length === 0) {
    return null
  }

  const hasTiers = tiers.length > 0
  const hasExtras = extras.length > 0
  const hasPackages = packages.length > 0

  const hasManyColumns = hasTiers && hasExtras && hasPackages

  return (
    <Grid
      container
      item
      alignItems={isMobile ? 'stretch' : 'flex-start'}
      className={classes.root}
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? 0 : 2}
      wrap="nowrap"
    >
      {hasTiers && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
            wrap="nowrap"
          >
            <Grid
              container
              item
              className={classes.plansHeaderContainer}
              direction="column"
            >
              <Typography className={classes.plansHeaderText}>
                {t('Common:WELLNESS_PLANS')}
              </Typography>
              <Typography>{t('Common:ONE_PER_PET')}</Typography>
            </Grid>
            {tiers.map((tier) => (
              <WellnessPlanReviewTablePlanRow
                showPrice
                dense={hasManyColumns}
                key={tier.id}
                plan={tier}
                wellnessPlanVersion={wellnessPlansVersion}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {hasExtras && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
            wrap="nowrap"
          >
            <Grid
              container
              item
              className={classes.plansHeaderContainer}
              direction="column"
            >
              <Typography className={classes.plansHeaderText}>
                {t('Common:WELLNESS_EXTRAS')}
              </Typography>
              <Typography>
                {t('Membership:ANY_NUMBER_PER_PETS_IN_PLAN')}
              </Typography>
            </Grid>
            {extras.map((extra) => (
              <WellnessPlanReviewTablePlanRow
                showPrice
                dense={hasManyColumns}
                key={extra.id}
                plan={extra}
                wellnessPlanVersion={wellnessPlansVersion}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {hasPackages && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
            wrap="nowrap"
          >
            <Grid
              container
              item
              className={classes.plansHeaderContainer}
              direction="column"
            >
              <Typography className={classes.plansHeaderText}>
                {t('Common:SUBSCRIBE_AND_SAVE')}
              </Typography>
              <Typography>{t('Membership:ANY_NUMBER_PER_PET')}</Typography>
            </Grid>
            {packages.map((packageItem) => (
              <WellnessPlanReviewTablePlanRow
                showPrice
                dense={hasManyColumns}
                key={packageItem.id}
                plan={packageItem}
                wellnessPlanVersion={wellnessPlansVersion}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default WellnessPlanBenefitsTable
