import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import { BusinessAppointmentType } from '../../types/entities/appointments'
import { getErrorMessage } from '../../utils/errors'
import type { RootState } from '..'

export type KioskAppointmentReasons = {
  appointmentTypeId: string
  id: string
  name: string
}

export type AppointmentTypesState = {
  error: string | null
  isLoading: boolean
  list: string[]
  map: Record<string, BusinessAppointmentType>
  totalCount: number
}

export const INITIAL_STATE: AppointmentTypesState = {
  error: null,
  map: {},
  list: [],
  isLoading: false,
  totalCount: 0,
}

const appointmentTypesSlice = createSlice({
  name: 'appointmentTypes',
  initialState: INITIAL_STATE,
  reducers: {
    resetAppointmentTypes: () => INITIAL_STATE,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAppointmentTypesList: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ from: number; to: number }>,
    ) => {
      state.list = []
      state.totalCount = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT
      state.isLoading = true
      state.error = null
    },
    fetchAppointmentTypesListSuccess: (
      state,
      action: PayloadAction<{
        list: string[]
        map: Record<string, BusinessAppointmentType>
        totalCount: number
      }>,
    ) => {
      state.list = R.uniq(action.payload.list)
      state.map = action.payload.map
      state.totalCount = action.payload.totalCount
      state.isLoading = false
    },
    fetchAppointmentTypesListFailure: (
      state,
      action: PayloadAction<ApiError>,
    ) => {
      state.error = getErrorMessage(action.payload)
    },
  },
})

const { actions } = appointmentTypesSlice

export const {
  resetAppointmentTypes,
  fetchAppointmentTypesList,
  fetchAppointmentTypesListSuccess,
  fetchAppointmentTypesListFailure,
} = actions

export const appointmentTypesReducer = appointmentTypesSlice.reducer

export const getAppointmentTypes = (state: RootState): AppointmentTypesState =>
  state.appointmentTypes
export const getAppointmentTypesIsLoading = (state: RootState) =>
  getAppointmentTypes(state).isLoading
export const getAppointmentTypesMap = (state: RootState) =>
  getAppointmentTypes(state).map
export const getAppointmentTypesIds = (state: RootState) =>
  getAppointmentTypes(state).list
export const getAppointmentReasons = createSelector<
  RootState,
  KioskAppointmentReasons[]
>(
  getAppointmentTypesIds,
  getAppointmentTypesMap,
  (
    appointmentTypeIds: string[],
    appointmentTypeMap: Record<string, BusinessAppointmentType>,
  ) =>
    appointmentTypeIds.map((id: string) => ({
      id,
      name: appointmentTypeMap[id].name,
      appointmentTypeId: id,
    })),
)
