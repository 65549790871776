import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import ContactUsScreen from './ContactUsScreen'

const PaymentAlreadyPaidScreen = () => {
  const { t } = useTranslation('Payment')

  return (
    <ContactUsScreen>
      <Text strong align="center" variant="subheading2" whiteSpace="pre-wrap">
        {t(
          'Payment:IT_LOOKS_LIKE_YOUR_INVOICE_WAS_ALREADY_PAID_PLEASE_LET_US_KNOW',
        )}
      </Text>
    </ContactUsScreen>
  )
}

export default PaymentAlreadyPaidScreen
