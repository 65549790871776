import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, moment, Text } from '@pbt/pbt-ui-components'

import { getAppointmentConfirmationAppointment } from '../../../store/duck/appointmentConfirmation'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
      },
    },
    caption: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
        padding: theme.spacing(2),
      },
    },
    vetInfo: {
      fontWeight: 400,
      marginTop: theme.spacing(1.5),
      fontSize: '1.8rem',
    },
  }),
  { name: 'AppointmentConfirmationConfirmedScreen' },
)

const AppointmentConfirmationConfirmedScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const appointment = useSelector(getAppointmentConfirmationAppointment)

  const client = appointment.client || {}
  const patient = appointment.patient || {}
  const appointmentStart = moment(appointment.scheduledStartDatetime)
  const veterinarian =
    appointment.vet ||
    appointment.personResponsibilities?.[0]?.person ||
    appointment.personRoles?.[0]?.person

  const date = appointmentStart.format(DateFormat.DAY_OF_WEEK_MONTH_DAY)
  const time = appointmentStart.format(DateFormat.FULL_TIME_WITH_MERIDIAN)

  return (
    <KioskScreen
      classes={{
        title: classes.title,
      }}
      justifyContent="flex-start"
      title={`${t(
        'Common:THANKS_FOR_CONFIRMING_YOUR_APPOINTMENT_WE_WILL_SEE_YOU_THEN',
      )}!`}
    >
      <Text strong align="center" className={classes.caption} variant="h2">
        {`${patient.name} ${client.lastName}`}
        <br />
        {t('Common:DATE_AT_TIME', { date, time })}
      </Text>
      {veterinarian && (
        <Text
          align="center"
          className={classes.vetInfo}
          strong={false}
          variant="h2"
        >
          {t('Common:WITH_PERSON', {
            personName: `${veterinarian.firstName} ${veterinarian.lastName}`,
          })}
        </Text>
      )}
    </KioskScreen>
  )
}

export default AppointmentConfirmationConfirmedScreen
