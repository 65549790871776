/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, moment, PhoneUtils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../store/duck/businesses'
import Header from './Header'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      zIndex: 1,
      flex: 1,
      maxWidth: '856px !important',
      height: `calc(100% - ${theme.spacing(14)})`,
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(2),
      },
    },
    businessLogo: {
      zIndex: 1,
    },
    businessInfoContainer: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    text: {
      color: (props) =>
        props.buttonsColor
          ? theme.colors.profileText
          : theme.colors.secondaryText,
    },
  }),
  { name: 'KioskWrapper' },
)

const RootRoute = ({
  Component,
  redirectCondition,
  isRoot,
  url,
  redirectUrl,
}) =>
  !redirectCondition || isRoot ? (
    <Component rootUrl={url} />
  ) : (
    <Navigate replace to={redirectUrl} />
  )

const defaultRoutes = []

const KioskWrapper = ({
  hideBusinessLogo = false,
  hideBusinessInfo = false,
  businessLogoClickable,
  routes = defaultRoutes,
  redirectCondition,
  rootUrl = '/',
}) => {
  const location = useLocation()
  const business = useSelector(getCurrentBusiness) || {}
  const { t } = useTranslation('Common')

  const { customColorsEnabled, buttonsColor, workingHours, timeZone } = business
  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const businessHoursFrom = moment.tz(workingHours?.from, timeZone)
  const businessHoursTo = moment.tz(workingHours?.to, timeZone)

  return (
    <Grid container item className={classes.wrapper} direction="column">
      {!hideBusinessLogo && (
        <Header
          className={classes.businessLogo}
          clickable={businessLogoClickable}
          rootUrl={rootUrl}
        />
      )}
      <Routes>
        {routes.map(({ Component, path, isRoot }) => (
          <Route
            element={
              <RootRoute
                Component={Component}
                isRoot={isRoot}
                redirectCondition={redirectCondition}
                redirectUrl={`${rootUrl}${location.search}`}
                url={rootUrl}
              />
            }
            key={path || '/'}
            path={path}
          />
        ))}
      </Routes>
      {!hideBusinessInfo && (
        <Grid
          container
          item
          className={classes.businessInfoContainer}
          justifyContent="space-between"
        >
          {workingHours && (
            <Typography className={classes.text} variant="body2">
              {t('Common:KIOSK_WRAPPER.WORKING_HOURS', {
                businessHoursFrom: businessHoursFrom.format(
                  DateFormat.FULL_TIME_WITH_MERIDIAN,
                ),
                businessHoursTo: businessHoursTo.format(
                  DateFormat.FULL_TIME_WITH_MERIDIAN,
                ),
              })}
            </Typography>
          )}
          {business.phone && (
            <Typography className={classes.text} variant="body2">
              {`${t(
                'Common:YOU_CAN_ALWAYS_GIVE_US_CALL',
              )}: ${PhoneUtils.formatPhoneNumber(business.phone)}`}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default KioskWrapper
