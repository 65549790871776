import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NumberUtils } from '@pbt/pbt-ui-components'

import InvoiceGroupHeader from './InvoiceGroupHeader'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
    },
    boldText: {
      fontWeight: 500,
    },
    groupHeaderContainer: {
      padding: theme.spacing(2, 1, 1),
    },
    item: {
      flexShrink: 0,
      padding: theme.spacing(2, 1),
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    declinedCell: {
      color: theme.colors.tabLabel,
    },
  }),
  { name: 'InvoiceTableMobile' },
)

const InvoiceTableMobile = ({ invoice }) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const groups = invoice?.groups || []

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      {groups.map((group) => (
        <React.Fragment key={group.soap?.id || 'otc'}>
          <Grid item className={classes.groupHeaderContainer}>
            <InvoiceGroupHeader group={group} invoice={invoice} />
          </Grid>
          {group.groupedItems.map((item, index) => {
            const isGrouped = item.items?.length > 0
            const isDeclined = item.declined

            return (
              <Grid
                container
                item
                className={classes.item}
                justifyContent="space-between"
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                wrap="nowrap"
              >
                <Typography
                  className={classNames({
                    [classes.declinedText]: isDeclined,
                  })}
                  variant="body2"
                >
                  {isGrouped ? item.groupName : item.name}
                </Typography>
                <Typography
                  className={classNames(classes.boldText, {
                    [classes.declinedText]: isDeclined,
                  })}
                  variant="body2"
                >
                  {isDeclined
                    ? `(${t('Common:DECLINED')})`
                    : NumberUtils.formatMoney(item.subTotal)}
                </Typography>
              </Grid>
            )
          })}
        </React.Fragment>
      ))}
    </Grid>
  )
}

InvoiceTableMobile.propTypes = {
  invoice: PropTypes.object,
}

export default InvoiceTableMobile
