import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'

import {
  enterFullscreen,
  exitFullscreen,
  isFullscreen,
  supportsFullscreen,
} from '../utils'

const vendors = ['', 'webkit', 'moz']

const FullscreenLink = ({ className }) => {
  const { t } = useTranslation('Common')

  const [isFs, setIsFs] = useState(false)

  const supportsFs = supportsFullscreen()

  const onFullscreenChange = () => {
    setIsFs(isFullscreen())
  }

  const watchFullScreenChange = () => {
    vendors.forEach((vendor) =>
      document.addEventListener(
        `${vendor}fullscreenchange`,
        onFullscreenChange,
      ),
    )
    // MS uses different casing:
    document.addEventListener('MSFullscreenChange', onFullscreenChange)
  }

  const unwatchFullScreenChange = () => {
    vendors.forEach((vendor) =>
      document.removeEventListener(
        `${vendor}fullscreenchange`,
        onFullscreenChange,
      ),
    )
    // MS uses different casing:
    document.removeEventListener('MSFullscreenChange', onFullscreenChange)
  }

  useEffect(() => {
    watchFullScreenChange()

    return () => unwatchFullScreenChange()
  }, [])

  const toggleFullscreen = () => {
    if (supportsFs) {
      if (!isFullscreen()) {
        enterFullscreen(document.documentElement)
      } else {
        exitFullscreen()
      }
    }
  }

  return supportsFs ? (
    <Link className={className} onClick={toggleFullscreen}>
      {isFs ? t('Common:EXIT_FULLSCREEN') : t('Common:ENTER_FULLSCREEN')}
    </Link>
  ) : null
}

export default FullscreenLink
