import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, LinkButton } from '@pbt/pbt-ui-components'
import { LinkButtonProps } from '@pbt/pbt-ui-components/src/components/buttons/LinkButton'

import { getCurrentBusiness } from '../../store/duck/businesses'

const useStyles = makeStyles(
  () => ({
    button: {
      border: 'none',
      color: (item: UseStylesProps) => item.buttonsColor,
    },
  }),
  { name: 'KioskLinkButton' },
)

interface UseStylesProps {
  buttonsColor: string | undefined
}

interface KioskLinkButtonProps extends LinkButtonProps {
  readonly classes?: ClassesType<typeof useStyles>
}

const KioskLinkButton = ({
  classes: classesProp,
  ...rest
}: KioskLinkButtonProps) => {
  const currentBusiness = useSelector(getCurrentBusiness)

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}

  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  return (
    <LinkButton
      classes={{
        button: classes.button,
        ...classesProp,
      }}
      {...rest}
    />
  )
}

export default KioskLinkButton
