import { all, put, takeLatest } from 'redux-saga/effects'

import * as API from '../../api'
import requestAPI from '../sagas/requestAPI'

export const FETCH_QUESTIONS_LOG = 'questions/FETCH_QUESTIONS_LOG'
export const FETCH_QUESTIONS_LOG_SUCCESS =
  'questions/FETCH_QUESTIONS_LOG_SUCCESS'
export const FETCH_QUESTIONS_LOG_FAILURE =
  'questions/FETCH_QUESTIONS_LOG_FAILURE'

export const SAVE_QUESTIONS_LOG = 'questions/SAVE_QUESTIONS_LOG'
export const SAVE_QUESTIONS_LOG_SUCCESS = 'questions/SAVE_QUESTIONS_LOG_SUCCESS'
export const SAVE_QUESTIONS_LOG_FAILURE = 'questions/SAVE_QUESTIONS_LOG_FAILURE'

export const fetchQuestionsLog = (appointmentId) => ({
  type: FETCH_QUESTIONS_LOG,
  appointmentId,
})
export const fetchQuestionsLogSuccess = (log) => ({
  type: FETCH_QUESTIONS_LOG_SUCCESS,
  log,
})
export const fetchQuestionsLogFailure = (error) => ({
  type: FETCH_QUESTIONS_LOG_FAILURE,
  error,
})

export const saveQuestionsLog = (appointmentId, log) => ({
  type: SAVE_QUESTIONS_LOG,
  appointmentId,
  log,
})
export const saveQuestionsLogSuccess = (log) => ({
  type: SAVE_QUESTIONS_LOG_SUCCESS,
  log,
})
export const saveQuestionsLogFailure = (error) => ({
  type: SAVE_QUESTIONS_LOG_FAILURE,
  error,
})

const INITIAL_STATE = {
  log: [],
  error: null,
  isLoading: false,
}

export const questionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_LOG:
    case SAVE_QUESTIONS_LOG:
      return { ...state, isLoading: true }
    case FETCH_QUESTIONS_LOG_SUCCESS:
    case SAVE_QUESTIONS_LOG_SUCCESS:
      return { ...state, isLoading: false, log: action.log }
    case FETCH_QUESTIONS_LOG_FAILURE:
    case SAVE_QUESTIONS_LOG_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    default:
      return state
  }
}

export const getQuestions = (state) => state.questions
export const getQuestionsLog = (state) => getQuestions(state).log
export const getQuestionsIsLoading = (state) => getQuestions(state).isLoading
export const getQuestionsError = (state) => getQuestions(state).error

export function* sagaFetchQuestionsLog({ appointmentId }) {
  try {
    const log = yield* requestAPI(API.fetchQuestionsLog, appointmentId)
    yield put(fetchQuestionsLogSuccess(log))
  } catch (error) {
    yield put(fetchQuestionsLogFailure(error))
  }
}

export function* sagaSaveQuestionsLog({ appointmentId, log }) {
  try {
    const result = yield* requestAPI(API.saveQuestionsLog, appointmentId, log)
    yield put(saveQuestionsLogSuccess(result))
  } catch (error) {
    yield put(saveQuestionsLogFailure(error))
  }
}

function* watchFetchQuestionsLog() {
  yield takeLatest(FETCH_QUESTIONS_LOG, sagaFetchQuestionsLog)
}

function* watchSaveQuestionsLog() {
  yield takeLatest(SAVE_QUESTIONS_LOG, sagaSaveQuestionsLog)
}

export function* questionsSaga() {
  yield all([watchFetchQuestionsLog(), watchSaveQuestionsLog()])
}
