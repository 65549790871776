import React from 'react'

// @ts-ignore
import KioskWrapper from '../../KioskWrapper'
import clientRoutes from './clientRoutes'

const ROOT_URL = '/client'

const ClientWrapperScreen = () => (
  <KioskWrapper rootUrl={ROOT_URL} routes={clientRoutes} />
)

export default ClientWrapperScreen
