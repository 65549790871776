import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { parse } from 'query-string'

import {
  fetchNPSData,
  getNPSData,
  getNPSDataLoading,
  updateNPSData,
} from '../../store/duck/nps'
import CompleteFeedback from './CompleteFeedback'
import IncompleteFeedback from './IncompleteFeedback'

const UserFeedback = () => {
  const { token } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()

  const { score } = parse(location.search)

  const npsData = useSelector(getNPSData)
  const loading = useSelector(getNPSDataLoading)

  const { modificationKey } = npsData || {}

  const onGradeChangeCallback = useCallback(
    (grade: number) => {
      if (token && modificationKey) {
        dispatch(updateNPSData(token, { modificationKey, score: grade }))
      }
    },
    [token, modificationKey],
  )

  useEffect(() => {
    if (token && score) {
      dispatch(fetchNPSData(token, Number(score)))
    }
  }, [])

  if (loading || !npsData) {
    return <CircularProgress />
  }

  return modificationKey ? (
    <IncompleteFeedback onGradeChange={onGradeChangeCallback} />
  ) : (
    <CompleteFeedback />
  )
}

export default UserFeedback
