import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiAutocomplete,
  PuiSelect,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import { getBreed, getSpecies } from '../../../store/duck/constants'
import GenderField from '../../common/input/GenderField'
import SpayedNeuteredField from '../../common/input/SpayedNeuteredField'
import KioskCalendar from '../../inputs/KioskCalendar'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 600,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
  { name: 'PatientDetailsScreen' },
)

const PatientDetailsScreen = forwardRef(function PatientDetailsScreen(
  { patient },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Abbreviations'])

  const Species = useSelector(getSpecies)
  const Breed = useSelector(getBreed)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const rowSpacing = isMobile ? 1 : 3
  const chipSpacing = isMobile ? 2 : 4

  const {
    fields: { name, dob, species, color, breed, gender, spayedNeutered },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:NAME'),
        validators: ['required'],
        initialValue: patient?.name,
      },
      {
        name: 'dob',
        label: t('Abbreviations:DATE_OF_BIRTH'),
        validators: ['timestamp'],
        initialValue: patient?.dateOfBirth,
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        validators: ['required'],
        initialValue: patient?.species,
      },
      { name: 'color', label: t('Common:COLOR'), initialValue: patient?.color },
      {
        name: 'breed',
        label: t('Common:BREED'),
        type: 'select',
        initialValue: patient?.breeds || [],
      },
      {
        name: 'gender',
        type: 'none',
        validators: ['required'],
        initialValue: patient?.gender,
      },
      {
        name: 'spayedNeutered',
        type: 'none',
        validators: ['required'],
        initialValue: patient?.spayedNeutered,
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [patient])

  const getPatient = () => ({
    ...(patient || {}),
    name: name.value,
    dateOfBirth: dob.value,
    species: species.value,
    color: color.value,
    breeds: breed.value,
    gender: gender.value,
    spayedNeutered: spayedNeutered.value,
  })

  useImperativeHandle(ref, () => ({
    validate,
    getPatient,
  }))

  const isNoBreeds =
    !species.value || !Breed[species.value] || Breed[species.value].length === 0
  const breedsLabel = !isNoBreeds
    ? breed.label
    : species.value
    ? t('Common:THERE_ARE_NO_BREEDS_FOR_SPECIES', {
        species: LanguageUtils.getConstantTranslatedName(
          species.value,
          Species,
        ),
      })
    : t('Common:SELECT_SPECIES_PLEASE')

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      spacing={rowSpacing}
    >
      <Grid item sm={6} xs={12}>
        <PuiTextField
          fullWidth
          field={name}
          inputProps={{ maxLength: 100 }}
          label={`${name.label}*`}
          type="text"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <KioskCalendar fullWidth field={dob} label={dob.label} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth error={!species.valid}>
          <InputLabel htmlFor="species-select">{`${species.label}*`}</InputLabel>
          <PuiSelect
            field={species}
            input={<Input id="species-select" />}
            items={Species}
            valueProp="humanReadable"
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <PuiTextField
          fullWidth
          field={color}
          inputProps={{ maxLength: 100 }}
          label={color.label}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <PuiAutocomplete
          multiple
          disabled={isNoBreeds}
          field={breed}
          label={breedsLabel}
          minSearchLength={1}
          options={Breed[species.value]}
        />
      </Grid>
      <Grid container item spacing={chipSpacing} xs={12}>
        <Grid item sm={6} xs={12}>
          <GenderField required field={gender} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SpayedNeuteredField
            required
            field={spayedNeutered}
            gender={gender.value}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

export default PatientDetailsScreen
