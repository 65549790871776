import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import {
  getFinanceBillingAddress,
  updateBillingAddress,
} from '../../../store/duck/finance'
import { useNavigateWithQueryString } from '../../../utils'
import BillingAddressScreen from '../common/BillingAddressScreen'
import PaymentDetailsScreen from './PaymentDetailsScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(1, 1, 3),
    },
  }),
  { name: 'BillingAddressScreen' },
)

const PaymentBillingAddressScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const billingAddress = useSelector(getFinanceBillingAddress)

  const billingAddressRef = useRef()

  const onProceed = () => {
    if (billingAddressRef.current.validate()) {
      dispatch(
        updateBillingAddress(billingAddressRef.current.getBillingAddress()),
      )

      navigateWithQueryString({ url: '/payment/method' })
    }
  }

  return (
    <PaymentDetailsScreen onProceed={onProceed}>
      <BillingAddressScreen
        billingAddress={billingAddress}
        formClasses={{
          content: classes.content,
        }}
        ref={billingAddressRef}
      />
    </PaymentDetailsScreen>
  )
}

export default PaymentBillingAddressScreen
