import { useSelector } from 'react-redux'

import Features from '../constants/features'
import { getCurrentBusinessHasOpenBoop } from '../store/duck/businesses'
import { getFeatureToggle } from '../store/duck/constants'

const useBoopEnabled = () => {
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(Features.BOOP_DISABLEMENT),
  )

  return !isBoopDisablementEnabled || openBoop
}

export default useBoopEnabled
