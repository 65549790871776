import * as R from 'ramda'

const memberComparator = R.ascend(R.prop('member'))
const priorityPatientComparator = (patientId) =>
  R.descend(R.pathEq(['patient', 'id'], patientId))
const patientNameComparator = R.ascend(
  R.compose(R.toLower, R.path(['patient', 'name'])),
)

export const sortMembershipsByPriorityPatient = (priorityPatientId) =>
  R.sortWith(
    [
      memberComparator,
      ...[priorityPatientId && priorityPatientComparator(priorityPatientId)],
      patientNameComparator,
    ].filter(Boolean),
  )
