import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { without } from 'ramda'

import InvoiceGroupHeader from './InvoiceGroupHeader'
import InvoiceTableMobile from './InvoiceTableMobile'
import InvoiceTableRow from './InvoiceTableRow'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      padding: theme.spacing(1, 1),
      borderRight: theme.constants.tabBorder,
    },
    headerCell: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
      borderTop: theme.constants.tabBorder,
      height: 25,
      padding: theme.spacing(0, 2),
    },
    bodyHeaderCell: {
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'InvoiceTable' },
)

const getExpandedGroupName = (group, soap) => `${soap?.id || null}-${group}`

const InvoiceTable = ({ invoice }) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const groups = invoice?.groups || []

  const [expandedGroups, setExpandedGroups] = useState([])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const onExpandChange = (group, soap) => {
    const name = getExpandedGroupName(group, soap)
    if (expandedGroups.includes(name)) {
      setExpandedGroups(without([name], expandedGroups))
    } else {
      setExpandedGroups(expandedGroups.concat(name))
    }
  }

  if (isMobile) {
    return <InvoiceTableMobile invoice={invoice} />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          />
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:QUANTITY')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:DISCOUNT')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:TAX')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:ITEM_TOTAL')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groups.map((group) => (
          <React.Fragment key={group.soap?.date || 'otc'}>
            <TableRow>
              <TableCell
                className={classNames(
                  classes.tableCell,
                  classes.bodyHeaderCell,
                )}
              >
                <InvoiceGroupHeader group={group} invoice={invoice} />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
            {group.groupedItems.map((item, index) => (
              <InvoiceTableRow
                invoice={invoice}
                isExpanded={expandedGroups.includes(
                  getExpandedGroupName(item.group, group.soap),
                )}
                item={item}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                soap={group.soap}
                onExpandChange={onExpandChange}
              />
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

InvoiceTable.propTypes = {
  invoice: PropTypes.object,
}

export default InvoiceTable
