import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PlusButton } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../store/duck/clients'
import { updateCurrentPatient } from '../../store/duck/patients'
import { useNavigateWithQueryString } from '../../utils'
import PatientCard from './PatientCard'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1, 1, 0),
      overflow: 'auto',
      position: 'absolute',
      top: 0,
      height: '100%',
    },
    plusButtonRoot: {
      [theme.breakpoints.up('sm')]: {
        width: 382,
      },
      flexShrink: 0,
    },
    plusButtonLabel: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    plusButtonIconContainer: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      marginRight: theme.spacing(1.5),
    },
    plusIcon: {
      width: 38,
      height: 38,
    },
    patientCard: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(3),
      },
    },
  }),
  { name: 'PatientsList' },
)

const PatientsList = ({ selectedPatient, onPatientSelected }) => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const client = useSelector(getCurrentClient) || {}

  const filteredPatients = (client.patients || []).filter(
    (patient) => patient.active !== false && patient.deceased !== true,
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      {filteredPatients.map((patient) => (
        <PatientCard
          className={classes.patientCard}
          isSelected={selectedPatient && selectedPatient.id === patient.id}
          key={patient.id}
          patient={patient}
          onSelected={onPatientSelected}
        />
      ))}
      <PlusButton
        alignItems="center"
        classes={{
          root: classes.plusButtonRoot,
          label: classes.plusButtonLabel,
          iconContainer: classes.plusButtonIconContainer,
          icon: classes.plusIcon,
        }}
        label={t('Common:ADD_ANOTHER_PET')}
        my={2}
        onClick={() => {
          // clear in case any patients were selected before
          dispatch(updateCurrentPatient())
          navigateWithQueryString({ url: '/patient-details' })
        }}
      />
    </Grid>
  )
}

export default PatientsList
