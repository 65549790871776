import React from 'react'
import { Grid, Link, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      alignContent: 'center',
    },
    text: {
      textAlign: 'center',
      fontSize: '1.8rem',
      marginBottom: theme.spacing(1),
    },
    textMobile: {
      fontSize: '1.4rem',
    },
    title: {
      fontWeight: 500,
    },
  }),
  { name: 'ConferencingInfo' },
)

const ConferencingInfo = ({
  title,
  dialIn,
  meetingLink,
  classes: classesProp,
}) => {
  const classes = useStyles({ classes: classesProp })
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const textStyles = classNames(classes.text, {
    [classes.textMobile]: isMobile,
  })

  return (
    <Grid container className={classes.root} direction="column">
      <Typography className={classNames(classes.title, textStyles)}>
        {title}
      </Typography>
      <Typography className={textStyles}>{dialIn}</Typography>
      <Link className={textStyles} href={meetingLink}>
        {meetingLink}
      </Link>
    </Grid>
  )
}

export default ConferencingInfo
