import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { clearCurrentClient } from '../../../store/duck/clients'
import { clearCurrentFlow, getIsEmbedded } from '../../../store/duck/flow'
import { clearCurrentPatient } from '../../../store/duck/patients'
import { clearCurrentAppointment } from '../../../store/duck/schedules'
import { clearWellnessPlans } from '../../../store/duck/wellnessPlans'
import EmbedWelcomeScreen from './EmbedWelcomeScreen'
import InClinicWelcomeScreen from './InClinicWelcomeScreen'

const WelcomeScreen = () => {
  const dispatch = useDispatch()
  const isEmbedded = useSelector(getIsEmbedded)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const eventId = queryParams.get('eventId')
  const clientId = queryParams.get('clientId')

  useEffect(() => {
    if (eventId && clientId) {
      return
    }
    dispatch(clearCurrentPatient())
    dispatch(clearCurrentClient())
    dispatch(clearCurrentAppointment())
    dispatch(clearWellnessPlans())
    dispatch(clearCurrentFlow())
  }, [])

  return isEmbedded ? <EmbedWelcomeScreen /> : <InClinicWelcomeScreen />
}

export default WelcomeScreen
