import { AnyAction } from 'redux'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import {
  ApiError,
  AtLeast,
  DateUtils,
  Patient,
  User,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import * as API from '../../api'
import {
  Appointment,
  AppointmentToCreateFromStore,
  BusinessAppointmentToCreate,
  BusinessAppointmentToEdit,
  BusinessAppointmentToEditFromStore,
  WaitlistBusinessAppointmentToCreate,
} from '../../types/entities/appointments'
import {
  Availability,
  AvailableStuff,
  TimeSlot,
} from '../../types/entities/schedules'
import type { RootState } from '../index'
// @ts-ignore
import requestAPI from '../sagas/requestAPI'
import { getCurrentBusinessId } from './businesses'
// @ts-ignore
import { getCurrentClient } from './clients'
// @ts-ignore
import { getCurrentPatient } from './patients'

export const FETCH_AVAILABLE_SLOTS = 'schedules/FETCH_AVAILABLE_SLOTS'
export const FETCH_AVAILABLE_SLOTS_SUCCESS =
  'schedules/FETCH_AVAILABLE_SLOTS_SUCCESS'
export const FETCH_AVAILABLE_SLOTS_FAILURE =
  'schedules/FETCH_AVAILABLE_SLOTS_FAILURE'

export const FETCH_AVAILABLE_STUFF = 'schedules/FETCH_AVAILABLE_STUFF'
export const FETCH_AVAILABLE_STUFF_SUCCESS =
  'schedules/FETCH_AVAILABLE_STUFF_SUCCESS'
export const FETCH_AVAILABLE_STUFF_FAILURE =
  'schedules/FETCH_AVAILABLE_STUFF_FAILURE'

export const FETCH_APPOINTMENT = 'schedules/FETCH_APPOINTMENT'
export const FETCH_APPOINTMENT_SUCCESS = 'schedules/FETCH_APPOINTMENT_SUCCESS'
export const FETCH_APPOINTMENT_FAILURE = 'schedules/FETCH_APPOINTMENT_FAILURE'

export const FETCH_APPOINTMENT_ICS_URL = 'schedules/FETCH_APPOINTMENT_ICS_URL'
export const FETCH_APPOINTMENT_ICS_URL_SUCCESS =
  'schedules/FETCH_APPOINTMENT_ICS_URL_SUCCESS'
export const FETCH_APPOINTMENT_ICS_URL_FAILURE =
  'schedules/FETCH_APPOINTMENT_ICS_URL_FAILURE'

export const UPDATE_CURRENT_APPOINTMENT = 'schedules/UPDATE_CURRENT_APPOINTMENT'

export const CREATE_APPOINTMENT = 'schedules/CREATE_APPOINTMENT'
export const CREATE_APPOINTMENT_SUCCESS = 'schedules/CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILURE = 'schedules/CREATE_APPOINTMENT_FAILURE'

export const EDIT_APPOINTMENT = 'schedules/EDIT_APPOINTMENT'
export const EDIT_APPOINTMENT_SUCCESS = 'schedules/EDIT_APPOINTMENT_SUCCESS'
export const EDIT_APPOINTMENT_FAILURE = 'schedules/EDIT_APPOINTMENT_FAILURE'

export const CREATE_WAITLIST_APPOINTMENT =
  'schedules/CREATE_WAITLIST_APPOINTMENT'
export const CREATE_WAITLIST_APPOINTMENT_SUCCESS =
  'schedules/CREATE_WAITLIST_APPOINTMENT_SUCCESS'
export const CREATE_WAITLIST_APPOINTMENT_FAILURE =
  'schedules/CREATE_WAITLIST_APPOINTMENT_FAILURE'

export const CHECK_IN_FOR_APPOINTMENT = 'schedules/CHECK_IN_FOR_APPOINTMENT'
export const CHECK_IN_FOR_APPOINTMENT_SUCCESS =
  'schedules/CHECK_IN_FOR_APPOINTMENT_SUCCESS'
export const CHECK_IN_FOR_APPOINTMENT_FAILURE =
  'schedules/CHECK_IN_FOR_APPOINTMENT_FAILURE'

export const FETCH_WAITLIST = 'schedules/FETCH_WAITLIST'
export const FETCH_WAITLIST_SUCCESS = 'schedules/FETCH_WAITLIST_SUCCESS'
export const FETCH_WAITLIST_FAILURE = 'schedules/FETCH_WAITLIST_FAILURE'

export const CLEAR_CURRENT_APPOINTMENT = 'schedules/CLEAR_CURRENT_APPOINTMENT'

export const UPDATE_APPOINTMENT_STATE = 'schedules/UPDATE_APPOINTMENT_STATE'
export const UPDATE_APPOINTMENT_STATE_SUCCESS =
  'schedules/UPDATE_APPOINTMENT_STATE_SUCCESS'
export const UPDATE_APPOINTMENT_STATE_FAILURE =
  'schedules/UPDATE_APPOINTMENT_STATE_FAILURE'

export const SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL =
  'schedules/SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL'
export const SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_SUCCESS =
  'schedules/SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_SUCCESS'
export const SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_FAILURE =
  'schedules/SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_FAILURE'

export const SEND_ZOOM_LINK_MESSAGE = 'schedules/SEND_ZOOM_LINK_MESSAGE'
export const SEND_ZOOM_LINK_MESSAGE_SUCCESS =
  'schedules/SEND_ZOOM_LINK_MESSAGE_SUCCESS'
export const SEND_ZOOM_LINK_MESSAGE_FAILURE =
  'schedules/SEND_ZOOM_LINK_MESSAGE_FAILURE'

export const CLEAR_SLOTS_UNAVAILABLE_ERROR =
  'schedules/CLEAR_SLOTS_UNAVAILABLE_ERROR'

export const fetchAvailableSlots = (
  businessAppointmentTypeId: string,
  date: string,
) => ({
  type: FETCH_AVAILABLE_SLOTS,
  businessAppointmentTypeId,
  date,
})
export const fetchAvailableSlotsSuccess = (availability: Availability) => ({
  type: FETCH_AVAILABLE_SLOTS_SUCCESS,
  availability,
})
export const fetchAvailableSlotsFailure = (error: ApiError) => ({
  type: FETCH_AVAILABLE_SLOTS_FAILURE,
  error,
})

export const fetchAvailableStuff = (
  appointmentTypeId: string,
  date: string,
  currentEventId?: string,
) => ({
  type: FETCH_AVAILABLE_STUFF,
  appointmentTypeId,
  date,
  currentEventId,
})
export const fetchAvailableStuffSuccess = (availability: Availability) => ({
  type: FETCH_AVAILABLE_STUFF_SUCCESS,
  availability,
})
export const fetchAvailableStuffFailure = (error: ApiError) => ({
  type: FETCH_AVAILABLE_STUFF_FAILURE,
  error,
})

export const fetchAppointment = (appointmentId: string) => ({
  type: FETCH_APPOINTMENT,
  appointmentId,
})
export const fetchAppointmentSuccess = (appointment: Appointment) => ({
  type: FETCH_APPOINTMENT_SUCCESS,
  appointment,
})
export const fetchAppointmentFailure = (error: ApiError) => ({
  type: FETCH_APPOINTMENT_FAILURE,
  error,
})

export const fetchAppointmentIcsUrl = (appointmentId: string) => ({
  type: FETCH_APPOINTMENT_ICS_URL,
  appointmentId,
})
export const fetchAppointmentIcsUrlSuccess = (icsFileUrl: string) => ({
  type: FETCH_APPOINTMENT_ICS_URL_SUCCESS,
  icsFileUrl,
})
export const fetchAppointmentIcsUrlFailure = (error: ApiError) => ({
  type: FETCH_APPOINTMENT_ICS_URL_FAILURE,
  error,
})

export const updateCurrentAppointment = (
  appointment: AtLeast<Appointment, 'patient'> | { type?: string },
) => ({
  type: UPDATE_CURRENT_APPOINTMENT,
  appointment,
})

export const createAppointment = (
  appointment: BusinessAppointmentToCreate,
) => ({
  type: CREATE_APPOINTMENT,
  appointment,
})
export const createAppointmentSuccess = (appointment: Appointment) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  appointment,
})
export const createAppointmentFailure = (error: ApiError) => ({
  type: CREATE_APPOINTMENT_FAILURE,
  error,
})

export const editAppointment = (appointment: BusinessAppointmentToEdit) => ({
  type: EDIT_APPOINTMENT,
  appointment,
})
export const editAppointmentSuccess = (appointment: Appointment) => ({
  type: EDIT_APPOINTMENT_SUCCESS,
  appointment,
})
export const editAppointmentFailure = (error: ApiError) => ({
  type: EDIT_APPOINTMENT_FAILURE,
  error,
})

export const createWaitlistAppointment = (
  appointment: WaitlistBusinessAppointmentToCreate,
  isEmbedded: boolean,
) => ({
  type: CREATE_WAITLIST_APPOINTMENT,
  appointment,
  isEmbedded,
})
export const createWaitlistAppointmentSuccess = (appointment: Appointment) => ({
  type: CREATE_WAITLIST_APPOINTMENT_SUCCESS,
  appointment,
})
export const createWaitlistAppointmentFailure = (error: ApiError) => ({
  type: CREATE_WAITLIST_APPOINTMENT_FAILURE,
  error,
})

export const checkInForAppointment = (
  appointmentId: string,
  arrived: boolean,
) => ({
  type: CHECK_IN_FOR_APPOINTMENT,
  appointmentId,
  arrived,
})
export const checkInForAppointmentSuccess = (appointment: Appointment) => ({
  type: CHECK_IN_FOR_APPOINTMENT_SUCCESS,
  appointment,
})
export const checkInForAppointmentFailure = (error: ApiError) => ({
  type: CHECK_IN_FOR_APPOINTMENT_FAILURE,
  error,
})

export const fetchWaitlist = (silent: boolean) => ({
  type: FETCH_WAITLIST,
  silent,
})
export const fetchWaitlistSuccess = (waitlist: Appointment[]) => ({
  type: FETCH_WAITLIST_SUCCESS,
  waitlist,
})
export const fetchWaitlistFailure = (error: ApiError) => ({
  type: FETCH_WAITLIST_FAILURE,
  error,
})

export const clearCurrentAppointment = () => ({
  type: CLEAR_CURRENT_APPOINTMENT,
})

export const updateAppointmentState = (
  appointmentId: string,
  stateId: string,
) => ({
  type: UPDATE_APPOINTMENT_STATE,
  appointmentId,
  stateId,
})
export const updateAppointmentStateSuccess = (appointment: Appointment) => ({
  type: UPDATE_APPOINTMENT_STATE_SUCCESS,
  appointment,
})
export const updateAppointmentStateFailure = (error: ApiError) => ({
  type: UPDATE_APPOINTMENT_STATE_FAILURE,
  error,
})

export const sendNewAppointmentConfirmationEmail = (
  appointmentId: string,
  message: string,
) => ({
  type: SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL,
  appointmentId,
  message,
})
export const sendNewAppointmentConfirmationEmailSuccess = () => ({
  type: SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_SUCCESS,
})
export const sendNewAppointmentConfirmationEmailFailure = (
  error: ApiError,
) => ({
  type: SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_FAILURE,
  error,
})

export const sendZoomLinkMessage = (
  appointmentId: string,
  message: string,
) => ({
  type: SEND_ZOOM_LINK_MESSAGE,
  appointmentId,
  message,
})
export const sendSendZoomLinkMessageSuccess = () => ({
  type: SEND_ZOOM_LINK_MESSAGE_SUCCESS,
})
export const sendSendZoomLinkMessageFailure = (error: ApiError) => ({
  type: SEND_ZOOM_LINK_MESSAGE_FAILURE,
  error,
})

export const clearSlotsUnavailableError = () => ({
  type: CLEAR_SLOTS_UNAVAILABLE_ERROR,
})

export type SchedulesState = {
  availableSlots: TimeSlot[]
  availableStuff: AvailableStuff[]
  currentAppointment:
    | Appointment
    | AppointmentToCreateFromStore
    | BusinessAppointmentToEditFromStore
    | null
  error: string | null
  isFetchingAppointment: boolean
  isFetchingAppointmentIcs: boolean
  isFetchingStuff: boolean
  isLoading: boolean
  isSendingConfirmationEmail: boolean
  isSendingZoomLinkMessage: boolean
  lastAppointmentIcsFileUrl: string | null
  slotsAreUnavailable: boolean
  waitlist: Appointment[]
  workingHours: TimeSlot | null
}

const INITIAL_STATE: SchedulesState = {
  waitlist: [],
  workingHours: null,
  availableSlots: [],
  availableStuff: [],
  currentAppointment: null,
  error: null,
  isLoading: false,
  isFetchingAppointmentIcs: false,
  isFetchingAppointment: false,
  isFetchingStuff: false,
  isSendingConfirmationEmail: false,
  isSendingZoomLinkMessage: false,
  lastAppointmentIcsFileUrl: null,
  slotsAreUnavailable: false,
}

export const schedulesReducer = (
  state: SchedulesState = INITIAL_STATE,
  action: AnyAction,
): SchedulesState => {
  switch (action.type) {
    case FETCH_AVAILABLE_SLOTS:
      return { ...state, isLoading: true, isFetchingStuff: true }
    case FETCH_AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingStuff: false,
        availableSlots: action.availability.timeSlots,
        workingHours: action.availability.workingHours,
      }
    case FETCH_AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetchingStuff: false,
        isLoading: false,
      }
    case FETCH_AVAILABLE_STUFF:
      return { ...state, isLoading: true, isFetchingStuff: true }
    case FETCH_AVAILABLE_STUFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingStuff: false,
        availableStuff: action.availability.availableStuff,
        workingHours: action.availability.workingHours,
      }
    case FETCH_AVAILABLE_STUFF_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetchingStuff: false,
        isLoading: false,
      }
    case UPDATE_CURRENT_APPOINTMENT:
      return { ...state, currentAppointment: action.appointment }
    case FETCH_APPOINTMENT:
      return { ...state, isLoading: true, isFetchingAppointment: true }
    case FETCH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingAppointment: false,
        currentAppointment: action.appointment,
      }
    case FETCH_APPOINTMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetchingAppointment: false,
      }
    case CREATE_APPOINTMENT:
    case EDIT_APPOINTMENT:
    case CREATE_WAITLIST_APPOINTMENT:
    case CHECK_IN_FOR_APPOINTMENT:
      return { ...state, isLoading: true, slotsAreUnavailable: false }
    case CREATE_APPOINTMENT_SUCCESS:
    case EDIT_APPOINTMENT_SUCCESS:
    case CREATE_WAITLIST_APPOINTMENT_SUCCESS:
    case CHECK_IN_FOR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentAppointment: action.appointment,
      }
    case CREATE_APPOINTMENT_FAILURE:
    case EDIT_APPOINTMENT_FAILURE:
    case CREATE_WAITLIST_APPOINTMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        slotsAreUnavailable:
          action.error?.data?.description?.includes(409) ||
          action.error?.message?.includes(409) ||
          action.error?.status === 409,
      }
    case CHECK_IN_FOR_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case FETCH_WAITLIST:
      return { ...state, isLoading: action.silent ? state.isLoading : true }
    case FETCH_WAITLIST_SUCCESS:
      return { ...state, waitlist: action.waitlist, isLoading: false }
    case FETCH_WAITLIST_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case CLEAR_CURRENT_APPOINTMENT:
      return { ...state, error: null, currentAppointment: null }
    case UPDATE_APPOINTMENT_STATE_SUCCESS:
      return { ...state, currentAppointment: action.appointment }
    case SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL:
      return { ...state, isSendingConfirmationEmail: true }
    case SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_SUCCESS:
      return { ...state, isSendingConfirmationEmail: false }
    case SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        isSendingConfirmationEmail: false,
      }

    case SEND_ZOOM_LINK_MESSAGE:
      return { ...state, isSendingZoomLinkMessage: true }
    case SEND_ZOOM_LINK_MESSAGE_SUCCESS:
      return { ...state, isSendingZoomLinkMessage: false }
    case SEND_ZOOM_LINK_MESSAGE_FAILURE:
      return { ...state, error: action.error, isSendingZoomLinkMessage: false }

    case FETCH_APPOINTMENT_ICS_URL:
      return { ...state, isFetchingAppointmentIcs: true }
    case FETCH_APPOINTMENT_ICS_URL_SUCCESS:
      return {
        ...state,
        isFetchingAppointmentIcs: false,
        lastAppointmentIcsFileUrl: action.icsFileUrl,
      }
    case FETCH_APPOINTMENT_ICS_URL_FAILURE:
      return { ...state, isFetchingAppointmentIcs: false, error: action.error }
    case CLEAR_SLOTS_UNAVAILABLE_ERROR:
      return { ...state, error: null, slotsAreUnavailable: false }
    default:
      return state
  }
}

export const getSchedules = (state: RootState): SchedulesState =>
  state.schedules
export const getAvailableSlots = (state: RootState) =>
  getSchedules(state).availableSlots
export const getAvailableStuff = (state: RootState) =>
  getSchedules(state).availableStuff
export const getCurrentAppointment = (state: RootState) =>
  getSchedules(state).currentAppointment
export const getSchedulesIsLoading = (state: RootState) =>
  getSchedules(state).isLoading
export const getWaitlist = (state: RootState) => getSchedules(state).waitlist
export const getSchedulesError = (state: RootState) => getSchedules(state).error
export const getIsFetchingStuff = (state: RootState) =>
  getSchedules(state).isFetchingStuff
export const getIsFetchingAppointment = (state: RootState) =>
  getSchedules(state).isFetchingAppointment
export const getIsSendingConfirmationEmail = (state: RootState) =>
  getSchedules(state).isSendingConfirmationEmail
export const getSchedulesWorkingHours = (state: RootState) =>
  getSchedules(state).workingHours
export const getIsSendingZoomLinkMessage = (state: RootState) =>
  getSchedules(state).isSendingZoomLinkMessage
export const getLastAppointmentIcsFileUrl = (state: RootState) =>
  getSchedules(state).lastAppointmentIcsFileUrl
export const getIsFetchingAppointmentIcs = (state: RootState) =>
  getSchedules(state).isFetchingAppointmentIcs
export const getSlotsAreUnavailable = (state: RootState) =>
  getSchedules(state).slotsAreUnavailable

function* extendAppointment(
  appointment:
    | Partial<Appointment>
    | BusinessAppointmentToCreate
    | BusinessAppointmentToEdit
    | WaitlistBusinessAppointmentToCreate,
) {
  const client: User = yield select(getCurrentClient)
  const patient: Patient = yield select(getCurrentPatient)
  const businessId: string = yield select(getCurrentBusinessId)
  return {
    ...appointment,
    client: client?.id,
    patient: patient?.id,
    business: businessId,
  }
}

export function* sagaFetchAvailableSlots({
  businessAppointmentTypeId,
  date,
}: ReturnType<typeof fetchAvailableSlots>) {
  try {
    const result = yield* requestAPI(
      API.fetchAvailableSlotsV2,
      businessAppointmentTypeId,
      DateUtils.serializeDate(date),
    )
    yield put(fetchAvailableSlotsSuccess(result))
  } catch (error) {
    yield put(fetchAvailableSlotsFailure(error as ApiError))
  }
}

export function* sagaFetchAvailableStuff({
  appointmentTypeId,
  date,
  currentEventId,
}: ReturnType<typeof fetchAvailableStuff>) {
  try {
    const result = yield* requestAPI(
      API.fetchAvailableStuffV2,
      appointmentTypeId,
      DateUtils.serializeDate(date),
      currentEventId,
    )
    yield put(fetchAvailableStuffSuccess(result))
  } catch (error) {
    yield put(fetchAvailableStuffFailure(error as ApiError))
  }
}

export function* sagaCreateAppointment({
  appointment,
}: ReturnType<typeof createAppointment>) {
  try {
    const newAppointment: Appointment = yield extendAppointment(appointment)
    const result = yield* requestAPI(API.createAppointment, newAppointment)
    yield put(createAppointmentSuccess(result))
  } catch (error) {
    yield put(createAppointmentFailure(error as ApiError))
  }
}

export function* sagaEditAppointment({
  appointment,
}: ReturnType<typeof editAppointment>) {
  try {
    const result = yield* requestAPI(API.editAppointment, appointment)
    yield put(editAppointmentSuccess(result))
  } catch (error) {
    yield put(editAppointmentFailure(error as ApiError))
  }
}

export function* sagaFetchAppointment({
  appointmentId,
}: ReturnType<typeof fetchAppointment>) {
  try {
    const appointment = yield* requestAPI(API.fetchAppointment, appointmentId)
    yield put(fetchAppointmentSuccess(appointment))
  } catch (error) {
    yield put(fetchAppointmentFailure(error as ApiError))
  }
}

export function* sagaCreateWaitlistAppointment({
  appointment,
  isEmbedded,
}: ReturnType<typeof createWaitlistAppointment>) {
  try {
    const newAppointment: Appointment = yield extendAppointment(appointment)
    const result = yield* requestAPI(
      API.createWaitlistAppointment,
      newAppointment,
      isEmbedded,
    )
    yield put(createWaitlistAppointmentSuccess(result))
  } catch (error) {
    yield put(createWaitlistAppointmentFailure(error as ApiError))
  }
}

export function* sagaCheckInForAppointment({
  appointmentId,
  arrived,
}: ReturnType<typeof checkInForAppointment>) {
  try {
    const result = yield* requestAPI(
      API.checkInForAppointment,
      appointmentId,
      arrived,
    )
    yield put(checkInForAppointmentSuccess(result))
  } catch (error) {
    yield put(checkInForAppointmentFailure(error as ApiError))
  }
}

export function* sagaFetchWaitlist() {
  try {
    const waitlist = yield* requestAPI(API.fetchWaitlist)
    yield put(fetchWaitlistSuccess(waitlist))
  } catch (error) {
    yield put(fetchWaitlistFailure(error as ApiError))
  }
}

export function* sagaUpdateAppointmentState({
  appointmentId,
  stateId,
}: ReturnType<typeof updateAppointmentState>) {
  try {
    const appointment = yield* requestAPI(
      API.updateAppointmentState,
      appointmentId,
      stateId,
    )
    yield put(updateAppointmentStateSuccess(appointment))
  } catch (error) {
    yield put(updateAppointmentStateFailure(error as ApiError))
  }
}

export function* sendAppointmentConfirmationEmail({
  appointmentId,
  message,
}: ReturnType<typeof sendNewAppointmentConfirmationEmail>) {
  try {
    yield* requestAPI(
      API.sendAppointmentConfirmationEmail,
      appointmentId,
      message,
    )
    yield put(sendNewAppointmentConfirmationEmailSuccess())
  } catch (error) {
    yield put(sendNewAppointmentConfirmationEmailFailure(error as ApiError))
  }
}

export function* sageSendZoomLinkMessage({
  appointmentId,
  message,
}: ReturnType<typeof sendZoomLinkMessage>) {
  try {
    yield* requestAPI(API.sendZoomLinkMessage, appointmentId, message)
    yield put(sendSendZoomLinkMessageSuccess())
  } catch (error) {
    yield put(sendSendZoomLinkMessageFailure(error as ApiError))
  }
}

export function* sagaFetchAppointmentIcsUrl({
  appointmentId,
}: ReturnType<typeof fetchAppointmentIcsUrl>) {
  try {
    const url = yield* requestAPI(API.fetchAppointmentIcsUrl, appointmentId)
    yield put(fetchAppointmentIcsUrlSuccess(url))
  } catch (error) {
    yield put(fetchAppointmentIcsUrlFailure(error as ApiError))
  }
}

function* watchFetchAvailableSlots() {
  yield takeLatest(FETCH_AVAILABLE_SLOTS, sagaFetchAvailableSlots)
}

function* watchFetchAvailableStuff() {
  yield takeLatest(FETCH_AVAILABLE_STUFF, sagaFetchAvailableStuff)
}

function* watchCreateAppointment() {
  yield takeLatest(CREATE_APPOINTMENT, sagaCreateAppointment)
}

function* watchEditAppointment() {
  yield takeLatest(EDIT_APPOINTMENT, sagaEditAppointment)
}

function* watchFetchAppointment() {
  yield takeLatest(FETCH_APPOINTMENT, sagaFetchAppointment)
}

function* watchCreateWaitlistAppointment() {
  yield takeLatest(CREATE_WAITLIST_APPOINTMENT, sagaCreateWaitlistAppointment)
}

function* watchCheckInForAppointment() {
  yield takeLatest(CHECK_IN_FOR_APPOINTMENT, sagaCheckInForAppointment)
}

function* watchFetchWaitlist() {
  yield takeLatest(FETCH_WAITLIST, sagaFetchWaitlist)
}

function* watchFetchAppointmentState() {
  yield takeLatest(UPDATE_APPOINTMENT_STATE, sagaUpdateAppointmentState)
}

function* watchSendAppointmentConfirmationEmail() {
  yield takeLatest(
    SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL,
    sendAppointmentConfirmationEmail,
  )
}

function* watchSendZoomLinkMessage() {
  yield takeLatest(SEND_ZOOM_LINK_MESSAGE, sageSendZoomLinkMessage)
}

function* watchFetchAppointmentIcsUrl() {
  yield takeLatest(FETCH_APPOINTMENT_ICS_URL, sagaFetchAppointmentIcsUrl)
}

export function* schedulesSaga() {
  yield all([
    watchFetchAvailableSlots(),
    watchFetchAvailableStuff(),
    watchCreateAppointment(),
    watchEditAppointment(),
    watchFetchAppointment(),
    watchCreateWaitlistAppointment(),
    watchCheckInForAppointment(),
    watchFetchWaitlist(),
    watchFetchAppointmentState(),
    watchSendAppointmentConfirmationEmail(),
    watchSendZoomLinkMessage(),
    watchFetchAppointmentIcsUrl(),
  ])
}
