import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  PuiAlert,
  useBlocker,
  useIdle,
} from '@pbt/pbt-ui-components'
import { BackArrow } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentBusiness } from '../../store/duck/businesses'
import { setClientAlreadyExists } from '../../store/duck/clients'
import { clearLastError, setKioskDisabled } from '../../store/duck/errors'
import { useNavigateWithQueryString } from '../../utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      position: 'fixed',
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    arrow: {
      marginRight: theme.spacing(0.5),
    },
    text: {
      fontSize: '1.4rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    alertPaper: {
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        minWidth: 340,
      },
    },
    alertButton: {
      minWidth: 264,
      height: 56,
      borderRadius: 32,
      fontSize: '1.8rem',
      margin: theme.spacing(0, 1.5),
      [theme.breakpoints.down('sm')]: {
        minWidth: 160,
        margin: theme.spacing(0.5),
        borderRadius: 32,
        fontSize: '1.4rem',
        height: 'auto',
        whiteSpace: 'pre-wrap',
      },
    },
    alertMessage: {
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'HomeButton' },
)

const IDLE_TIMEOUT = 60 * 1000

const HomeButton = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isIdle = useIdle(IDLE_TIMEOUT)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [confirmLeaveAlertOpen, setConfirmLeaveAlertOpen] = useState(false)
  const [isIdleAlertOpen, setIsIdleAlertOpen] = useState(false)

  // if already on root page, no need to show this button
  const renderNothing = location.pathname === '/'
  const businessId = useSelector(getCurrentBusiness)

  useBlocker(({ retry }) => retry(), !renderNothing)

  useEffect(() => {
    if (isIdle && !renderNothing) {
      setIsIdleAlertOpen(isIdle)
    }
  }, [isIdle])

  if (renderNothing) {
    return null
  }

  const goToHomePage = () => {
    dispatch(clearLastError())
    dispatch(setKioskDisabled(false))
    dispatch(setClientAlreadyExists(false))
    setConfirmLeaveAlertOpen(false)
    setIsIdleAlertOpen(false)
    navigateWithQueryString({ url: '/' })
  }

  return (
    <Grid container item className={classes.root}>
      {!isMobile && (
        <>
          {businessId && (
            <Button onClick={() => setConfirmLeaveAlertOpen(true)}>
              <BackArrow className={classes.arrow} />
              <Typography className={classes.text} variant="body2">
                {t('Common:HOME')}
              </Typography>
            </Button>
          )}
          <PuiAlert
            cancelButtonText={t('Common:CANCEL_ACTION')}
            classes={{
              paper: classes.alertPaper,
              button: classes.alertButton,
              message: classes.alertMessage,
            }}
            iconType={AlertIconType.WARN}
            message={t('Common:THIS_WILL_RETURN_YOU_TO_HOME')}
            okButtonText={t('Common:CONTINUE_TO_HOME_SCREEN')}
            open={confirmLeaveAlertOpen}
            onCancel={() => setConfirmLeaveAlertOpen(false)}
            onClose={() => setConfirmLeaveAlertOpen(false)}
            onOk={goToHomePage}
          />
        </>
      )}
      <PuiAlert
        cancelButtonText={t('Common:EXIT_TO_HOME_SCREEN')}
        classes={{
          paper: classes.alertPaper,
          button: classes.alertButton,
          message: classes.alertMessage,
        }}
        iconType={AlertIconType.WARN}
        message={t('Common:ARE_YOU_STILL_THERE')}
        okButtonText={t('Common:CONTINUE_I_AM_HERE')}
        open={isIdleAlertOpen}
        onCancel={goToHomePage}
        onClose={() => setIsIdleAlertOpen(false)}
        onOk={() => setIsIdleAlertOpen(false)}
      />
    </Grid>
  )
}

export default HomeButton
