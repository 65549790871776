import AppointmentConfirmationCancelledScreen from './AppointmentConfirmationCancelledScreen'
import AppointmentConfirmationConfirmedScreen from './AppointmentConfirmationConfirmedScreen'
import AppointmentConfirmationErrorScreen from './AppointmentConfirmationErrorScreen'
import AppointmentConfirmationRescheduleScreen from './AppointmentConfirmationRescheduleScreen'
import AppointmentConfirmationWelcomeScreen from './AppointmentConfirmationWelcomeScreen'

/* root path: /appointment-confirmation */

export default [
  { Component: AppointmentConfirmationConfirmedScreen, path: 'confirmed' },
  { Component: AppointmentConfirmationCancelledScreen, path: 'cancelled' },
  { Component: AppointmentConfirmationRescheduleScreen, path: 'reschedule' },
  {
    Component: AppointmentConfirmationErrorScreen,
    path: 'error',
    isRoot: true,
  },
  { Component: AppointmentConfirmationWelcomeScreen, path: '/*', isRoot: true },
]
