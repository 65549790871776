import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ClassesType,
  DateFormat,
  ImageScalingUtils,
  moment,
  PatientAvatar,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getFeatureToggle, getSpecies } from '../../../store/duck/constants'
import { Appointment } from '../../../types/entities/appointments'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      padding: theme.spacing(1, 1, 1, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
      },
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      marginRight: theme.spacing(2),
    },
    avatarBigScreen: {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    button: {
      fontSize: '1.4rem',
      height: 48,
      width: 'fit-content',
      minWidth: 120,
    },
  }),
  { name: 'WaitListItem' },
)

const getFirstLetter = (name?: string) => name?.[0]?.toUpperCase() || ''

interface WaitListItemProps {
  readonly appointment: Appointment
  readonly bigScreen?: boolean
  classes: ClassesType<typeof useStyles>
  onCheckIn?: () => void
}

const WaitListItem = ({
  bigScreen,
  appointment,
  onCheckIn,
  classes: classesProp,
}: WaitListItemProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const Species = useSelector(getSpecies)

  const {
    patient,
    client,
    vet,
    waitTime,
    scheduledStartDatetime,
    isWaitlist,
    isCheckedIn,
    isArrived,
  } = appointment

  const { amount, unit } = waitTime || {}
  const { estimatedWaitTimeInKioskEnabled } =
    useSelector(getCurrentBusiness) || {}
  const isEstimatedWaitTimeInKioskFeatureToggleEnabled = useSelector(
    getFeatureToggle(Features.WAIT_LIST_ESTIMATED_TIME),
  )
  const showEstimatedWaitTime =
    !isEstimatedWaitTimeInKioskFeatureToggleEnabled ||
    estimatedWaitTimeInKioskEnabled

  const time = moment(scheduledStartDatetime).format(
    DateFormat.FULL_TIME_WITH_MERIDIAN_AND_SPACE,
  )

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient.photo,
    patient.photoThumbnail,
    AVATAR_SIZE,
  )

  const patientAvatar = (
    <PatientAvatar
      small
      animal={Utils.getConstantName(patient?.species, Species)}
      classes={{
        root: classNames(classes.avatar, {
          [classes.avatarBigScreen]: bigScreen,
        }),
      }}
      src={avatarSrc}
    />
  )

  const getAppointmentStatus = () => {
    if (isArrived) {
      return (
        <Text strong px={5} variant="lowAccent2">
          {t('Common:ARRIVED')}
        </Text>
      )
    }
    if (isCheckedIn && !isArrived) {
      return (
        <KioskButtonWithLoader
          className={classes.button}
          onClick={() => onCheckIn && onCheckIn()}
        >
          {`${t('Common:ARRIVED_QUESTION')}`}
        </KioskButtonWithLoader>
      )
    }
    return (
      <KioskButtonWithLoader
        className={classes.button}
        onClick={() => onCheckIn && onCheckIn()}
      >
        {t('Common:CHECK_IN_ACTION')}
      </KioskButtonWithLoader>
    )
  }

  return (
    <Grid container alignItems="center" className={classes.root} wrap="nowrap">
      <Grid container item xs>
        {!bigScreen && patientAvatar}
        <Grid item xs minWidth={100}>
          <Text strong variant="subheading">
            {`${isWaitlist ? t('Common:WAITLIST') : time} | ${
              patient.name
            } ${getFirstLetter(client.lastName)}.`}
          </Text>
        </Grid>
        {(isWaitlist || vet) && (
          <Grid item xs minWidth={130}>
            <Text px={0} variant="body">
              {isWaitlist
                ? showEstimatedWaitTime
                  ? `${t('Common:ESTIMATED_WAIT_TIME')}: ${amount} ${(
                      unit || ''
                    ).toLowerCase()}`
                  : ''
                : `${t('Common:WITH').toLowerCase()} ${vet?.firstName} ${
                    vet?.lastName
                  }`}
            </Text>
          </Grid>
        )}
      </Grid>
      {!bigScreen && onCheckIn && getAppointmentStatus()}
      {bigScreen && patientAvatar}
    </Grid>
  )
}

WaitListItem.propTypes = {
  classes: PropTypes.object,
  onCheckIn: PropTypes.func,
}

export default WaitListItem
