import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import { useNavigateWithQueryString } from '../../../utils'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(5, 3),
    },
    body: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    successIcon: {
      fontSize: '4rem',
      color: theme.colors.alertSuccess,
    },
    boopScreens: {
      maxWidth: 455,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    caption: {
      fontSize: '1.4rem',
      marginBottom: theme.spacing(1.5),
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    button: {
      marginTop: theme.spacing(3),
      width: 175,
    },
    shrinkedButton: {
      width: 135,
    },
    emailInput: {
      maxWidth: 285,
      marginTop: theme.spacing(3),
    },
  }),
  { name: 'MembershipSuccessScreen' },
)

const MembershipSuccessScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const handleFinish = () => {
    navigateWithQueryString({ url: '/' })
  }

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
    >
      <SuccessAlert className={classes.successIcon} />
      <Text variant="hero">{t('Common:YOU_ARE_ALL_SIGNED_UP')}!</Text>
      <Grid item>
        <KioskButtonWithLoader
          className={classNames(classes.button, classes.shrinkedButton)}
          color="secondary"
          onClick={handleFinish}
        >
          {t('Common:FINISH_ACTION')}
        </KioskButtonWithLoader>
      </Grid>
    </KioskScreen>
  )
}

export default MembershipSuccessScreen
