import React from 'react'
import { Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Utils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      color: theme.colors.link,
    },
  }),
  { name: 'BusinessLink' },
)

const BusinessLink = ({ business }) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.link}
      color="secondary"
      href={Utils.toExternalUrl(business.website)}
      rel="noopener noreferrer"
      target="_blank"
      underline="always"
    >
      {Utils.getUrlHost(business.website)}
    </Link>
  )
}

export default BusinessLink
