import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { Utils } from '@pbt/pbt-ui-components'

import { getAppointmentTypes } from '../../store/duck/constants'
import { getIsCheckInFlow } from '../../store/duck/flow'
import { updateCurrentPatient } from '../../store/duck/patients'
import {
  getCurrentAppointment,
  updateCurrentAppointment,
} from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import PatientsList from '../patient/PatientsList'
import KioskScreen from './KioskScreen'

const SelectPatientScreen = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const appointment = useSelector(getCurrentAppointment) || {}
  const AppointmentTypes = useSelector(getAppointmentTypes)
  const isCheckIn = useSelector(getIsCheckInFlow)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [selectedPatient, setSelectedPatient] = useState()

  const BoardingType = Utils.findConstantIdByName('Boarding', AppointmentTypes)

  const isBoarding = appointment.type?.id === BoardingType

  const proceed = () => {
    const newAppointment = {
      ...appointment,
      patient: selectedPatient.id,
    }
    dispatch(updateCurrentAppointment(newAppointment))
    dispatch(updateCurrentPatient(selectedPatient))

    if (selectedPatient.needsUpdate) {
      navigateWithQueryString({ url: '/patient-details' })
    } else if (isCheckIn) {
      if (isBoarding) {
        navigateWithQueryString({ url: '/patient-diet-and-allergies' })
      } else {
        navigateWithQueryString({ url: '/questions' })
      }
    } else if (appointment.type) {
      navigateWithQueryString({ url: '/primary-complaint' })
    } else {
      navigateWithQueryString({ url: '/appointment-type' })
    }
  }

  return (
    <KioskScreen
      justifyContent={isMobile ? 'flex-start' : 'center'}
      proceedButtonDisabled={!selectedPatient}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      title={t('Common:WHO_WILL_WE_BE_SEEING')}
      onProceed={proceed}
    >
      <PatientsList
        selectedPatient={selectedPatient}
        onPatientSelected={setSelectedPatient}
      />
    </KioskScreen>
  )
}

export default SelectPatientScreen
