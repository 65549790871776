import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Communications,
  CommunicationsUtils,
  DateFormat,
  moment,
} from '@pbt/pbt-ui-components'

import i18nKiosk from '../../locales/i18n'
import { getCurrentClient } from '../../store/duck/clients'
import {
  getCurrentAppointment,
  getIsSendingConfirmationEmail,
  getIsSendingZoomLinkMessage,
  sendNewAppointmentConfirmationEmail,
  sendZoomLinkMessage,
} from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import useDisplayMessageSentSuccess from '../../utils/useDisplayMessageSentSuccess'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
import ToInput from '../inputs/communications/ToInput'
import TransportSelect from '../inputs/communications/TransportSelect'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(9, 4, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    text: {
      fontSize: '1.8rem',
    },
    appointmentDetails: {
      padding: theme.spacing(1, 2, 0, 0),
    },
    button: {
      width: 223,
      margin: theme.spacing(2, 2, 0, 0),
    },
    toInput: {
      margin: theme.spacing(2, 0, 1),
      maxWidth: '50%',
    },
    withDoctorContainer: {
      marginTop: theme.spacing(1.5),
    },
  }),
  { name: 'AppointmentMessageScreen' },
)

export const AppointmentMessageType = {
  CONFIRMATION: 'CONFIRMATION',
  ZOOM_LINK: 'ZOOM_LINK',
}

const APPOINTMENT_CONFIRMATION_MESSAGE_CONFIG = {
  sendMessage: sendNewAppointmentConfirmationEmail,
  getIsSendingMessage: getIsSendingConfirmationEmail,
  title: i18nKiosk.t('Common:YOUR_APPOINTMENT_IS_SCHEDULED'),
  sendText: i18nKiosk.t('Common:SEND_CONFIRMATION'),
}

const appointmentMessageConfigByMessageType = {
  [AppointmentMessageType.CONFIRMATION]:
    APPOINTMENT_CONFIRMATION_MESSAGE_CONFIG,
  [AppointmentMessageType.ZOOM_LINK]: {
    sendMessage: sendZoomLinkMessage,
    getIsSendingMessage: getIsSendingZoomLinkMessage,
    title: i18nKiosk.t('Common:YOU_ARE_ALL_CHECKED_IN'),
    sendText: i18nKiosk.t('Common:SEND_ZOOM_LINK'),
  },
}

const AppointmentMessageScreen = ({
  appointmentMessageType = AppointmentMessageType.CONFIRMATION,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const toInputRef = useRef()
  const { t } = useTranslation('Common')

  const flowConfig =
    appointmentMessageConfigByMessageType[appointmentMessageType] ||
    APPOINTMENT_CONFIRMATION_MESSAGE_CONFIG

  const appointment = useSelector(getCurrentAppointment)
  const isSendingMessage = useSelector(flowConfig.getIsSendingMessage)
  const currentClient = useSelector(getCurrentClient)

  const [transport, setTransport] = useState(
    Communications.MessageTransport.EMAIL,
  )

  const {
    scheduledStartDatetime,
    personRoles = [],
    personResponsibilities = [],
    vet,
    client = {},
    patient = {},
  } = appointment || {}

  const appointmentStart = moment(scheduledStartDatetime)

  const veterinarian =
    vet || personResponsibilities?.[0]?.person || personRoles[0]?.person

  const finish = () => {
    navigateWithQueryString({ url: '/' })
  }

  const showSuccessAfterMessageSent = useDisplayMessageSentSuccess(
    flowConfig.getIsSendingMessage,
  )

  const sendMessage = () => {
    if (toInputRef.current.validate()) {
      const to = toInputRef.current.value
      const recipients = CommunicationsUtils.formRecipients(
        { client, patient },
        to,
        transport,
      )
      const message = {
        recipients,
        transport,
      }
      showSuccessAfterMessageSent()
      dispatch(flowConfig.sendMessage(appointment.id, message))
    }
  }

  return (
    <KioskScreen justifyContent="flex-start" title={flowConfig.title}>
      <Grid container item className={classes.root} justifyContent="center">
        <Grid
          container
          item
          alignItems="flex-start"
          className={classes.appointmentDetails}
          direction="column"
        >
          <Grid item xs>
            <Typography className={classes.text} variant="h5">
              {patient.name || ''} {client.lastName || ''}
            </Typography>
            <Typography className={classes.text} variant="h5">
              {appointmentStart.format(
                DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_AT_FULL_TIME_WITH_MERIDIAN,
              )}
            </Typography>
          </Grid>
          {veterinarian && (
            <Grid item xs className={classes.withDoctorContainer}>
              <Typography variant="body2">
                {t('Common:WITH_PERSON', {
                  personName: `${veterinarian.firstName} ${veterinarian.lastName}`,
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item>
          <TransportSelect
            communicationSmsOptIn={
              currentClient?.globalClientPreferences?.communicationSmsOptIn
            }
            setTransport={setTransport}
            title={t('Common:SEND_AS')}
            transport={transport}
          />
        </Grid>
        <Grid container item direction="column">
          <ToInput
            className={classes.toInput}
            initialEmail={client.email}
            initialMobilePhone={client.mobilePhone}
            ref={toInputRef}
            transport={transport}
          />
        </Grid>
        <Grid container item>
          <KioskButtonWithLoader
            className={classes.button}
            loading={isSendingMessage}
            onClick={sendMessage}
          >
            {flowConfig.sendText}
          </KioskButtonWithLoader>
          <KioskButtonWithLoader
            className={classes.button}
            color="secondary"
            disabled={isSendingMessage}
            onClick={finish}
          >
            {t('Common:SKIP_AND_FINISH')}
          </KioskButtonWithLoader>
        </Grid>
      </Grid>
    </KioskScreen>
  )
}

export default AppointmentMessageScreen
