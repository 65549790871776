import React from 'react'
import {
  ConsentForm,
  DocumentElementType,
  PaginatedDocumentInstance,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
import KioskScreen from '../KioskScreen'

// Mock data just to render the skeleton component properly
const MOCK_FORM: PaginatedDocumentInstance = {
  id: 1,
  creatorId: 2000,
  creationDate: '2021-08-01T00:00:00',
  modifierId: 2000,
  modificationDate: '2021-08-01T00:00:00',
  deleted: false,
  businessId: 2,
  categoryId: 1,
  name: 'MEDICAL TREATMENT CONSENT FORM',
  required: true,
  includeInKiosk: true,
  active: true,
  expirationDateOffset: null,
  appointmentTypeIds: null,
  speciesIds: null,
  displayLocationIds: null,
  pages: [
    [
      {
        id: 11,
        type: DocumentElementType.TEXT,
        value:
          '<p>\n    I understand that the purpose of this form is to provide me with information regarding certain\n    veterinary medical services and treatment to be performed by Chewy Vet Care\n  CVC Vet Care Plantation, FL.\n</p>\n',
      },
    ],
    [
      {
        id: 12,
        type: DocumentElementType.DATE,
        label: 'Drop off date',
        inputValue: '2024-01-01',
      },
      {
        id: 13,
        type: DocumentElementType.DATE_TIME,
        label: 'Drop off date and time',
        inputValue: '2024-01-01T00:00:00',
      },
    ],
  ],
}

/**
 * This screen is not currently accessible from any flow
 * For now it serves to display mock data while the BE is being implemented
 */
export function ConsentFormScreen() {
  const navigateWithQueryString = useNavigateWithQueryString()

  const handleSubmit = () => {
    navigateWithQueryString({ url: '/consent-forms-complete' })
  }

  return (
    <KioskScreen>
      <ConsentForm
        form={MOCK_FORM}
        onContinue={() => {}}
        onSubmit={handleSubmit}
      />
    </KioskScreen>
  )
}
