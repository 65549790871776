import { combineReducers } from 'redux'

import { appointmentConfirmationReducer as appointmentConfirmation } from '../duck/appointmentConfirmation'
import { appointmentTypesReducer as appointmentTypes } from '../duck/appointmentTypes'
import { braintreeReducer as braintree } from '../duck/braintree'
import { businessesReducer as businesses } from '../duck/businesses'
import { clientsReducer as clients } from '../duck/clients'
import { communicationsReducer as communications } from '../duck/communications'
import { constantsReducer as constants } from '../duck/constants'
import { documentsReducer as documents } from '../duck/documents'
import { errorsReducer as errors } from '../duck/errors'
import { financeReducer as finance } from '../duck/finance'
import { flowReducer as flow } from '../duck/flow'
import { npsReducer as nps } from '../duck/nps'
import { patientsReducer as patients } from '../duck/patients'
import { paymentsReducer as payments } from '../duck/payments'
import { questionsReducer as questions } from '../duck/questions'
import { schedulesReducer as schedules } from '../duck/schedules'
import { wellnessPlansReducer as wellnessPlans } from '../duck/wellnessPlans'

export default combineReducers({
  appointmentConfirmation,
  appointmentTypes,
  businesses,
  clients,
  communications,
  constants,
  documents,
  errors,
  finance,
  flow,
  nps,
  patients,
  payments,
  questions,
  schedules,
  wellnessPlans,
  braintree,
})
