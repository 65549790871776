import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PhoneUtils, useTimeout } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
import { getIsCheckInFlow } from '../../store/duck/flow'
import { useNavigateWithQueryString } from '../../utils'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    item: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(5),
      },
    },
    text: {
      whiteSpace: 'pre-wrap',
    },
    link: {
      cursor: 'pointer',
      fontSize: '2.6rem',
      textDecoration: 'underline',
      color: theme.colors.title,
    },
  }),
  { name: 'ClientNotFoundScreen' },
)

const ClientNotFoundScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const business = useSelector(getCurrentBusiness) || {}
  const navigateWithQueryString = useNavigateWithQueryString()
  const isCheckIn = useSelector(getIsCheckInFlow)

  const navigate = () => {
    navigateWithQueryString({ url: '/' })
  }

  useTimeout(navigate, 50 * 1000)

  return (
    <KioskScreen>
      <Grid item className={classes.item}>
        <Typography align="center" className={classes.text} variant="h5">
          {`${t('Common:SORRY_WE_CANNOT_FIND_YOUR_ACCOUNT')}\n`}
          {isCheckIn
            ? t(
                'Common:PLEASE_CHECK_WITH_RECEPTION_THEY_CAN_HELP_PULL_UP_YOUR_DETAILS',
              )
            : t('Common:PLEASE_GIVE_US_CALL_WE_WILL_HELP_YOU_JOIN_WAITLIST')}
        </Typography>
      </Grid>
      {!isCheckIn && (
        <Grid item className={classes.item}>
          <Typography align="center" variant="h5">
            {PhoneUtils.formatPhoneNumber(business.phone)}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.item}>
        <Typography className={classes.link} onClick={navigate}>
          {t('Common:START_OVER')}
        </Typography>
      </Grid>
    </KioskScreen>
  )
}

export default ClientNotFoundScreen
