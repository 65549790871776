import React from 'react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import useBoopEnabled from '../../../utils/useBoopEnabled'
import WellnessPlanBenefitRow from './WellnessPlanBenefitRow'
import WellnessPlanPriceLabel from './WellnessPlanPriceLabel'
import { getBenefitIsAccessToBoop } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
      [theme.breakpoints.down('sm')]: {
        '&:last-of-type': {
          borderBottom: theme.constants.tableBorder,
        },
      },
    },
    planNameContainer: {
      flex: 1,
      padding: theme.spacing(2),
      borderRight: theme.constants.tableBorder,
    },
    planNameContainerDense: {
      padding: theme.spacing(0.75, 0.75, 0.75, 2),
      backgroundColor: theme.colors.tableEvenItem,
    },
    priceText: {
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      marginTop: theme.spacing(1),
    },
    priceTextDense: {
      textAlign: 'right',
      color: theme.colors.tabLabel,
      marginTop: 0,
    },
    planNameText: {
      minWidth: 60,
      marginRight: theme.spacing(2),
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    benefitRow: {
      flex: 1,
    },
    benefitRowDense: {
      padding: theme.spacing(0.5, 2),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableBackground,
      },
    },
    rowsContainer: {
      flex: 1,
    },
  }),
  { name: 'WellnessPlanReviewTablePlanRow' },
)

const WellnessPlanReviewTablePlanRow = ({
  dense,
  plan,
  showPrice,
  wellnessPlanVersion,
}) => {
  const classes = useStyles()
  const isBoopEnabled = useBoopEnabled()

  const benefits = plan.benefits || []
  const filteredBenefits = isBoopEnabled
    ? benefits
    : benefits.filter((benefit) => !getBenefitIsAccessToBoop(benefit))

  return (
    <Grid
      container
      item
      className={classes.root}
      direction={dense ? 'column' : 'row'}
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems={dense ? 'center' : 'stretch'}
        className={classNames(classes.planNameContainer, {
          [classes.planNameContainerDense]: dense,
        })}
        direction={dense ? 'row' : 'column'}
        justifyContent={dense ? 'space-between' : 'flex-start'}
        wrap="nowrap"
        xs={dense ? true : 4}
      >
        <Typography className={classes.planNameText}>{plan.name}</Typography>
        {showPrice && (
          <WellnessPlanPriceLabel
            className={classNames(classes.priceText, {
              [classes.priceTextDense]: dense,
            })}
            plan={plan}
            wellnessPlansVersion={wellnessPlanVersion}
          />
        )}
      </Grid>
      <Grid
        container
        item
        xs
        className={classes.rowsContainer}
        direction="column"
      >
        {filteredBenefits.map((benefit) => (
          <WellnessPlanBenefitRow
            benefit={benefit}
            className={classNames(classes.benefitRow, {
              [classes.benefitRowDense]: dense,
            })}
            key={`${benefit.id}-${benefit.globalBenefitId}`}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default WellnessPlanReviewTablePlanRow
