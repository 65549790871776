import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, LinkButton, moment, Utils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  fetchAppointmentIcsUrl,
  getCurrentAppointment,
  getIsFetchingAppointmentIcs,
  getLastAppointmentIcsFileUrl,
} from '../../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskLinkButton from '../../buttons/KioskLinkButton'
import BottomSheet from '../common/BottomSheet'
import ConferencingInfo from './ConferencingInfo'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      paddingBottom: theme.spacing(1),
    },
    containerWithoutBorder: {
      padding: theme.spacing(0, 2, 0),
    },
    containerWithBorder: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      margin: theme.spacing(3, 4, 0),
      padding: theme.spacing(2),
    },
    actionsBlockMobile: {
      marginTop: theme.spacing(12),
    },
    actionsBlock: {
      marginTop: theme.spacing(3),
    },
    conferencingInfoBlock: {
      marginTop: theme.spacing(3),
    },
    finishButtonContainer: {
      padding: theme.spacing(7, 0),
    },
    button: {
      width: 254,
    },
    emailZoomLinkButton: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    titleMobile: {
      fontSize: '1.6rem',
    },
    meetingInfoButton: {
      fontSize: '1.4rem',
    },
    conferencingInfoBottomSheet: {
      alignItems: 'flex-start',
      margin: theme.spacing(0.5, 2, 0),
    },
    conferencingInfoBottomSheetText: {
      textAlign: 'start',
    },
  }),
  { name: 'CheckInWithConferencing' },
)

const CheckInWithConferencing = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const appointment = useSelector(getCurrentAppointment)
  const business = useSelector(getCurrentBusiness) || {}
  const lastAppointmentIcsFileUrl = useSelector(getLastAppointmentIcsFileUrl)
  const isFetchingAppointmentIcs = useSelector(getIsFetchingAppointmentIcs)

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const {
    scheduledStartDatetime,
    personRoles = [],
    personResponsibilities = [],
    vet,
    meetingLink,
    dialIn,
  } = appointment || {}

  const appointmentStart = moment(scheduledStartDatetime)

  const veterinarian =
    vet || personResponsibilities?.[0]?.person || personRoles[0]?.person

  const date = DateUtils.formatDate(appointmentStart)
  const time = DateUtils.formatTime(appointmentStart)

  const downloadAppointmentIcs = () => {
    if (lastAppointmentIcsFileUrl) {
      Utils.downloadLink({ href: lastAppointmentIcsFileUrl })
    }
  }

  const downloadAppointmentIcsAfterUrlFetch = useCallbackWhenLoaded(
    downloadAppointmentIcs,
    getIsFetchingAppointmentIcs,
  )

  const handleAddToCalendar = () => {
    downloadAppointmentIcsAfterUrlFetch()
    dispatch(fetchAppointmentIcsUrl(appointment.id))
  }

  const handleLaunchZoomMeeting = () => {
    if (meetingLink) {
      Utils.openInNewTab({ href: meetingLink })
    }
  }

  const handleEmailZoomInformation = () => {
    navigateWithQueryString({ url: '/appointment-message/zoom-link' })
  }

  const handleFinish = () => {
    navigateWithQueryString({ url: '/' })
  }

  const handleMeetingDialInAndLinkClick = () => {
    setBottomSheetOpen(true)
  }

  const moreThan30MinutesBeforeAppointment =
    appointmentStart.diff(moment(), 'minutes') > 30
  const isAppointmentToday = moment().isSame(appointmentStart, 'day')

  const title = moreThan30MinutesBeforeAppointment
    ? isAppointmentToday
      ? t(
          'Common:YOUR_APPOINTMENT_IS_SCHEDULED_FOR_TIME_PLEASE_JOIN_ZOOM_MEETING_AT_THAT_TIME',
          { time },
        )
      : t(
          'Common:YOUR_APPOINTMENT_IS_SCHEDULED_FOR_DATE_AT_TIME_PLEASE_JOIN_ZOOM_MEETING_AT_THAT_TIME',
          { date, time },
        )
    : veterinarian
    ? t('Common:PLEASE_JOIN_PERSON_ON_YOUR_ZOOM_MEETING', {
        personName: Utils.getPersonString(veterinarian),
      })
    : t('Common:PLEASE_JOIN_ZOOM_MEETING_WITH_BUSINESS', {
        businessName: business.name,
      })

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid
        container
        item
        alignContent="flex-start"
        alignItems="flex-start"
        className={classNames({
          [classes.containerWithBorder]: !isMobile,
          [classes.containerWithoutBorder]: isMobile,
        })}
        justifyContent="center"
      >
        <Grid item>
          <Typography
            className={classNames({
              [classes.title]: !isMobile,
              [classes.titleMobile]: isMobile,
            })}
          >
            {title}
          </Typography>
        </Grid>
        {!isMobile && (
          <ConferencingInfo
            classes={{ root: classes.conferencingInfoBlock }}
            dialIn={dialIn}
            meetingLink={meetingLink}
            title={t('Common:DIAL_IN_INFO')}
          />
        )}
        <Grid
          container
          item
          alignItems="center"
          className={classNames({
            [classes.actionsBlockMobile]: isMobile,
            [classes.actionsBlock]: !isMobile,
          })}
          direction="column"
        >
          {moreThan30MinutesBeforeAppointment && (
            <KioskButtonWithLoader
              className={classes.button}
              loading={isFetchingAppointmentIcs}
              onClick={handleAddToCalendar}
            >
              {t('Common:ADD_TO_CALENDAR')}
            </KioskButtonWithLoader>
          )}
          {!moreThan30MinutesBeforeAppointment && (
            <KioskButtonWithLoader
              className={classes.button}
              onClick={handleLaunchZoomMeeting}
            >
              {t('Common:LAUNCH_ZOOM_MEETING')}
            </KioskButtonWithLoader>
          )}
          <KioskLinkButton
            className={classNames(classes.emailZoomLinkButton, classes.button)}
            onClick={handleEmailZoomInformation}
          >
            {t('Common:EMAIL_ZOOM_INFORMATION')}
          </KioskLinkButton>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid container alignItems="flex-end" justifyContent="center">
          <LinkButton
            classes={{ button: classes.meetingInfoButton }}
            onClick={handleMeetingDialInAndLinkClick}
          >
            {t('Common:CLICK_FOR_MEETING_DIAL_IN_AND_LINK')}
          </LinkButton>
        </Grid>
      )}
      {!isMobile && (
        <Grid item className={classes.finishButtonContainer}>
          <KioskButtonWithLoader
            className={classes.button}
            color="secondary"
            onClick={handleFinish}
          >
            {t('Common:FINISH_ACTION')}
          </KioskButtonWithLoader>
        </Grid>
      )}
      <BottomSheet open={bottomSheetOpen} onChange={setBottomSheetOpen}>
        <ConferencingInfo
          classes={{
            root: classes.conferencingInfoBottomSheet,
            text: classes.conferencingInfoBottomSheetText,
          }}
          dialIn={dialIn}
          meetingLink={meetingLink}
          title={t('Common:MEETING_DIAL_IN_AND_LINK')}
        />
      </BottomSheet>
    </Grid>
  )
}

export default CheckInWithConferencing
