import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { prop } from 'ramda'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../../store/duck/businesses'
import {
  getAppointmentTypes,
  getWellnessPlanLimitType,
} from '../../../../store/duck/constants'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      padding: theme.spacing(1.5, 1),
    },
    borderCell: {
      borderRight: theme.constants.tableBorder,
    },
    limitsCell: {
      minWidth: 125,
    },
    priceText: {
      fontSize: '1.4rem',
    },
    descriptionCell: {
      padding: theme.spacing(0, 1, 1),
    },
    limitsText: {
      color: prop('buttonsColor'),
    },
    benefitNameText: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    benefitContainer: {
      minHeight: 'fit-content',
      flex: 1,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    coverRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'PlanDetailsBenefitRow' },
)

const PlanDetailsBenefitRow = ({ benefit }) => {
  const WellnessPlanLimitType = useSelector(getWellnessPlanLimitType)
  const AppointmentTypes = useSelector(getAppointmentTypes)
  const business = useSelector(getCurrentBusiness)

  const { customColorsEnabled, buttonsColor } = business || {}

  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const LimitedTypeId = Utils.findConstantIdByName(
    'Limited',
    WellnessPlanLimitType,
  )

  const isLimited = benefit.limitTypeId === LimitedTypeId

  return (
    <Grid
      container
      item
      xs
      className={classes.benefitContainer}
      key={benefit.id}
      wrap="nowrap"
    >
      <Grid container item className={classes.borderCell} xs={8}>
        <Grid container item wrap="nowrap">
          <Grid item xs className={classes.tableCell}>
            <Typography className={classes.benefitNameText}>
              {benefit.name}
            </Typography>
          </Grid>
          <Grid
            item
            className={classNames(classes.tableCell, classes.limitsCell)}
          >
            {benefit.limitTypeId && (
              <Typography
                className={classNames(classes.priceText, classes.limitsText)}
                variant="body2"
              >
                {LanguageUtils.getConstantTranslatedName(
                  benefit.limitTypeId,
                  WellnessPlanLimitType,
                )}
                {isLimited ? ` ${benefit.limit}/year` : ''}
              </Typography>
            )}
          </Grid>
        </Grid>
        {benefit.description && (
          <Grid
            item
            className={classNames(classes.tableCell, classes.descriptionCell)}
            xs={12}
          >
            <Typography>{benefit.description}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs direction="column" wrap="nowrap">
        {benefit.appointmentTypeIds?.map((appointmentTypeId) => {
          const name = Utils.findById(appointmentTypeId, AppointmentTypes)?.name

          return (
            <Grid item className={classes.coverRow} key={appointmentTypeId}>
              <Typography className={classes.priceText} variant="body2">
                {name}
              </Typography>
            </Grid>
          )
        })}
        {benefit.cover?.map((cover) => (
          <Grid item className={classes.coverRow} key={cover.id}>
            <Typography className={classes.priceText} variant="body2">
              {cover.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default PlanDetailsBenefitRow
