import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, useInterval } from '@pbt/pbt-ui-components'

import Flows from '../../../constants/flows'
import { getCurrentBusiness } from '../../../store/duck/businesses'
import { updateCurrentClient } from '../../../store/duck/clients'
import { updateCurrentFlow } from '../../../store/duck/flow'
import { updateCurrentPatient } from '../../../store/duck/patients'
import {
  fetchWaitlist,
  getSchedulesIsLoading,
  getWaitlist,
  updateCurrentAppointment,
} from '../../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../../utils'
import JoinWaitlistButton from '../../buttons/JoinWaitlistButton'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskMembershipSignUpButton from '../../buttons/KioskMembershipSignUpButton'
import KioskScreen from '../KioskScreen'
import WaitListItem from '../wait-list/WaitListItem'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      flex: 1,
      margin: theme.spacing(4, 4, 0),
      width: `calc(100% - ${theme.spacing(8)})`,
      overflowY: 'auto',
      maxHeight: 'calc(var(--app-height) - 437px)',
      minHeight: 'calc(var(--app-height) - 437px)',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: `calc(100% - ${theme.spacing(2)})`,
        maxHeight: 'calc(var(--app-height) - 422px)',
        minHeight: 'calc(var(--app-height) - 422px)',
      },
    },
    overlayButtons: {
      zIndex: 1,
      marginTop: 1,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
    overlayButton: {
      margin: theme.spacing(1, 0.5),
      width: 270,
      fontSize: '1.5rem',
    },
  }),
  { name: 'InClinicWelcomeScreen' },
)

const InClinicWelcomeScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getSchedulesIsLoading)
  const waitlist = useSelector(getWaitlist) || []
  const business = useSelector(getCurrentBusiness)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    dispatch(fetchWaitlist())
  }, [])

  useInterval(() => {
    dispatch(fetchWaitlist(true))
  }, Defaults.REFRESH_INTERVAL)

  const handleJoin = () => {
    dispatch(updateCurrentFlow(Flows.JOIN_WAITLIST))
    navigateWithQueryString({ url: '/client-type-selection' })
  }

  const handleBook = () => {
    dispatch(updateCurrentFlow(Flows.SCHEDULE_APPOINTMENT))
    navigateWithQueryString({ url: '/client-type-selection' })
  }

  const handleCheckIn = (appointment) => {
    dispatch(updateCurrentFlow(Flows.CHECK_IN))
    dispatch(updateCurrentClient(appointment.client))
    dispatch(updateCurrentPatient(appointment.patient))
    dispatch(updateCurrentAppointment(appointment))

    if (appointment.client.mobilePhone) {
      navigateWithQueryString({ url: '/phone' })
    } else {
      navigateWithQueryString({ url: '/email' })
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      title={t('Common:WELCOME_SELECT_APPOINTMENT')}
    >
      <Grid
        container
        alignItems="center"
        className={classes.container}
        direction="column"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        wrap="nowrap"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          waitlist.map((appointment) => (
            <WaitListItem
              appointment={appointment}
              key={appointment.id}
              onCheckIn={() => handleCheckIn(appointment)}
            />
          ))
        )}
      </Grid>
      <Grid
        container
        alignItems={isMobile ? 'center' : 'stretch'}
        className={classes.overlayButtons}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
        py={1}
      >
        {business?.waitListEnabled && (
          <JoinWaitlistButton
            className={classes.overlayButton}
            onClick={handleJoin}
          />
        )}
        <KioskButtonWithLoader
          className={classes.overlayButton}
          color="secondary"
          onClick={handleBook}
        >
          {t('Common:BOOK_FUTURE_APPOINTMENT')}
        </KioskButtonWithLoader>
        <KioskMembershipSignUpButton className={classes.overlayButton} />
      </Grid>
    </KioskScreen>
  )
}

export default InClinicWelcomeScreen
