import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  fetchPaymentLinkData,
  getInvoice,
  getPaymentIsExpired,
  getPaymentIsPaid,
} from '../../../store/duck/finance'
import { useNavigateWithQueryString } from '../../../utils'

const PaymentRootScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = parse(location.search)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isExpired = useSelector(getPaymentIsExpired)
  const isPaid = useSelector(getPaymentIsPaid)
  const invoice = useSelector(getInvoice)

  useEffect(() => {
    if (!currentBusiness) {
      dispatch(fetchPaymentLinkData(token))
    }
  }, [token, currentBusiness])

  useEffect(() => {
    if (!currentBusiness) {
      return
    }

    if (isExpired) {
      navigateWithQueryString({ url: '/payment/expired' })
    } else if (isPaid) {
      navigateWithQueryString({ url: '/payment/already-paid' })
    } else if (invoice) {
      navigateWithQueryString({ url: '/payment/invoice' })
    } else {
      // pay balance case
      navigateWithQueryString({ url: '/payment/balance' })
    }
  }, [])

  return null
}

export default PaymentRootScreen
