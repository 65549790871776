import { all, put, takeLatest } from 'redux-saga/effects'

import * as API from '../../api'
import requestAPI from '../sagas/requestAPI'

export const FETCH_PAYMENTS_PUBLIC_KEY = 'payments/FETCH_PAYMENTS_PUBLIC_KEY'
export const FETCH_PAYMENTS_PUBLIC_KEY_SUCCESS =
  'payments/FETCH_PAYMENTS_PUBLIC_KEY_SUCCESS'
export const FETCH_PAYMENTS_PUBLIC_KEY_FAILURE =
  'payments/FETCH_PAYMENTS_PUBLIC_KEY_FAILURE'

export const UPDATE_PAYMENTS_PUBLIC_KEY = 'payments/UPDATE_PAYMENTS_PUBLIC_KEY'
export const UPDATE_PAYMENTS_SECRET_KEY = 'payments/UPDATE_PAYMENTS_SECRET_KEY'

export const fetchPaymentsPublicKey = () => ({
  type: FETCH_PAYMENTS_PUBLIC_KEY,
})
export const fetchPaymentsPublicKeySuccess = (publicKey) => ({
  type: FETCH_PAYMENTS_PUBLIC_KEY_SUCCESS,
  publicKey,
})
export const fetchPaymentsPublicKeyFailure = (error) => ({
  type: FETCH_PAYMENTS_PUBLIC_KEY_FAILURE,
  error,
})

export const updatePaymentsSecretKey = (secretKey) => ({
  type: UPDATE_PAYMENTS_SECRET_KEY,
  secretKey,
})
export const updatePaymentsPublicKey = (publicKey) => ({
  type: UPDATE_PAYMENTS_PUBLIC_KEY,
  publicKey,
})

export const INITIAL_STATE = {
  publicKey: undefined,
  secretKey: undefined,
  isLoading: false,
  error: null,
}

export const paymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS_PUBLIC_KEY:
      return { ...state, isLoading: true }
    case FETCH_PAYMENTS_PUBLIC_KEY_SUCCESS:
      return { ...state, isLoading: false, publicKey: action.publicKey }
    case FETCH_PAYMENTS_PUBLIC_KEY_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case UPDATE_PAYMENTS_PUBLIC_KEY:
      return { ...state, publicKey: action.publicKey }
    case UPDATE_PAYMENTS_SECRET_KEY:
      return { ...state, secretKey: action.secretKey }
    default:
      return state
  }
}

export const getPayments = (state) => state.payments
export const getPaymentsIsLoading = (state) => getPayments(state).isLoading
export const getPaymentsError = (state) => getPayments(state).error
export const getPaymentsPublicKey = (state) => getPayments(state).publicKey
export const getPaymentsSecretKey = (state) => getPayments(state).secretKey

export function* sagaFetchPaymentsPublicKey() {
  try {
    const result = yield* requestAPI(API.fetchPaymentsPublicKey)
    yield put(fetchPaymentsPublicKeySuccess(result.publicKey))
  } catch (error) {
    yield put(fetchPaymentsPublicKeyFailure(error))
  }
}

function* watchFetchPaymentsPublicKey() {
  yield takeLatest(FETCH_PAYMENTS_PUBLIC_KEY, sagaFetchPaymentsPublicKey)
}

export function* paymentsSaga() {
  yield all([watchFetchPaymentsPublicKey()])
}
