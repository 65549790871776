import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NumberUtils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      borderTop: theme.constants.tableBorder,
      marginTop: theme.spacing(2),
    },
    text: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    totalText: {
      fontWeight: 500,
      color: theme.colors.primaryText,
    },
    summary: {
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
      padding: theme.spacing(2),
      backgroundColor: theme.colors.tableEvenItem,
    },
    summaryItem: {
      flexShrink: 0,
    },
  }),
  { name: 'InvoiceSummary' },
)

const InvoiceSummary = ({ invoice }) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const { subtotal, totalDiscount, totalTax, amount } = invoice || {}

  return (
    <Grid container item className={classes.root}>
      <Grid item xs />
      <Grid
        container
        item
        className={classes.summary}
        direction="column"
        justifyContent="center"
      >
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:SUBTOTAL')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(subtotal)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>
            {t('Common:DISCOUNT')}
          </Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(totalDiscount, true)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classes.text}>{t('Common:TAX')}</Typography>
          <Typography className={classes.text}>
            {NumberUtils.formatMoney(totalTax)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Typography className={classNames(classes.text, classes.totalText)}>
            {t('Common:INVOICE_TOTAL')}
          </Typography>
          <Typography className={classNames(classes.text, classes.totalText)}>
            {NumberUtils.formatMoney(amount)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

InvoiceSummary.propTypes = {
  invoice: PropTypes.object,
}

export default InvoiceSummary
