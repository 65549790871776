import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTextField, useFields } from '@pbt/pbt-ui-components'

import {
  fetchClientByEmail,
  getClientsError,
  getClientsIsLoading,
  getCurrentClient,
} from '../../store/duck/clients'
import { getIsCheckInFlow, getIsJoinWaitlistFlow } from '../../store/duck/flow'
import { getPatientsIsLoading } from '../../store/duck/patients'
import {
  checkInForAppointment,
  getCurrentAppointment,
} from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import { isPatientCheckedIn } from '../../utils/isPatientCheckedIn'
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    textField: {
      maxWidth: 382,
    },
  }),
  { name: 'EmailScreen' },
)

const EmailScreen = ({
  enableUpdateClientFlow = true,
  successRoute,
  failureRoute = '/not-found',
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const currentClient = useSelector(getCurrentClient)
  const appointment = useSelector(getCurrentAppointment)
  const isLoading = useSelector(getClientsIsLoading)
  const isCheckIn = useSelector(getIsCheckInFlow)
  const error = useSelector(getClientsError)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)

  const {
    fields: { email },
    validate,
  } = useFields([
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email', 'required'],
      initialValue: '',
    },
  ])

  const onCheckedIn = () => {
    navigateWithQueryString({ url: '/success' })
  }

  const callbackWhenLoadedAppointment = useCallbackWhenLoaded(
    onCheckedIn,
    getPatientsIsLoading,
  )

  const onUserFetched = () => {
    if (currentClient) {
      if (currentClient.needsUpdate && enableUpdateClientFlow) {
        navigateWithQueryString({ url: '/client-details' })
      } else if (!enableUpdateClientFlow) {
        navigateWithQueryString({ url: successRoute })
      } else if (isPatientCheckedIn(isWaitlist, isCheckIn, appointment)) {
        callbackWhenLoadedAppointment()
        dispatch(checkInForAppointment(appointment?.id, true))
      } else if (isCheckIn && !appointment.isCheckedIn) {
        navigateWithQueryString({ url: '/any-more-pets' })
      } else if (isWaitlist) {
        navigateWithQueryString({ url: '/any-more-pets' })
      } else {
        navigateWithQueryString({ url: '/select-patient' })
      }
    } else {
      navigateWithQueryString({ url: failureRoute })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onUserFetched,
    getClientsIsLoading,
  )

  const proceed = () => {
    if (validate()) {
      callbackWhenLoaded()

      if (isCheckIn) {
        dispatch(fetchClientByEmail(email.value, appointment.id))
      } else {
        dispatch(fetchClientByEmail(email.value))
      }
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={!email.value}
      proceedButtonLabel="Next"
      proceedButtonLoading={isLoading}
      title={
        error
          ? t(
              'Common:WE_ARE_SORRY_WE_COULD_NOT_FIND_YOUR_ACCOUNT_WITH_THAT_NUMBER',
            )
          : t('Common:PLESE_CONFIRM_YOUR_EMAIL_ADDRESS')
      }
      onProceed={proceed}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.root}
        direction="column"
      >
        <PuiTextField
          autoFocus
          fullWidth
          className={classes.textField}
          field={email}
          inputProps={{ maxLength: 100 }}
          label={`${email.label}*`}
          type="email"
        />
      </Grid>
    </KioskScreen>
  )
}

export default EmailScreen
