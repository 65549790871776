import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, moment, Utils } from '@pbt/pbt-ui-components'

import Features from '../../constants/features'
import { getCurrentBusiness } from '../../store/duck/businesses'
import { getEventState, getFeatureToggle } from '../../store/duck/constants'
import { getIsCheckInFlow, getIsJoinWaitlistFlow } from '../../store/duck/flow'
import {
  getCurrentAppointment,
  getSchedulesIsLoading,
} from '../../store/duck/schedules'
import { getWaitTimeString, useNavigateWithQueryString } from '../../utils'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
import AppointmentInfoSection from '../common/AppointmentInfoSection'
import CheckInWithConferencing from './checkin/CheckInWithConferencing'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(11, 4, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    button: {
      width: 224,
      marginTop: theme.spacing(5),
    },
    loadingIndicator: {
      marginTop: '50px',
    },
  }),
  { name: 'SuccessScreen' },
)

const SuccessScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const EventStates = useSelector(getEventState)
  const arrivedStateId = Utils.findConstantIdByName('Arrived', EventStates)
  const appointment = useSelector(getCurrentAppointment)
  const business = useSelector(getCurrentBusiness)
  const isCheckIn = useSelector(getIsCheckInFlow)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)
  const { estimatedWaitTimeInKioskEnabled } =
    useSelector(getCurrentBusiness) || {}
  const isEstimatedWaitTimeInKioskFeatureToggleEnabled = useSelector(
    getFeatureToggle(Features.WAIT_LIST_ESTIMATED_TIME),
  )
  const isLoadingSchedules = useSelector(getSchedulesIsLoading)

  const appointmentStart = moment(appointment?.scheduledStartDatetime)

  const date = appointmentStart.format(DateFormat.DAY_OF_WEEK_MONTH_DAY)
  const time = appointmentStart.format(DateFormat.FULL_TIME_WITH_MERIDIAN)

  const waitTimeString = getWaitTimeString(appointment)

  const finish = () => {
    navigateWithQueryString({ url: '/' })
  }

  const zoomEnabled = Boolean(business?.zoomIntegrationEnabled)
  const includeZoomLinkInCheckIn = Boolean(business?.includeZoomLinkInCheckin)
  const showCheckInWithConferencing =
    isCheckIn &&
    zoomEnabled &&
    includeZoomLinkInCheckIn &&
    appointment.meetingLink

  if (isLoadingSchedules) {
    return (
      <KioskScreen justifyContent="flex-start">
        <CircularProgress className={classes.loadingIndicator} />
      </KioskScreen>
    )
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      title={
        isCheckIn
          ? appointment.state?.id === arrivedStateId
            ? `${t('Common:YOU_ARE_ALL_CHECKED_IN')}. ${t(
                'Common:A_TEAM_MEMBER_WILL_BE_WITH_YOU_SHORTLY',
              )}`
            : `
              ${t('Common:YOU_ARE_ALL_CHECKED_IN')}. 
              ${t(
                'Common:PLEASE_RETURN_TO_THIS_PAGE_OR_VISIT_THE_KIOSK_AT_THE_FRONT_DESK_TO_LET_US_KNOW_WHEN_YOU_HAVE_ARRIVED',
              )}
            `
          : isWaitlist
          ? !isEstimatedWaitTimeInKioskFeatureToggleEnabled ||
            estimatedWaitTimeInKioskEnabled
            ? t('Common:YOU_HAVE_BEEN_ADDED_TO_WAITLIST_CURRENT_WAIT_IS_TIME', {
                time: waitTimeString,
              })
            : t('Common:YOU_HAVE_BEEN_ADDED_TO_WAITLIST')
          : t('Common:YOUR_APPOINTMENT_IS_SCHEDULED_FOR_DATE_AT_TIME', {
              date,
              time,
            })
      }
    >
      {showCheckInWithConferencing ? (
        <CheckInWithConferencing />
      ) : (
        <Grid
          container
          item
          alignItems="center"
          className={classes.root}
          justifyContent="center"
        >
          <AppointmentInfoSection appointment={appointment} />
          <KioskButtonWithLoader className={classes.button} onClick={finish}>
            {t('Common:FINISH_ACTION')}
          </KioskButtonWithLoader>
        </Grid>
      )}
    </KioskScreen>
  )
}

export default SuccessScreen
