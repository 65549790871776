import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Paper, Typography } from '@mui/material'
import { DateFormat, DateUtils } from '@pbt/pbt-ui-components'
import { DateFormatOptionsMap } from '@pbt/pbt-ui-components/src/localization'
import { updateMomentUiLocale } from '@pbt/pbt-ui-components/src/momentLocale'

// @ts-ignore
import i18nKiosk from '../../locales/i18n'
import { getNPSData } from '../../store/duck/nps'

updateMomentUiLocale(i18nKiosk.language)

const CompleteFeedback = () => {
  const { t } = useTranslation(['Common'])
  const npsData = useSelector(getNPSData)

  const { feedbackDate } = npsData || {}
  const format =
    DateFormatOptionsMap[i18nKiosk.language].longDateFormat[
      DateFormat.FULL_DATE_COMMA
    ]

  const text = t('Common:FEEDBACK.COMPLETE_FEEDBACK', {
    feedbackDate: DateUtils.formatDate(feedbackDate, false, format),
  })

  return (
    <Grid
      container
      item
      md
      sm
      alignItems="center"
      component={Paper}
      direction="column"
      justifyContent="center"
    >
      <Typography align="center" sx={{ width: '50%' }} variant="h3">
        {text}
      </Typography>
    </Grid>
  )
}

export default CompleteFeedback
