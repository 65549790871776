import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PhoneUtils, Text } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../store/duck/businesses'
import { useNavigateWithQueryString } from '../utils'
import LanguageSelector from './LanguageSelector'
import BusinessLink from './links/BusinessLink'

const useStyles = makeStyles(
  (theme) => ({
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
    },
    logoContainer: {
      width: 140,
      height: 70,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: 96,
        height: 48,
      },
      padding: `2px ${theme.spacing(1)}`,
      background: theme.colors.badgeColor,
      borderRadius: 4,
      border: '1px solid rgba(229, 229, 229, 0.5)',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
    },
    logoContainerClickable: {
      cursor: 'pointer',
    },
    wrapper: {
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'Header' },
)

const Header = ({ className, clickable = true, rootUrl = '/' }) => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const business = useSelector(getCurrentBusiness)

  return (
    <Grid container item wrap="nowrap" zIndex={1}>
      <Grid
        container
        item
        alignItems={isMobile ? 'flex-start' : 'center'}
        className={classes.wrapper}
        justifyContent="flex-start"
        wrap="nowrap"
      >
        {business && (
          <>
            {business.logo && (
              <Grid
                container
                alignItems="center"
                className={classNames(className, classes.logoContainer, {
                  [classes.logoContainerClickable]: clickable,
                })}
                onClick={() => {
                  if (clickable) {
                    navigateWithQueryString({ url: rootUrl })
                  }
                }}
              >
                <img
                  alt="logotype"
                  className={classes.image}
                  src={business.logo}
                />
              </Grid>
            )}
            <Grid item>
              <Text strong variant="h2">
                {business.name}
              </Text>
              {business.website && isMobile && (
                <BusinessLink business={business} />
              )}
              <Text>{business.address}</Text>
              <Grid container direction="row">
                <Text>{PhoneUtils.formatPhoneNumber(business.phone)}</Text>
                {business.website && !isMobile && (
                  <BusinessLink business={business} />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item>
        <LanguageSelector />
      </Grid>
    </Grid>
  )
}

export default Header
