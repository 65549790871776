import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  editConfirmableAppointment,
  getAppointmentConfirmationAppointment,
  getAppointmentConfirmationIsLoading,
} from '../../../store/duck/appointmentConfirmation'
import { getIsFetchingStuff } from '../../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../../utils'
import { getPatchedAppointment } from '../../../utils/appointmentUtils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import CalendarSection from '../../common/calendar-section/CalendarSection'
import KioskScreen from '../KioskScreen'

const AppointmentConfirmationRescheduleScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getAppointmentConfirmationIsLoading)
  const isFetchingStuff = useSelector(getIsFetchingStuff)
  const appointment = useSelector(getAppointmentConfirmationAppointment)

  const calendarSectionRef = useRef()

  const [canProceed, setCanProceed] = useState(false)

  const onAppointmentEdited = () => {
    navigateWithQueryString({ url: '/appointment-confirmation/confirmed' })
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onAppointmentEdited,
    getAppointmentConfirmationIsLoading,
  )

  const finalize = (config) => {
    callbackWhenLoaded()

    const editedAppointment = {
      ...getPatchedAppointment(appointment),
      ...config,
    }
    dispatch(editConfirmableAppointment(editedAppointment))
  }

  const isCreate = !appointment.id

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={!canProceed}
      proceedButtonLabel={isCreate ? t('Common:CREATE_APPOINTMENT') : t('Common:EDIT_APPOINTMENT')}
      proceedButtonLoading={isLoading && !isFetchingStuff}
      onBack={calendarSectionRef.current?.onBack}
      onProceed={calendarSectionRef.current?.onProceed}
    >
      <CalendarSection
        appointment={appointment}
        ref={calendarSectionRef}
        onBack={() => navigate(-1)}
        onCanProceedChange={setCanProceed}
        onFinalize={finalize}
      />
    </KioskScreen>
  )
}

export default AppointmentConfirmationRescheduleScreen
