import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  PhoneUtils,
  PuiAlert,
  PuiSelect,
  PuiTextField,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  createClient,
  editClient,
  getClientAlreadyExists,
  getClientAlreadyExistsMessage,
  getClientsIsLoading,
  getCurrentClient,
  setClientAlreadyExists,
} from '../../store/duck/clients'
import { getEventState } from '../../store/duck/constants'
// @ts-ignore
import { getIsCheckInFlow, getIsEmbedded } from '../../store/duck/flow'
import {
  getCurrentAppointment,
  updateAppointmentState,
} from '../../store/duck/schedules'
import { useGetContactMethodWithDisabledFlag } from '../../store/hooks/clients'
import { Appointment } from '../../types/entities/appointments'
import { KioskClient } from '../../types/entities/clients'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
// @ts-ignore
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
// @ts-ignore
import PhoneInput from '../inputs/PhoneInput'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    alertButton: {
      margin: theme.spacing(0, 1),
    },
    selectItem: {
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'ClientDetailsScreen' },
)

const ClientDetailsScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getClientsIsLoading)
  const isEmbedded = useSelector(getIsEmbedded)
  const clientAlreadyExists = useSelector(getClientAlreadyExists)
  const clientAlreadyExistsMessage = useSelector(getClientAlreadyExistsMessage)
  const EventState = useSelector(getEventState)
  const appointment = useSelector(getCurrentAppointment) as Appointment | null
  const isCheckIn = useSelector(getIsCheckInFlow)

  const ContactMethodWithDisabledFlag = useGetContactMethodWithDisabledFlag()

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const spacing = isMobile ? 0 : 4
  const isCreate = !client?.id
  const arrivedId = Utils.findConstantIdByName('Arrived', EventState)

  const {
    fields: {
      mobilePhone,
      homePhone,
      workPhone,
      otherPhone,
      email,
      preferredContactMethod,
    },
    validate,
  } = useFields(
    [
      {
        name: 'mobilePhone',
        label: `${t('Common:MOBILE_NUMBER')}*`,
        validators: ['phone', 'required'],
        initialValue: isCreate
          ? ''
          : PhoneUtils.formatPhoneNumber(client?.mobilePhone),
      },
      {
        name: 'homePhone',
        label: t('Common:HOME_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: isCreate
          ? ''
          : PhoneUtils.formatPhoneNumber(client?.homePhone),
      },
      {
        name: 'workPhone',
        label: t('Common:WORK_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: isCreate
          ? ''
          : PhoneUtils.formatPhoneNumber(client?.workPhone),
      },
      {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: isCreate
          ? ''
          : PhoneUtils.formatPhoneNumber(client?.otherPhone),
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email', 'required'],
        initialValue: isCreate ? '' : client?.email,
      },
      {
        name: 'preferredContactMethod',
        label: t('Common:PREFERRED_CONTACT_METHOD'),
        type: 'select',
        initialValue: isCreate ? '' : client?.preferredContactMethod,
      },
    ],
    false,
  )

  const onClientUpdated = () => {
    navigateWithQueryString({ url: '/client-address' })
  }

  const onClientCreated = () => {
    if (!clientAlreadyExists) {
      navigateWithQueryString({ url: '/client-address' })
    }
  }

  const onClientCreatedForced = () => {
    navigateWithQueryString({ url: '/select-patient' })
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onClientUpdated,
    getClientsIsLoading,
  )
  const callbackWhenCreated = useCallbackWhenLoaded(
    onClientCreated,
    getClientsIsLoading,
  )
  const callbackWhenCreatedForced = useCallbackWhenLoaded(
    onClientCreatedForced,
    getClientsIsLoading,
  )

  const onProceed = (force?: boolean) => {
    if (validate()) {
      const newClient = {
        ...client,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        homePhone: PhoneUtils.parsePhoneNumber(homePhone.value),
        workPhone: PhoneUtils.parsePhoneNumber(workPhone.value),
        otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
        email: email.value,
        preferredContactMethod: preferredContactMethod.value,
        patients: client?.patients || [],
      } as KioskClient

      if (!isEmbedded && isCheckIn && appointment?.id) {
        dispatch(updateAppointmentState(appointment.id, arrivedId))
      }

      if (isCreate) {
        if (force) {
          callbackWhenCreatedForced()
        } else {
          callbackWhenCreated()
        }
        dispatch(createClient(newClient, force))
      } else {
        callbackWhenLoaded()
        // emailVerificationDate should be nullified for correct email validation handling
        dispatch(
          editClient({
            ...newClient,
            patients: null,
            coparents: null,
            emailVerificationDate: null,
          }),
        )
      }
    }
  }

  const handleCloseAlert = () => {
    dispatch(setClientAlreadyExists(false))
  }

  const onContinueToReturningFlow = () => {
    handleCloseAlert()
    onProceed(true)
  }

  const onCancelReturningFlow = () => {
    handleCloseAlert()
    navigateWithQueryString({ url: '/' })
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonLabel={isCreate ? t('Common:CREATE_ACCOUNT') : undefined}
      proceedButtonLoading={isLoading}
      title={
        isCreate
          ? t('Common:HOW_SHOULD_WE_CONTACT_YOU')
          : t(
              'Common:WELCOME_BACK_CLIENT_NAME_CAN_YOU_CHECK_THAT_YOUR_INFO_IS_UP_TO_DATE',
              { clientName: client?.firstName || '' },
            )
      }
      onProceed={() => onProceed()}
    >
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        spacing={spacing}
      >
        <Grid container item alignItems="flex-end" spacing={spacing}>
          <Grid item sm={6} xs={12}>
            <PhoneInput fullWidth field={mobilePhone} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PhoneInput fullWidth field={homePhone} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PhoneInput fullWidth field={workPhone} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PhoneInput fullWidth field={otherPhone} />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end" spacing={spacing}>
          <Grid item sm xs={12}>
            <PuiTextField
              fullWidth
              disabled={!isCreate && !isCheckIn && Boolean(client?.email)}
              field={email}
              inputProps={{ maxLength: 100 }}
              label={`${email.label}*`}
              type="email"
            />
          </Grid>
          <Grid item sm xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="contact-method-select">
                {preferredContactMethod.label}
              </InputLabel>
              <PuiSelect
                field={preferredContactMethod}
                input={<Input id="contact-method-select" />}
                items={ContactMethodWithDisabledFlag}
                selectItemProps={{ classes: { root: classes.selectItem } }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <PuiAlert
        content={
          <Grid container wrap="nowrap">
            <KioskButtonWithLoader
              className={classes.alertButton}
              color="primary"
              onClick={onContinueToReturningFlow}
            >
              {t('Common:CONTINUE_ACTION')}
            </KioskButtonWithLoader>
            <KioskButtonWithLoader
              className={classes.alertButton}
              color="secondary"
              onClick={onCancelReturningFlow}
            >
              {t('Common:CANCEL_ACTION')}
            </KioskButtonWithLoader>
          </Grid>
        }
        iconType={AlertIconType.WARN}
        message={clientAlreadyExistsMessage}
        open={clientAlreadyExists}
        onClose={handleCloseAlert}
      />
    </KioskScreen>
  )
}

export default ClientDetailsScreen
