import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { startsWith } from 'ramda'

import { getKioskDisabled, getLastError } from '../store/duck/errors'
import { useNavigateWithQueryString } from '../utils'

const ERROR_ROOT = '/error'

const COMPLEX_ROOTS = {
  PAYMENT: '/payment',
  MEMBERSHIP: '/membership',
  APPOINTMENT_CONFIRMATION: '/appointment-confirmation',
}

const ErrorWatcher = () => {
  const location = useLocation()
  const navigateWithQueryString = useNavigateWithQueryString()
  const lastError = useSelector(getLastError)
  const kioskDisabled = useSelector(getKioskDisabled)

  const complexRoot = Object.values(COMPLEX_ROOTS).find((root) =>
    startsWith(root, location.pathname),
  )

  useEffect(() => {
    if (lastError || kioskDisabled) {
      const errorUrl = complexRoot ? complexRoot + ERROR_ROOT : ERROR_ROOT

      navigateWithQueryString({ url: errorUrl })
    }
  }, [lastError, kioskDisabled])

  return null
}

export default ErrorWatcher
