import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { omit } from 'ramda'
import {
  LanguageUtils,
  Patient,
  PuiSelect,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  fetchAppointmentTypesList,
  getAppointmentReasons,
  getAppointmentTypesIsLoading,
} from '../../store/duck/appointmentTypes'
// @ts-ignore
import { getCurrentPatient } from '../../store/duck/patients'
import {
  getCurrentAppointment,
  updateCurrentAppointment,
} from '../../store/duck/schedules'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
import KioskSelectableButton from '../buttons/KioskSelectableButton'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(5, 5, 1),
      maxHeight: `calc(100vh - ${theme.spacing(50)})`,
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 0),
      },
    },
  }),
  { name: 'AppointmentTypeScreen' },
)

const AppointmentTypeScreen = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const appointmentReasons = useSelector(getAppointmentReasons)
  const patient = useSelector(getCurrentPatient) as Patient
  const appointment = useSelector(getCurrentAppointment) || {}
  const isLoading = useSelector(getAppointmentTypesIsLoading)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const spacing = isMobile ? 2 : 2
  const importantReasonCount = isMobile ? 2 : undefined

  const KioskAppointmentReasons = appointmentReasons.filter(
    (reason) => reason.name,
  )

  const importantReasons = KioskAppointmentReasons.slice(
    0,
    importantReasonCount,
  )
  const nonImportantReasons =
    KioskAppointmentReasons.slice(importantReasonCount)

  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<string>()

  useEffect(() => {
    dispatch(fetchAppointmentTypesList({ from: 0, to: 200 }))
  }, [])

  const selectedNonImportantReason = nonImportantReasons.find(
    ({ appointmentTypeId }: { appointmentTypeId: string }) =>
      appointmentTypeId === selectedAppointmentType,
  )

  const onProceed = () => {
    const newAppointment = {
      ...appointment,
      type: selectedAppointmentType,
    }

    dispatch(updateCurrentAppointment(newAppointment))
    navigateWithQueryString({ url: '/primary-complaint' })
  }

  const onBack = () => {
    const newAppointment = omit(['type'], appointment)
    dispatch(updateCurrentAppointment(newAppointment))
    navigate(-1)
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={!selectedAppointmentType}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      title={
        patient?.name
          ? t('Common:WHY_IS_PET_NAME_COMING_IN', {
              patientName: patient?.name,
            })
          : t('Common:WHY_IS_YOUR_PET_COMING_IN')
      }
      onBack={onBack}
      onProceed={onProceed}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.root}
        flex={isLoading ? 1 : 0}
        justifyContent="center"
        spacing={spacing}
      >
        {isLoading || !importantReasons.length ? (
          <CircularProgress />
        ) : (
          <>
            {importantReasons.map((reason) => {
              const isSelected =
                selectedAppointmentType === reason.appointmentTypeId
              return (
                <Grid item key={reason.appointmentTypeId} sm={6} xs={12}>
                  <KioskSelectableButton
                    isSelected={isSelected}
                    onClick={() =>
                      setSelectedAppointmentType(reason.appointmentTypeId)
                    }
                  >
                    {LanguageUtils.getTranslatedFieldName(reason)}
                  </KioskSelectableButton>
                </Grid>
              )
            })}
            {isMobile && (
              <Grid item>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="reason-select">
                    {t('Common:CHOOSE_ANOTHER_REASON')}
                  </InputLabel>
                  <PuiSelect
                    native
                    // @ts-ignore
                    Item="option"
                    input={<Input id="reason-select" />}
                    items={nonImportantReasons}
                    // @ts-ignore
                    keyProp="appointmentTypeId"
                    value={selectedAppointmentType}
                    onChange={Utils.handleFormSelectInput(
                      setSelectedAppointmentType,
                    )}
                  />
                </FormControl>
                {selectedNonImportantReason && (
                  <KioskSelectableButton isSelected>
                    {selectedNonImportantReason.name}
                  </KioskSelectableButton>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </KioskScreen>
  )
}

export default AppointmentTypeScreen
