import { all, put, select, takeLatest } from 'redux-saga/effects'

import * as API from '../../api'
import requestAPI from '../sagas/requestAPI'
import { getCurrentClient, updateCurrentClient } from './clients'

export const CREATE_PATIENT = 'patients/CREATE_PATIENT'
export const CREATE_PATIENT_SUCCESS = 'patients/CREATE_PATIENT_SUCCESS'
export const CREATE_PATIENT_FAILURE = 'patients/CREATE_PATIENT_FAILURE'

export const EDIT_PATIENT = 'patients/EDIT_PATIENT'
export const EDIT_PATIENT_SUCCESS = 'patients/EDIT_PATIENT_SUCCESS'
export const EDIT_PATIENT_FAILURE = 'patients/EDIT_PATIENT_FAILURE'

export const UPDATE_CURRENT_PATIENT = 'patients/UPDATE_CURRENT_PATIENT'
export const CLEAR_CURRENT_PATIENT = 'patients/CLEAR_CURRENT_PATIENT'

export const createPatient = (clientId, patient) => ({
  type: CREATE_PATIENT,
  clientId,
  patient,
})
export const createPatientSuccess = (patient) => ({
  type: CREATE_PATIENT_SUCCESS,
  patient,
})
export const createPatientFailure = (error) => ({
  type: CREATE_PATIENT_FAILURE,
  error,
})

export const editPatient = (clientId, patient) => ({
  type: EDIT_PATIENT,
  clientId,
  patient,
})
export const editPatientSuccess = (patient) => ({
  type: EDIT_PATIENT_SUCCESS,
  patient,
})
export const editPatientFailure = (error) => ({
  type: EDIT_PATIENT_FAILURE,
  error,
})

export const updateCurrentPatient = (patient) => ({
  type: UPDATE_CURRENT_PATIENT,
  patient,
})
export const clearCurrentPatient = () => ({ type: CLEAR_CURRENT_PATIENT })

const INITIAL_STATE = {
  currentPatient: null,
  error: null,
  isLoading: false,
}

export const patientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PATIENT:
    case EDIT_PATIENT:
      return { ...state, isLoading: true }
    case EDIT_PATIENT_SUCCESS:
    case CREATE_PATIENT_SUCCESS:
      return { ...state, isLoading: false, currentPatient: action.patient }
    case CREATE_PATIENT_FAILURE:
    case EDIT_PATIENT_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case UPDATE_CURRENT_PATIENT:
      return { ...state, currentPatient: action.patient }
    case CLEAR_CURRENT_PATIENT:
      return { ...state, error: null, currentPatient: null }
    default:
      return state
  }
}

export const getPatients = (state) => state.patients
export const getCurrentPatient = (state) => getPatients(state).currentPatient
export const getPatientsIsLoading = (state) => getPatients(state).isLoading
export const getPatientsError = (state) => getPatients(state).error

export function* sagaCreatePatient({ clientId, patient }) {
  try {
    const client = yield select(getCurrentClient)
    const result = yield* requestAPI(API.createPatient, clientId, patient)
    if (client) {
      const newClient = {
        ...client,
        patients: (client.patients || []).concat(result),
        memberships: (client.memberships || []).concat({
          member: false,
          patient: result,
        }),
      }
      yield put(updateCurrentClient(newClient))
    }
    yield put(createPatientSuccess(result))
  } catch (error) {
    yield put(createPatientFailure(error))
  }
}

export function* sagaEditPatient({ clientId, patient }) {
  try {
    const result = yield* requestAPI(API.editPatient, clientId, patient)
    yield put(editPatientSuccess(result))
  } catch (error) {
    yield put(editPatientFailure(error))
  }
}

function* watchCreatePatient() {
  yield takeLatest(CREATE_PATIENT, sagaCreatePatient)
}

function* watchEditPatient() {
  yield takeLatest(EDIT_PATIENT, sagaEditPatient)
}

export function* patientsSaga() {
  yield all([watchCreatePatient(), watchEditPatient()])
}
