import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Features from '../../constants/features'
import { getCurrentBusinessIsOmniChannel } from '../duck/businesses'
import { getCurrentClient } from '../duck/clients'
import { getContactMethod, getFeatureToggle } from '../duck/constants'

export const useShouldDisplaySmsConsent = () => {
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSmsConsentToggleEnabled = useSelector(
    getFeatureToggle(Features.SMS_CONSENT_TOGGLE),
  )

  const shouldDisplaySmsConsent =
    Boolean(isCurrentBusinessOmniChannel) && isSmsConsentToggleEnabled

  return shouldDisplaySmsConsent
}

export const useCanSendSmsToCurrentClient = (): boolean => {
  const currentClient = useSelector(getCurrentClient)
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  return shouldDisplaySmsConsent
    ? currentClient?.globalClientPreferences?.communicationSmsOptIn ?? false
    : true
}

export const useGetContactMethodWithDisabledFlag = () => {
  const { t } = useTranslation('Clients')

  const ContactMethod = useSelector(getContactMethod)

  const canSendSmsToClient = useCanSendSmsToCurrentClient()

  const ContactMethodWithDisabledFlag = ContactMethod.map((method) => {
    const isTextMessageOptionDisabled =
      method.name === 'Text message' && !canSendSmsToClient

    return {
      ...method,
      nameTranslation: isTextMessageOptionDisabled
        ? t('Clients:SMS_CONSENT_DISABLED')
        : method.nameTranslation,
      disabled: isTextMessageOptionDisabled,
    }
  })

  return ContactMethodWithDisabledFlag
}
