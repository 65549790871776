import { all, put, takeEvery } from 'redux-saga/effects'

import {
  CREATE_CLIENT_FAILURE,
  FETCH_CLIENT_BY_EMAIL_FAILURE,
  FETCH_CLIENT_BY_PHONE_FAILURE,
} from './clients'
import { CREATE_WAITLIST_APPOINTMENT_FAILURE } from './schedules'

export const ADD_LAST_ERROR = 'errors/ADD_LAST_ERROR'
export const CLEAR_LAST_ERROR = 'errors/CLEAR_LAST_ERROR'
export const SET_KIOSK_DISABLED = 'errors/SET_KIOSK_DISABLED'

export const addLastError = (error) => ({ type: ADD_LAST_ERROR, error })
export const clearLastError = () => ({ type: CLEAR_LAST_ERROR })
export const setKioskDisabled = (kioskDisabled) => ({
  type: SET_KIOSK_DISABLED,
  kioskDisabled,
})

const INITIAL_STATE = {
  lastError: null,
  kioskDisabled: false,
}

const SILENT_ERRORS = [
  CREATE_CLIENT_FAILURE,
  FETCH_CLIENT_BY_PHONE_FAILURE,
  FETCH_CLIENT_BY_EMAIL_FAILURE,
  CREATE_WAITLIST_APPOINTMENT_FAILURE,
]

const errorMatcher = (action) =>
  action.type.match('_FAILURE$') && !SILENT_ERRORS.includes(action.type)

export const errorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LAST_ERROR:
      return { ...state, lastError: action.error }
    case CLEAR_LAST_ERROR:
      return { ...state, lastError: null }
    case SET_KIOSK_DISABLED:
      return { ...state, kioskDisabled: action.kioskDisabled }
    default:
      return state
  }
}

export const getErrors = (state) => state.errors
export const getLastError = (state) => getErrors(state).lastError
export const getKioskDisabled = (state) => getErrors(state).kioskDisabled

export function* reportError(action) {
  const error = {
    data: action.error?.response?.data || action.error?.originalMessage,
    status: action.error?.response?.status,
  }

  if (error.data?.description?.indexOf('Kiosk is not enabled') > -1) {
    yield put(setKioskDisabled(true))
  }
  if (error.data?.error?.type === 'APPOINTMENT_TYPE_NOT_AVAILABLE') {
    yield put(
      addLastError({ data: 'Selected appointment type is not available.' }),
    )
  } else {
    // eslint-disable-next-line no-console
    console.error(error)
    yield put(addLastError(error))
  }
}

function* watchFailures() {
  yield takeEvery(errorMatcher, reportError)
}

export function* errorsSaga() {
  yield all([watchFailures()])
}
