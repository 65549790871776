import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, LinearProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PaymentIframe, StripePaymentForm } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
    },
  }),
  { name: 'PaymentMethodScreen' },
)

const PaymentMethodScreen = ({
  className,
  stripeSecret,
  isStripePayment,
  formData,
  client,
  isBusy,
  setIsBusy,
  billingAddress,
  setIsProceedDisabled,
  onGenerateCrypto,
  onError,
  onGenerateCryptoSuccess,
  onPaymentSuccess,
}) => {
  const classes = useStyles()
  const stripeRef = useRef()
  const { t } = useTranslation('Common')

  const createStripePayment = async () => {
    const paymentDetails = await stripeRef.current.createPayment({
      client,
      clientSecret: stripeSecret,
      billingAddress,
      method: 'confirmCardPayment',
    })
    setIsBusy(false)
    if (paymentDetails.error) {
      onError(paymentDetails.error?.message || t('Common:UNKNOWN_ERROR'))
    } else {
      onPaymentSuccess()
    }
  }

  useEffect(() => {
    if (stripeSecret && stripeRef?.current && isStripePayment) {
      setIsBusy(true)
      createStripePayment()
    }
  }, [stripeSecret, stripeRef, billingAddress])

  return (
    <Grid container item className={className} direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.title}>
          {t('Common:PAYMENT_METHOD')}
        </Typography>
      </Grid>
      {isBusy && <LinearProgress className={classes.progress} />}
      {isStripePayment ? (
        formData?.stripePublicKey && (
          <StripePaymentForm
            publicKey={formData?.stripePublicKey}
            ref={stripeRef}
            stripeAccount={formData?.stripeAccountId}
            onCompleteStateChange={(isValid) => setIsProceedDisabled(!isValid)}
          />
        )
      ) : (
        <PaymentIframe
          processorId={formData?.processorId}
          src={formData?.url}
          transactionType={formData?.type}
          transcenterId={formData?.transactionCenterId}
          onGenerateCrypto={onGenerateCrypto}
          onGenerateCryptoFailure={onError}
          onGenerateCryptoSuccess={onGenerateCryptoSuccess}
          onLoad={() => setIsBusy(false)}
        />
      )}
    </Grid>
  )
}

export default PaymentMethodScreen
