import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, useTimeout } from '@pbt/pbt-ui-components'

import ContactUsScreen from './ContactUsScreen'

const onSuccessPostMessage = () =>
  window?.ReactNativeWebView?.postMessage('PAYMENT_SUCCESS', '*')

const PaymentFinishedScreen = () => {
  const { t } = useTranslation('Common')

  useTimeout(onSuccessPostMessage, 5 * 1000)

  return (
    <ContactUsScreen hideMessageButton>
      <Text strong align="center" variant="subheading2">
        {t('Common:THANK_YOU_CONTACT_US_ANY_TIME')}
      </Text>
    </ContactUsScreen>
  )
}

export default PaymentFinishedScreen
