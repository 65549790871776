import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getCurrentClient } from '../../../store/duck/clients'
import {
  clearCurrentPatient,
  createPatient,
  getCurrentPatient,
  getPatientsIsLoading,
} from '../../../store/duck/patients'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import PatientDetailsScreen from '../common/PatientDetailsScreen'
import KioskScreen from '../KioskScreen'

const MembershipPatientDetailsScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const location = useLocation()
  const { redirectUrl = '/membership/sign-up' } = location.state || {}
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const patient = useSelector(getCurrentPatient)
  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getPatientsIsLoading)

  const patientDetailsRef = useRef()

  const isCreate = !patient?.id

  const onOk = () => {
    dispatch(clearCurrentPatient())
    navigateWithQueryString({
      url: redirectUrl,
      options: { replace: true },
    })
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(onOk, getPatientsIsLoading)

  const onProceed = () => {
    if (patientDetailsRef.current.validate()) {
      const newPatient = patientDetailsRef.current.getPatient()

      if (isCreate) {
        callbackWhenLoaded()
        dispatch(createPatient(client.id, newPatient))
      }
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonLoading={isLoading}
      title={
        isCreate
          ? t('Common:CAN_YOU_TELL_US_ABOUT_YOUR_PET')
          : t('Common:CONFIRM_PATIENTS_INFORMATION', {
              patientName: patient.name || '',
            })
      }
      onBack={onOk}
      onProceed={onProceed}
    >
      <PatientDetailsScreen patient={patient} ref={patientDetailsRef} />
    </KioskScreen>
  )
}

export default MembershipPatientDetailsScreen
