import './locales/i18n'

import React, { Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { CircularProgressOverlay, muiTheme } from '@pbt/pbt-ui-components'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './store'

import './index.scss'

const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Suspense fallback={<CircularProgressOverlay open />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
