import React from 'react'

import AppointmentMessageScreen, {
  AppointmentMessageType,
} from './AppointmentMessageScreen'

const AppointmentConfirmationMessageScreen = () => (
  <AppointmentMessageScreen
    appointmentMessageType={AppointmentMessageType.CONFIRMATION}
  />
)

export default AppointmentConfirmationMessageScreen
