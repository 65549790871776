import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../store'

const useCallbackWhenLoaded = (
  onOk: () => void,
  loadingSelector: (state: RootState) => boolean,
) => {
  const isLoading = useSelector(loadingSelector)

  const [performCallbackWhenLoaded, setPerformCallbackWhenLoaded] =
    useState(false)

  useEffect(() => {
    if (performCallbackWhenLoaded && !isLoading) {
      setPerformCallbackWhenLoaded(false)
      if (onOk) {
        onOk()
      }
    }
  }, [performCallbackWhenLoaded, isLoading])

  return () => {
    setPerformCallbackWhenLoaded(true)
  }
}

export default useCallbackWhenLoaded
