import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import * as R from 'ramda'
import { LanguageUtils } from '@pbt/pbt-ui-components'
import { Config as LocalizationConfig } from '@pbt/pbt-ui-components/src/localization'

import Features from '../constants/features'
import i18nKiosk from '../locales/i18n'
import { getCurrentBusinessLanguageCodes } from '../store/duck/businesses'
import { getFeatureToggle } from '../store/duck/constants'

export function useChangeLanguage() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const isI18nEnabled = useSelector(getFeatureToggle(Features.I18N))
  const currentBusinessLanguageCodes = useSelector(
    getCurrentBusinessLanguageCodes,
  )

  const isMounted = useRef(false)

  const { LookupQueryString } = LocalizationConfig

  const queryParams = parse(search)
  const hasCurrentLanguagesSupported = Boolean(
    currentBusinessLanguageCodes?.length,
  )
  const isSupportedCachedLanguageCode = currentBusinessLanguageCodes.includes(
    i18nKiosk.language,
  )
  const hasEmbedLanguage = R.has(LookupQueryString, queryParams)

  const toggleLang = (value) => {
    if (!isI18nEnabled || !value) {
      return
    }

    if (hasEmbedLanguage) {
      const newQueryParams = stringify({
        ...queryParams,
        [LookupQueryString]: value,
      })
      navigate(`${pathname}?${newQueryParams}`)
    }

    LanguageUtils.updateLanguage({
      languageValue: value,
      i18n: i18nKiosk,
      SupportedLngsValuesList: currentBusinessLanguageCodes,
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    if (!hasCurrentLanguagesSupported) {
      return
    }

    if (isSupportedCachedLanguageCode) {
      toggleLang(i18nKiosk.language)
      return
    }

    if (!isSupportedCachedLanguageCode) {
      toggleLang(R.head(currentBusinessLanguageCodes))
    }
  }, [currentBusinessLanguageCodes, search, isI18nEnabled])

  return {
    i18n: i18nKiosk,
    toggleLang,
  }
}
