import React from 'react'
import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { mergeDeepRight } from 'ramda'
import { Calendar, rawTheme } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'

const KioskCalendar = (props) => {
  const currentBusiness = useSelector(getCurrentBusiness)

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}
  const backgroundColor = customColorsEnabled ? buttonsColor : undefined
  const isStatic = props.variant === 'static'

  const theme = createTheme(
    mergeDeepRight(rawTheme, {
      components: {
        MuiCalendarPicker: {
          styleOverrides: {
            root: {
              paddingLeft: isStatic ? 32 : 0,
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            daySelected: backgroundColor && {
              backgroundColor,
              '&:hover': {
                backgroundColor,
              },
            },
          },
        },
      },
    }),
  )

  return (
    <ThemeProvider theme={theme}>
      <Calendar {...props} />
    </ThemeProvider>
  )
}

export default KioskCalendar
