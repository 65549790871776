import React from 'react'
import { useSelector } from 'react-redux'
import { GenderField as GenderFieldComponent } from '@pbt/pbt-ui-components'

import { getGender } from '../../../store/duck/constants'

const GenderField = (props) => {
  const Gender = useSelector(getGender)

  return <GenderFieldComponent Gender={Gender} {...props} />
}

export default GenderField
