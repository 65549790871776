import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { Nil } from '@pbt/pbt-ui-components'

import {
  fetchAppointmentConfirmationDataForToken,
  getAppointmentConfirmationAppointment,
  getAppointmentConfirmationError,
  getAppointmentConfirmationIsFetching,
  getAppointmentConfirmationToken,
  // @ts-ignore
} from '../../../store/duck/appointmentConfirmation'
import {
  fetchBusiness,
  getCurrentBusiness,
} from '../../../store/duck/businesses'
import {
  fetchConstants,
  getConstantsIsLoading,
} from '../../../store/duck/constants'
// @ts-ignore
import FullscreenCircularProgress from '../../FullscreenCircularProgress'
// @ts-ignore
import KioskWrapper from '../../KioskWrapper'
// @ts-ignore
import appointmentConfirmationRoutes from './appointmentConfirmationRoutes'

const AppointmentConfirmationWrapperScreen = () => {
  const { search } = useLocation()
  const { businessId: businessIdParam, token: tokenFromUrl } = parse(search)
  const dispatch = useDispatch()
  const currentBusiness = useSelector(getCurrentBusiness)
  const appointment = useSelector(getAppointmentConfirmationAppointment)
  const token = useSelector(getAppointmentConfirmationToken)
  const error = useSelector(getAppointmentConfirmationError)
  const isFetching = useSelector(getAppointmentConfirmationIsFetching)
  const constantsIsLoading = useSelector(getConstantsIsLoading)

  const { id } = currentBusiness || {}
  const businessId = (businessIdParam || id) as string | Nil

  const rootUrl = tokenFromUrl ? '/appointment-confirmation' : '/'

  useEffect(() => {
    if (businessId) {
      dispatch(fetchBusiness(businessId))
      dispatch(fetchConstants(businessId))
    }
  }, [businessId])

  useEffect(() => {
    if (tokenFromUrl && !token && businessId && !error && !isFetching) {
      dispatch(
        fetchAppointmentConfirmationDataForToken(businessId, tokenFromUrl),
      )
    }
  }, [tokenFromUrl, businessId, error])

  const showSpinner = !currentBusiness || constantsIsLoading

  if (showSpinner) {
    return <FullscreenCircularProgress />
  }

  return (
    <KioskWrapper
      redirectCondition={!appointment}
      rootUrl={rootUrl}
      routes={appointmentConfirmationRoutes}
    />
  )
}

export default AppointmentConfirmationWrapperScreen
