import { useEffect, useState } from 'react'
import { useThrottledCallback } from 'use-debounce'

const getDimensions = () => {
  const height = document.documentElement?.clientHeight || window.innerHeight
  const width = document.documentElement?.clientWidth || window.innerWidth

  document.documentElement.style.setProperty('--app-height', `${height}px`)
  document.documentElement.style.setProperty('--app-width', `${width}px`)

  return {
    width,
    height,
  }
}

export function useFullWindowDimensions() {
  const [dimensions, setDimensions] = useState(getDimensions)

  const onUpdateDimensions = () => {
    const dimensions = getDimensions()
    setDimensions(dimensions)
  }

  const dimensionHandler = useThrottledCallback(onUpdateDimensions, 200)

  useEffect(() => {
    window.addEventListener('resize', dimensionHandler)

    return () => window.removeEventListener('resize', dimensionHandler)
  }, [dimensionHandler])

  return { dimensions }
}
