import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { propEq } from 'ramda'
import { PuiTextArea } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentClient } from '../../../store/duck/clients'
import {
  clearMembershipSubscriptionStatus,
  fetchMembershipSignUpData,
  getMembershipSelection,
  getMembershipSubscriptionStatus,
  getMembershipToken,
  getWellnessPlansIsFetching,
  updateMembershipSignUpTokenSelection,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    item: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(2),
      },
    },
    tryAgainText: {
      marginTop: theme.spacing(2),
    },
    textArea: {
      maxWidth: `calc(100% - ${theme.spacing(2)})`,
      width: 500,
    },
    icon: {
      width: 32,
      height: 32,
      color: theme.colors.alertWarning,
    },
    button: {
      width: 285,
    },
    linkButton: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ErrorScreen' },
)

const MembershipSignUpErrorScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Error'])

  const membershipSubscriptionStatus = useSelector(
    getMembershipSubscriptionStatus,
  )
  const client = useSelector(getCurrentClient)
  const selection = useSelector(getMembershipSelection)
  const isFetching = useSelector(getWellnessPlansIsFetching)
  const token = useSelector(getMembershipToken)

  const subscriptions = membershipSubscriptionStatus?.subscriptions || []
  const failedSubscriptions = subscriptions.filter(propEq('state', 'ERROR'))
  const firstFailedSubscription = subscriptions[0]
  const error = firstFailedSubscription?.error
  const hasErrorToShow = Boolean(error?.message)

  const onSelectionUpdatedForManualFlow = () => {
    dispatch(clearMembershipSubscriptionStatus())
    navigateWithQueryString({ url: '/membership/billing-address' })
  }

  const setCallbackWhenSelectionUpdatedOn = useCallbackWhenLoaded(
    onSelectionUpdatedForManualFlow,
    getWellnessPlansIsFetching,
  )

  const onSignUpDataFetched = () => {
    const failedSelection = selection.filter(({ patientId }) =>
      failedSubscriptions.some(propEq('patientId', patientId)),
    )

    setCallbackWhenSelectionUpdatedOn()
    dispatch(updateMembershipSignUpTokenSelection(token, failedSelection))
  }

  const setCallbackWhenFetchedSignUpData = useCallbackWhenLoaded(
    onSignUpDataFetched,
    getWellnessPlansIsFetching,
  )

  const navigateToBilling = () => {
    setCallbackWhenFetchedSignUpData()
    dispatch(fetchMembershipSignUpData(client.id))
  }

  return (
    <KioskScreen>
      <Grid
        container
        item
        alignItems="center"
        className={classes.root}
        direction="column"
        justifyContent="center"
      >
        <Grid item>
          <WarnAlert className={classes.icon} />
        </Grid>
        <Grid item className={classes.item}>
          <Typography align="center" variant="h5">
            {t('Error:PAYMENT_WAS_NOT_SUCCESSFUL')}
          </Typography>
          <Typography
            align="center"
            className={classes.tryAgainText}
            variant="body2"
          >
            {t('Common:PLEASE_TRY_AGAIN_OR_USE_DIFFERENT_PAYMENT_METHOD')}.
          </Typography>
        </Grid>
        {hasErrorToShow && (
          <PuiTextArea className={classes.textArea} value={error.message} />
        )}
        <Grid
          container
          item
          alignItems="center"
          className={classes.item}
          direction="column"
        >
          <KioskButtonWithLoader
            className={classes.button}
            loading={isFetching}
            onClick={navigateToBilling}
          >
            {t('Common:TRY_AGAIN')}
          </KioskButtonWithLoader>
        </Grid>
      </Grid>
    </KioskScreen>
  )
}

export default MembershipSignUpErrorScreen
