import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../store/duck/clients'
import { getAppointmentTypes } from '../../store/duck/constants'
import { getIsCheckInFlow } from '../../store/duck/flow'
import {
  editPatient,
  getCurrentPatient,
  getPatientsIsLoading,
  updateCurrentPatient,
} from '../../store/duck/patients'
import { getCurrentAppointment } from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import PatientDetailsScreen from './common/PatientDetailsScreen'
import KioskScreen from './KioskScreen'

const KioskPatientDetailsScreen = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const patient = useSelector(getCurrentPatient)
  const isCheckIn = useSelector(getIsCheckInFlow)
  const appointment = useSelector(getCurrentAppointment) || {}
  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getPatientsIsLoading)
  const AppointmentTypes = useSelector(getAppointmentTypes)

  const patientDetailsRef = useRef()

  const BoardingType = Utils.findConstantIdByName('Boarding', AppointmentTypes)

  const isCreate = !patient?.id
  const isBoarding = appointment.type?.id === BoardingType

  const onPatientUpdated = () => {
    if (isCheckIn) {
      if (isBoarding) {
        navigateWithQueryString({ url: '/patient-diet-and-allergies' })
      } else {
        navigateWithQueryString({ url: '/questions' })
      }
    } else if (appointment.type) {
      navigateWithQueryString({ url: '/primary-complaint' })
    } else {
      navigateWithQueryString({ url: '/appointment-type' })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onPatientUpdated,
    getPatientsIsLoading,
  )

  const onProceed = () => {
    if (patientDetailsRef.current.validate()) {
      const newPatient = patientDetailsRef.current.getPatient()

      if (isCreate) {
        dispatch(updateCurrentPatient(newPatient))
        navigateWithQueryString({ url: '/patient-microchipped' })
      } else {
        callbackWhenLoaded()
        dispatch(editPatient(client.id, newPatient))
      }
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonLoading={isLoading}
      title={
        isCreate
          ? t('Common:CAN_YOU_TELL_US_ABOUT_YOUR_PET')
          : t('Common:CONFIRM_PATIENTS_INFORMATION', {
              patientName: patient.name || '',
            })
      }
      onProceed={onProceed}
    >
      <PatientDetailsScreen patient={patient} ref={patientDetailsRef} />
    </KioskScreen>
  )
}

export default KioskPatientDetailsScreen
