import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getAppointmentTypesMap } from '../../store/duck/appointmentTypes'
import {
  createAppointment,
  editAppointment,
  getCurrentAppointment,
  getIsFetchingStuff,
  getSchedulesIsLoading,
} from '../../store/duck/schedules'
import {
  Appointment,
  AppointmentConfig,
  AppointmentToCreateFromStore,
  BusinessAppointment,
  BusinessAppointmentToEditFromStore,
  BusinessAppointmentType,
} from '../../types/entities/appointments'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
// @ts-ignore
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import CalendarSection, {
  CalendarSectionHandle,
} from '../common/calendar-section/CalendarSection'
import KioskScreen from './KioskScreen'

const KioskCalendarScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const appointment = useSelector(getCurrentAppointment) as
    | Appointment
    | AppointmentToCreateFromStore
  const isLoading = useSelector(getSchedulesIsLoading)
  const isFetchingStuff = useSelector(getIsFetchingStuff)
  const appointmentTypesMap = useSelector(getAppointmentTypesMap)

  const calendarSectionRef = useRef<CalendarSectionHandle>(null)

  const [canProceed, setCanProceed] = useState(false)

  const isCreate = !(appointment as Appointment)?.id

  const onAppointmentCreated = () => {
    navigateWithQueryString({ url: '/any-more-pets' })
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onAppointmentCreated,
    getSchedulesIsLoading,
  )

  const finalize = (config: AppointmentConfig) => {
    callbackWhenLoaded()

    if (isCreate) {
      const newAppointment = {
        ...(appointment as AppointmentToCreateFromStore),
        ...config,
        type: appointmentTypesMap[appointment!.type as string]?.eventTypeId,
        businessAppointmentType: { id: appointment!.type as string },
      }
      dispatch(createAppointment(newAppointment))
    } else {
      const getTypes = () => {
        const isTypeEdited = typeof appointment.type === 'string'
        if (isTypeEdited) {
          return {
            eventTypeId:
              appointmentTypesMap[appointment.type as string]?.eventTypeId,
            businessAppointmentTypeId: appointment.type as string,
          }
        }
        return {
          eventTypeId: (appointment as Appointment).type.id,
          businessAppointmentTypeId: (appointment as BusinessAppointment)
            .businessAppointmentType.id,
        }
      }

      const { eventTypeId, businessAppointmentTypeId } = getTypes()

      const newAppointmentForEdit = {
        ...(appointment as BusinessAppointmentToEditFromStore),
        ...config,
        type: eventTypeId,
        businessAppointmentType: {
          id: businessAppointmentTypeId as string,
        } as BusinessAppointmentType,
      }

      const editedAppointment = {
        ...newAppointmentForEdit,
        client: newAppointmentForEdit.client.id,
        patient: newAppointmentForEdit.patient.id,
        state: newAppointmentForEdit.state.id,
      }
      dispatch(editAppointment(editedAppointment))
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={!canProceed}
      proceedButtonLabel={
        isCreate ? t('Common:CREATE_APPOINTMENT') : t('Common:EDIT_APPOINTMENT')
      }
      proceedButtonLoading={isLoading && !isFetchingStuff}
      onBack={calendarSectionRef.current?.onBack}
      onProceed={calendarSectionRef.current?.onProceed}
    >
      <CalendarSection
        appointment={appointment}
        ref={calendarSectionRef}
        onBack={() => navigate(-1)}
        onCanProceedChange={setCanProceed}
        onFinalize={finalize}
      />
    </KioskScreen>
  )
}

export default KioskCalendarScreen
