import { useEffect, useRef, useState } from 'react'
import { useThrottledCallback } from 'use-debounce'

const CLICKED_REF_TIMEOUT = 500

export const useScrollSpy = (scrollRef) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const clickedRef = useRef()
  const unsetClickedRef = useRef()

  const items = scrollRef?.children || []
  const hasNextItem = activeItemIndex + 1 < items.length

  const goToItem = (newItemIndex) => {
    clickedRef.current = true
    clearTimeout(unsetClickedRef.current)
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, CLICKED_REF_TIMEOUT)

    if (newItemIndex < items.length) {
      setActiveItemIndex(newItemIndex)

      if (scrollRef) {
        const maxScroll = scrollRef.scrollHeight - scrollRef.clientHeight

        if (items[newItemIndex].offsetTop > maxScroll) {
          setActiveItemIndex(items.length - 1)
        }

        scrollRef.scrollTo({
          top: items[newItemIndex].offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }

  const goToNextItem = () => goToItem(activeItemIndex + 1)

  const onScroll = () => {
    // Don't set the active index based on scroll if a button was just clicked
    if (clickedRef.current) {
      return
    }

    const maxScroll = scrollRef.scrollHeight - scrollRef.clientHeight

    if (scrollRef.scrollTop >= maxScroll) {
      setActiveItemIndex(items.length - 1)
      return
    }

    if (scrollRef.scrollTop < 0) {
      setActiveItemIndex(0)
      return
    }

    const children = Array.prototype.slice.call(scrollRef.children)
    const closestNode = children.find(
      (element) => element.offsetTop > scrollRef.scrollTop,
    )

    if (closestNode) {
      if (closestNode.offsetTop > maxScroll) {
        setActiveItemIndex(items.length - 1)
      } else {
        setActiveItemIndex(Math.max(children.indexOf(closestNode) - 1, 0))
      }
    }
  }

  const scrollHandler = useThrottledCallback(onScroll, 200)

  useEffect(() => {
    if (scrollRef) {
      scrollRef.addEventListener('scroll', scrollHandler)
    }
    return () => {
      if (scrollRef) {
        scrollRef.removeEventListener('scroll', scrollHandler)
      }
      scrollHandler.cancel()
    }
  }, [scrollRef])

  useEffect(() => () => clearTimeout(unsetClickedRef.current), [])

  return {
    goToItem,
    goToNextItem,
    hasNextItem,
  }
}
