import { useDispatch, useSelector } from 'react-redux'
import { groupBy, pluck } from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import {
  MembershipSignUpFlow,
  WellnessPlanBenefit,
  WellnessPlanLevels,
} from '../../../constants/wellnessPlansConstants'
import { getCurrentClient } from '../../../store/duck/clients'
import {
  getFeatureToggle,
  getWellnessPlanType,
} from '../../../store/duck/constants'
import {
  getMembershipSelection,
  getMembershipToken,
  getWellnessPlansIsLoading,
  getWellnessPlansVersion,
  storeMembershipSignUpTokenSelection,
  updateActiveSignUpFlow,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'

export const getPlanIsOfLevel = (plan, level) => plan.level === level

export const getPlanByLevel = (level, plans = []) =>
  plans.find((plan) => getPlanIsOfLevel(plan, level))

export const getEnabledPlans = (wellnessPlanVersion) =>
  (wellnessPlanVersion?.plans || []).filter((plan) => {
    const isBaseLevel = plan.level === WellnessPlanLevels.BASE
    return !isBaseLevel || !wellnessPlanVersion.basePlanHidden
  })

export const getBenefitIsAccessToBoop = (benefit) =>
  benefit?.name === WellnessPlanBenefit.ACCESS_TO_MOBILE_APP

export const useGroupedWellnessPlans = () => {
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const names = {
    [ExtraTypeId]: 'extras',
    [PackageTypeId]: 'packages',
  }

  return (plans = []) =>
    groupBy((plan) => names[plan.planTypeId] || 'tiers', plans)
}

export const useConvertSelectionToPlans = () => {
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}
  const groupWellnessPlans = useGroupedWellnessPlans()
  const plans = wellnessPlansVersion?.plans || []
  const { packages = [], extras = [], tiers = [] } = groupWellnessPlans(plans)

  return (selection) => {
    const tiersSelection = selection.filter((item) =>
      Boolean(Utils.findById(item.planId, tiers)),
    )
    const extrasSelection = selection.filter((item) =>
      Boolean(Utils.findById(item.planId, extras)),
    )
    const packagesSelection = selection.filter((item) =>
      Boolean(Utils.findById(item.planId, packages)),
    )

    return {
      tiers: tiersSelection,
      extras: extrasSelection,
      packages: packagesSelection,
    }
  }
}

export const useTotalsPerPatient = (
  selection,
  memberships,
  includeOTF = false,
) => {
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}
  const isWpOneTimeFeeEnabled = useSelector(
    getFeatureToggle(Features.WP_ONE_TIME_FEE),
  )
  const convertSelectionToPlans = useConvertSelectionToPlans()

  const allPlans = wellnessPlansVersion?.plans || []
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)

  return memberships.reduce((acc, membership) => {
    const selectionsForPatient = selection.filter(
      (item) => item.patientId === membership.patient.id,
    )

    const {
      tiers: tiersSelection = [],
      extras: extrasSelection = [],
      packages: packagesSelection = [],
    } = convertSelectionToPlans(selectionsForPatient)

    const tierForPatientId = pluck('planId', tiersSelection)[0]
    const extrasForPatientIds = pluck('planId', extrasSelection)
    const packagesForPatientIds = pluck('planId', packagesSelection)

    const isBaseTier = tierForPatientId === baseLevelPlan.id

    const plansForPatient = tierForPatientId
      ? [tierForPatientId, ...packagesForPatientIds, ...extrasForPatientIds]
      : [...packagesForPatientIds, ...extrasForPatientIds]

    const plansForPatientWithBase =
      plansForPatient.length > 0 && !isBaseTier && !membership.member
        ? [...plansForPatient, baseLevelPlan.id]
        : plansForPatient

    // adds OTF only for new members with new membership selections
    const initTypePriceObj =
      isWpOneTimeFeeEnabled &&
      includeOTF &&
      !membership.member &&
      plansForPatientWithBase.length > 0
        ? { OTF: wellnessPlansVersion?.oneTimeFeeAmount || 0 }
        : {}

    return {
      ...acc,
      [membership.patient.id]: plansForPatientWithBase.reduce((acc, planId) => {
        const plan = Utils.findById(planId, allPlans)
        const planSelection = selection.find((item) => item.planId === planId)
        const planPrice = Utils.findByProp(
          planSelection?.priceTypeId,
          plan.prices,
          'priceTypeId',
        )
        const price = planPrice?.price || plan.price
        const priceTypeId = planSelection?.priceTypeId || plan.priceTypeId

        return {
          ...acc,
          [priceTypeId]: (acc[priceTypeId] || 0) + price,
        }
      }, initTypePriceObj),
    }
  }, {})
}

export const useHandleSendSignUpLink = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const token = useSelector(getMembershipToken)
  const selection = useSelector(getMembershipSelection)
  const client = useSelector(getCurrentClient)

  const onSelectionStoredForHybridFlow = () => {
    if (client?.isBoopUser) {
      window?.parent?.postMessage(
        { type: 'HANDLE_SEND_HYBRID_SIGN_UP_LINK' },
        '*',
      )
    } else {
      navigateWithQueryString({ url: '/membership/email-verification' })
    }
  }

  const setCallbackWhenSelectionStoredOn = useCallbackWhenLoaded(
    onSelectionStoredForHybridFlow,
    getWellnessPlansIsLoading,
  )

  return () => {
    dispatch(updateActiveSignUpFlow(MembershipSignUpFlow.HYBRID))

    setCallbackWhenSelectionStoredOn()
    dispatch(storeMembershipSignUpTokenSelection(token, selection))
  }
}
