import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { getMembershipIsPortalEmbed } from '../../../store/duck/wellnessPlans'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 2),
      overflowY: 'auto',
      maxHeight: ({ isPortalEmbed }) =>
        `calc(var(--app-height) - ${isPortalEmbed ? 232 : 405}px)`,
      fontSize: '1.2rem',
    },
    text: {
      marginTop: theme.spacing(1),
      fontSize: 'inherit',
    },
    list: {
      fontWeight: 400,
      '& > li::marker': {
        fontWeight: 500,
      },
    },
    primaryList: {
      '& > li': {
        fontWeight: 500,
        '&:not(:first-of-type)': {
          marginTop: theme.spacing(1),
        },
      },
    },
    secondaryList: {
      padding: 0,
      listStyleType: 'lower-latin',
      '& > li': {
        marginTop: theme.spacing(1),
      },
    },
    tertiaryList: {
      listStyleType: 'lower-roman',
      '& > li': {
        marginTop: theme.spacing(1),
      },
    },
  }),
  { name: 'WellnessPlanTermsAndConditions' },
)

const WellnessPlanTermsAndConditions = ({ onScroll }) => {
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)

  const classes = useStyles({ isPortalEmbed })

  return (
    <Grid container className={classes.root} onScroll={onScroll}>
      <ol className={classNames(classes.list, classes.primaryList)}>
        <li>
          General Terms of Use
          <ol className={classNames(classes.list, classes.secondaryList)}>
            <li>
              This agreement concerns the Boop Wellness Plan (&quot;Plan&quot;),
              the person who pays for and manages the Plan (&quot;Client&quot;),
              the designated pet for whom services are provided under the Plan
              (&quot;Patient&quot;), and the veterinary hospital or clinic
              (&quot;Practice&quot;) where the Plan is in effect.
            </li>
            <li>
              Start date shall be the day the Client signs up online for the
              Plan.
            </li>
            <li>
              The Plan covers all the services listed on the itemized service
              page issued to Client and attached to this document (the
              &quot;Service Addendum&quot;).
              <ol className={classNames(classes.list, classes.tertiaryList)}>
                <li>
                  The Plan is not transferrable or assignable and applies only
                  to the Patient while owned by the Client.
                </li>
                <li>
                  Client may obtain service covered under the Plan for the
                  Patient only at the Practice named herein.
                </li>
                <li>
                  The Plan does not include any services not listed on the
                  Service Exhibit.
                </li>
                <li>
                  The Plan does not include any services provided by anyone
                  other than the Practice or any fees for services recommended
                  as a result of an illness or accidental injury, even if
                  Practice refers Patient.
                </li>
                <li>
                  The Plan is not insurance. Fees for services which are not
                  included will be determined between the parties in accordance
                  with the fee schedule in effect at the time and must be paid
                  at the time such services are rendered.
                </li>
                <li>
                  Services will at all times be provided by Practice in
                  accordance with the normal standards of veterinary care.
                  However, Practice can only provide services pursuant to
                  appointments which Client makes, and it is Client’s
                  responsibility to ensure that Client and the Patient visit
                  Practice for exams in accordance with Agreement. Practice can
                  also only provide services on the basis of the information
                  which Practice has about Patient, and therefore Client must
                  provide Practice with all relevant information concerning
                  Patient, including details of health and well‐being.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Payments
          <ol className={classNames(classes.list, classes.secondaryList)}>
            <li>
              Monthly subscription installments and the Plan’s annual plan
              enrollment fee (&quot;Plan Fees&quot;) will be billed directly to
              the Client’s credit card. Client is responsible for keeping their
              payment information up to date. If the Client’s payment
              information causes the Plan to be in a failed billing state,
              goods, and services available under the Plan will be suspended
              until the payment information is brought up to date and any
              payments due cured.
            </li>
            <li>
              The Plan Fees will be collected on Practice’s behalf by
              Rhapsody.Vet (Boop). Annual plan enrollment fee will be collected
              on the first day of the Plan’s initial term or at each renewal
              date. The monthly fee will be collected monthly; specifically, 30
              days after the start date and continuing every 30 days thereafter
              until such time as this Agreement expires or is terminated for any
              reason whatsoever.
            </li>
            <li>
              The monthly fees are inclusive of sales tax and all other relevant
              taxes. Practice reserves the right to increase the monthly
              payments, upon notice to Client, in order to take account of
              increases in sales tax rates.
            </li>
            <li>
              DEFAULT: If Client fails to pay any monthly installment by its due
              date, Practice will immediately suspend Plan services and products
              until Client reinstates the Plan by paying all monthly fees due
              and owing at the time of payment; provided, however, if any
              monthly installment is past due for 120 days or more, Practice
              will immediately cancel this Agreement and discontinue Plan
              services, declare all remaining monthly installments for the
              then-current Plan year to be immediately due and payable, and
              refer Client’s account to a third-party collection agency. If
              Provider permits Client to restart a Plan after cancellation under
              this section, a new plan annual enrollment fee will be required
              unless special arrangements are made between Client and Practice
              and agreed to in advance by Practice.
            </li>
          </ol>
        </li>
        <li>
          Term, renewal, and cancellation
          <Typography className={classes.text}>
            This agreement is effective on the Start Date listed in section 1.b
            above for an initial term of 12 months (&quot;Plan Term&quot;) and
            will automatically renew on the anniversary of the Start Date in
            each succeeding year for subsequent one-year periods until Client or
            Practice cancels this Agreement as described below.
          </Typography>
          <ol className={classNames(classes.list, classes.secondaryList)}>
            <li>
              This Agreement is designed to help Client spread veterinary costs
              in respect of the services over a 12‐month period by making
              monthly payments in advance.
            </li>
            <li>
              Because Client’s costs for services are spread over 12 months,
              this is a 12‐month Agreement and Client may not end Agreement or
              stop paying the monthly payments other than at the end of a 12‐
              month period. Client may not carry over any unused portion of
              benefits to the next Agreement year.
            </li>
            <li>
              CANCELLATION TERMS: Either Practice or Client may cancel this
              Agreement at any time, but there may be monies due upon
              cancellation as set forth above. In the event that cancellation of
              this Agreement results in monies due from Client, such monies
              shall be paid in full at the time of cancellation. To cancel this
              Agreement, Member must notify the Practice in writing or by phone.
            </li>
            <li>
              If Client performs all his/her obligations and Practice cancels
              this agreement, all the Plan fees Client has paid for the Plan’s
              current term year will be refunded in full, less Practice&apos;s
              standard charges for any services rendered prior to cancellation.
            </li>
            <li>
              If Client cancels at any time before services are rendered, the
              entire amount paid for the current year will be refunded, minus
              any membership fee charged. Refunds will not be made for any prior
              year plan.
            </li>
            <li>
              If Client cancels after any services have been rendered, for any
              reason (including but not limited to death or loss of pet or
              transfer of ownership), Practice shall be entitled to retain the
              entire amount of the membership fee and all monthly installments
              which have been paid and Client will be liable to pay Practice the
              remaining installments for the term year in effect. Such
              installments can be paid either monthly as they come due or as one
              payment in full.
            </li>
            <li>
              Anniversary Date fee adjustments: Practice reserves the right to
              adjust Plan’s monthly fee at any anniversary date or cease at any
              time to provide the Plan.
            </li>
          </ol>
        </li>
        <li>
          General provisions
          <ol className={classNames(classes.list, classes.secondaryList)}>
            <li>
              The terms of Agreement set out the entire agreement between Client
              and Practice.
            </li>
            <li>
              Client has read all details of Agreement before committing to its
              terms. Once Client signs Agreement, Client is bound by its terms.
            </li>
            <li>
              Although Practice reserves the right to transfer rights under this
              Agreement to any other suitable person provided that Practice
              gives notice to Client, this Agreement is personal to Client and
              the Patient. Client cannot transfer any benefits of this Agreement
              to any other person or transfer the Patient without prior written
              consent from the Practice.
            </li>
            <li>
              Notice: Member agrees, in order for Practice or its agents to
              service Client&apos;s account, including, but not limited to,
              providing medical reminders, product recalls, or to collect any
              amounts owed, Practice or its agents may contact Client by
              telephone, text messages and/or emails, at any telephone number or
              email address provided to Practice by Client. All notices given
              under the provisions of this Agreement must be in writing and
              shall be delivered via e‐mail or prepaid first-class mail.
            </li>
            <li>
              Governing Law: This Agreement and the rights of the parties
              hereunder shall be governed by and construed in accordance with
              the laws of the State of New York, exclusive of conflict or choice
              of law rules.
            </li>
            <li>
              Arbitration: Any and all claims, controversies, or disputes
              arising out of or related in any way to this Agreement shall be
              subject to binding arbitration pursuant to the Commercial
              Arbitration Rules of the American Arbitration Association
              (&quot;AAA&quot;) under the Federal Arbitration Act. This
              includes, without limitation, any claims, controversies, or
              disputes that would otherwise be subject to class actions. This
              Agreement is governed by the substantive laws of New York, without
              regard to New York choice of law rules. Judgment of the
              arbitration award may be entered in any court of competent
              jurisdiction.
            </li>
          </ol>
        </li>
      </ol>
    </Grid>
  )
}

export default WellnessPlanTermsAndConditions
