import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { NumberUtils, Utils } from '@pbt/pbt-ui-components'

import { getWellnessPlanPriceType } from '../../../store/duck/constants'

const WellnessPlanPriceLabel = ({ plan, wellnessPlansVersion, ...rest }) => {
  const { t } = useTranslation('Common')

  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const prices = plan?.prices || []

  return (
    <Typography variant="body2" {...rest}>
      {prices.map((price, index) => {
        const formattedPrice = NumberUtils.formatMoney(price.price)
        const formattedPriceType =
          Utils.findById(price.priceTypeId, WellnessPlanPriceType)
            ?.displayName || ''

        return (
          <React.Fragment key={price.priceTypeId}>
            {index > 0 && ` ${t('Common:OR')} `}
            {`${formattedPrice}/${formattedPriceType}`}
          </React.Fragment>
        )
      })}
    </Typography>
  )
}

export default WellnessPlanPriceLabel
