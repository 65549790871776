import React from 'react'
import { useSelector } from 'react-redux'
import { SpayedNeuteredField as SpayedNeuteredFieldComponent } from '@pbt/pbt-ui-components'

import { getGender } from '../../../store/duck/constants'

const SpayedNeuteredField = (props) => {
  const Gender = useSelector(getGender)

  return <SpayedNeuteredFieldComponent Gender={Gender} {...props} />
}

export default SpayedNeuteredField
