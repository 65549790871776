import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils } from '@pbt/pbt-ui-components'

import {
  fetchDocumentsList,
  getDocumentsIsLoading,
  getDocumentsList,
} from '../../../store/duck/documents'
import { getCurrentAppointment } from '../../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskScreen from '../KioskScreen'
import DocumentDialog from './DocumentDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      padding: theme.spacing(4, 5, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    text: {
      fontSize: '1.6rem',
    },
    stepperText: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    questionViewRoot: {
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    questionViewText: {
      color: theme.colors.primaryText,
      fontSize: '2.6rem',
      fontWeight: 500,
    },
    questionViewRadioLabel: {
      fontSize: '1.8rem',
    },
    documentItem: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
    documentNameText: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    signedText: {
      color: theme.colors.tabLabel,
      fontSize: '1rem',
    },
  }),
  { name: 'DocumentsScreen' },
)

const DocumentsScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const documentsList = useSelector(getDocumentsList)
  const isLoading = useSelector(getDocumentsIsLoading)
  const appointment = useSelector(getCurrentAppointment) || {}

  const [documentDialogOpen, setDocumentDialogOpen] = useState(false)
  const [documentToSign, setDocumentToSign] = useState()

  const appointmentId = appointment.id

  const onDocumentsLoaded = () => {
    if (documentsList.length === 0) {
      navigateWithQueryString({ url: '/check-in' })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onDocumentsLoaded,
    getDocumentsIsLoading,
  )

  useEffect(() => {
    callbackWhenLoaded()
    dispatch(fetchDocumentsList(appointmentId))
  }, [appointmentId])

  const openDocumentDialog = (document) => {
    setDocumentDialogOpen(true)
    setDocumentToSign(document)
  }

  const onProceed = () => navigateWithQueryString({ url: '/check-in' })

  const hasUnsignedDocuments =
    !isLoading &&
    documentsList.some((document) => !document.dateSigned && document.required)

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={hasUnsignedDocuments}
      proceedButtonLabel={t('Common:FINISH_ACTION')}
      proceedButtonLoading={isLoading}
      title={
        !isLoading && `${t('Common:PLESE_READ_AND_SIGN_THESE_DOCUMENTS')}:`
      }
      onProceed={onProceed}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.root}
        direction="column"
        justifyContent={isLoading ? 'center' : 'flex-start'}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          documentsList.map((document) => {
            const signedDate =
              document.dateSigned &&
              DateUtils.formatDateWithHours(document.dateSigned)
            const signedBy = document.signer

            return (
              <Grid
                container
                item
                alignItems="center"
                className={classes.documentItem}
                direction="column"
                key={document.id}
              >
                <Typography
                  className={classes.documentNameText}
                  variant="body2"
                  onClick={() => openDocumentDialog(document)}
                >
                  {document.name}
                </Typography>
                {document.dateSigned && (
                  <Typography className={classes.signedText}>
                    {signedBy
                      ? t('Common:SIGNED_DATE_BY_PERSON', {
                          signedDate,
                          signedBy,
                        })
                      : t('Common:SIGNED_DATE', { signedDate })}
                  </Typography>
                )}
              </Grid>
            )
          })
        )}
      </Grid>
      {documentDialogOpen && (
        <DocumentDialog
          document={documentToSign}
          open={documentDialogOpen}
          onClose={() => {
            setDocumentDialogOpen(false)
            setDocumentToSign()
          }}
        />
      )}
    </KioskScreen>
  )
}

export default DocumentsScreen
