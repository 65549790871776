import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Paper, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import colors from '@pbt/pbt-ui-components/src/constants/colors'

import { getNPSData } from '../../store/duck/nps'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'

type Grade = {
  color: string
  id: number
  message: string
  selectedUrl: string
  url: string
}

type IncompleteFeedbackProps = {
  readonly onGradeChange: (grade: number) => void
}

const useStyles = makeStyles(
  (theme) => ({
    image: {
      height: 80,
      width: 80,
      [theme.breakpoints.down('md')]: {
        height: 60,
        width: 60,
      },
    },
    selected: {
      backgroundColor: colors.SUCCESS,
    },
    notSelected: {
      backgroundColor: colors.GREY400,
    },
    wrapText: {
      textAlign: 'center',
      maxInlineSize: '45%',
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: 265,
        height: 56,
      },
    },
    buttonContainer: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
    },
  }),
  { name: 'IncompleteFeedback' },
)

const IncompleteFeedback = ({ onGradeChange }: IncompleteFeedbackProps) => {
  const { t } = useTranslation(['Common'])
  const theme = useTheme()

  const npsData = useSelector(getNPSData)

  const { feedbackBusinessInfo, score } = npsData || {}

  const GRADES: Record<number, Grade> = {
    1: {
      id: 1,
      url: 'https://cdn.rhapsody.vet/static/nps/nps-score-1.svg',
      selectedUrl: 'https://cdn.rhapsody.vet/static/nps/filled/nps-score-1.svg',
      message: t('Common:FEEDBACK.TERRIBLE_GRADE'),
      color: theme.colors.alertLabelError,
    },
    2: {
      id: 2,
      url: 'https://cdn.rhapsody.vet/static/nps/nps-score-2.svg',
      selectedUrl: 'https://cdn.rhapsody.vet/static/nps/filled/nps-score-2.svg',
      message: t('Common:FEEDBACK.VERY_BAD_GRADE'),
      color: theme.colors.important,
    },
    3: {
      id: 3,
      url: 'https://cdn.rhapsody.vet/static/nps/nps-score-3.svg',
      selectedUrl: 'https://cdn.rhapsody.vet/static/nps/filled/nps-score-3.svg',
      message: t('Common:FEEDBACK.BAD_GRADE'),
      color: theme.colors.alertWarning,
    },
    4: {
      id: 4,
      url: 'https://cdn.rhapsody.vet/static/nps/nps-score-4.svg',
      selectedUrl: 'https://cdn.rhapsody.vet/static/nps/filled/nps-score-4.svg',
      message: t('Common:FEEDBACK.GOOD_GRADE'),
      color: theme.colors.goodGrade,
    },
    5: {
      id: 5,
      url: 'https://cdn.rhapsody.vet/static/nps/nps-score-5.svg',
      selectedUrl: 'https://cdn.rhapsody.vet/static/nps/filled/nps-score-5.svg',
      message: t('Common:FEEDBACK.EXCELLENT_GRADE'),
      color: theme.colors.success,
    },
  }

  const [selectedGrade, setSelectedGrade] = useState(GRADES[Number(score) || 5])

  const classes = useStyles()

  return (
    <Grid
      container
      item
      md
      sm
      alignItems="center"
      component={Paper}
      direction="column"
      justifyContent="center"
    >
      <Grid item>
        <Grid container item justifyContent="center">
          {Object.values(GRADES).map(({ id, url, selectedUrl, color }) => (
            <div
              className={classes.image}
              key={id}
              style={{
                WebkitMask: `url(${
                  selectedGrade.id === id ? selectedUrl : url
                }) center / 80%  no-repeat`,
                mask: `url(${
                  selectedGrade.id === id ? selectedUrl : url
                }) no-repeat center / 80%  no-repeat`,
                WebkitMaskSize: '80%',
                maskSize: '80%',
                WebkitMaskPosition: 'center',
                maskPosition: 'center',
                WebkitMaskClip: 'padding',
                maskClip: 'padding',
                backgroundColor:
                  selectedGrade.id === id ? color : colors.GREY400,
              }}
              onClick={() => {
                const grade = R.prop(id, GRADES) as Grade
                setSelectedGrade(grade)
                const gradeId = R.prop('id', grade)
                onGradeChange(gradeId)
              }}
            />
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={4}
      >
        <Grid item>
          <Typography align="center" variant="h3">
            {t('Common:FEEDBACK.INCOMPLETE_FEEDBACK')}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" justifyContent="center">
          <div className={classes.wrapText}>{selectedGrade.message}</div>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          {feedbackBusinessInfo?.googleReviewLink && selectedGrade.id === 5 && (
            <KioskButtonWithLoader
              className={classes.button}
              color="primary"
              onClick={() =>
                window.open(feedbackBusinessInfo?.googleReviewLink, '_blank')
              }
            >
              {t('Common:FEEDBACK.GOOGLE_REVIEW_LINK')}
            </KioskButtonWithLoader>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IncompleteFeedback
