import { all, put, takeLatest } from 'redux-saga/effects'
import { Utils } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import requestAPI from '../sagas/requestAPI'

export const FETCH_DOCUMENTS_LIST = 'questions/FETCH_DOCUMENTS_LIST'
export const FETCH_DOCUMENTS_LIST_SUCCESS =
  'questions/FETCH_DOCUMENTS_LIST_SUCCESS'
export const FETCH_DOCUMENTS_LIST_FAILURE =
  'questions/FETCH_DOCUMENTS_LIST_FAILURE'

export const SIGN_DOCUMENT = 'questions/SIGN_DOCUMENT'
export const SIGN_DOCUMENT_SUCCESS = 'questions/SIGN_DOCUMENT_SUCCESS'
export const SIGN_DOCUMENT_FAILURE = 'questions/SIGN_DOCUMENT_FAILURE'

export const fetchDocumentsList = (appointmentId) => ({
  type: FETCH_DOCUMENTS_LIST,
  appointmentId,
})
export const fetchDocumentsListSuccess = (list) => ({
  type: FETCH_DOCUMENTS_LIST_SUCCESS,
  list,
})
export const fetchDocumentsListFailure = (error) => ({
  type: FETCH_DOCUMENTS_LIST_FAILURE,
  error,
})

export const signDocument = (document) => ({ type: SIGN_DOCUMENT, document })
export const signDocumentSuccess = (document) => ({
  type: SIGN_DOCUMENT_SUCCESS,
  document,
})
export const signDocumentFailure = (error) => ({
  type: SIGN_DOCUMENT_FAILURE,
  error,
})

const INITIAL_STATE = {
  list: [],
  error: null,
  isLoading: false,
}

export const documentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_LIST:
      return { ...state, isLoading: true }
    case FETCH_DOCUMENTS_LIST_SUCCESS:
      return { ...state, isLoading: false, list: action.list }
    case FETCH_DOCUMENTS_LIST_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case SIGN_DOCUMENT:
      return { ...state, isLoading: true }
    case SIGN_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: Utils.updateById(action.document, state.list),
      }
    case SIGN_DOCUMENT_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    default:
      return state
  }
}

export const getDocuments = (state) => state.documents
export const getDocumentsList = (state) => getDocuments(state).list
export const getDocumentsIsLoading = (state) => getDocuments(state).isLoading
export const getDocumentsError = (state) => getDocuments(state).error

export function* sagaFetchDocumentsList({ appointmentId }) {
  try {
    const list = yield* requestAPI(API.fetchDocumentsList, appointmentId)
    yield put(fetchDocumentsListSuccess(list))
  } catch (error) {
    yield put(fetchDocumentsListFailure(error))
  }
}

export function* sagaSignDocument({ document }) {
  try {
    const signDTO = {
      body: document.file.body,
      signer: document.signer,
      sign: true,
    }
    const result = yield* requestAPI(API.signDocument, document.id, signDTO)
    yield put(signDocumentSuccess(result))
  } catch (error) {
    yield put(signDocumentFailure(error))
  }
}

function* watchFetchDocumentsList() {
  yield takeLatest(FETCH_DOCUMENTS_LIST, sagaFetchDocumentsList)
}

function* watchSignDocument() {
  yield takeLatest(SIGN_DOCUMENT, sagaSignDocument)
}

export function* documentsSaga() {
  yield all([watchFetchDocumentsList(), watchSignDocument()])
}
