import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Slide } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, SignButton } from '@pbt/pbt-ui-components'

import {
  getDocumentsIsLoading,
  signDocument,
} from '../../../store/duck/documents'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import DocumentPreview from './DocumentPreview'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      minHeight: 73,
      paddingRight: theme.spacing(3),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dialogContentRoot: {
      position: 'relative',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    button: {
      borderRadius: 28,
      width: 156,
      height: 56,
    },
  }),
  { name: 'DocumentDialog' },
)

// eslint-disable-next-line react/no-multi-comp
const DocumentDialog = ({ open, document, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getDocumentsIsLoading)

  const [documentCandidate, setDocumentCandidate] = useState(document)
  const [iframeDocument, setIframeDocument] = useState()

  const setCallbackWhenSigned = useCallbackWhenLoaded(
    onClose,
    getDocumentsIsLoading,
  )

  useEffect(() => {
    setDocumentCandidate(document)
  }, [document])

  const onIframeLoad = (event) => {
    const document = event?.target?.contentDocument
    setIframeDocument(document)
  }

  const onDocumentSign = (document) => {
    setCallbackWhenSigned()
    dispatch(signDocument(document))
  }

  return (
    <PuiDialog
      fullScreen
      TransitionComponent={Transition}
      actions={
        <SignButton
          className={classes.button}
          document={documentCandidate}
          iframeDocument={iframeDocument}
          isLoading={isLoading}
          onSigned={onDocumentSign}
        />
      }
      aria-labelledby="document-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        actions: classes.actions,
      }}
      open={open}
      scroll="paper"
      title={document.name}
      onClose={onClose}
    >
      <DocumentPreview
        document={documentCandidate}
        onIframeLoad={onIframeLoad}
      />
    </PuiDialog>
  )
}

export default DocumentDialog
