import i18nKiosk from '../locales/i18n'

export enum WellnessPlanLevels {
  BASE = 0,
  PLAN_ONE = 1,
  PLAN_TWO = 2,
}

export const WellnessPlanLevelsLabels = {
  [WellnessPlanLevels.BASE]: i18nKiosk.t('Membership:PLAN_LEVEL.BASE'),
  [WellnessPlanLevels.PLAN_ONE]: i18nKiosk.t('Membership:PLAN_LEVEL.PLAN_ONE'),
  [WellnessPlanLevels.PLAN_TWO]: i18nKiosk.t('Membership:PLAN_LEVEL.PLAN_TWO'),
}

export enum MembershipStatus {
  COMPLETED = 'COMPLETED',
  CONFIRMING = 'CONFIRMING',
}

export enum MembershipSignUpFlow {
  HYBRID = 'hybrid',
  MANUAL = 'manual',
}

export enum WellnessPlanBenefit {
  OFFICE_VISITS = 'Office visits',
  TELEHEALTH = 'Telehealth',
  LOYALTY_REWARDS = 'Loyalty rewards',
  ACCESS_TO_MOBILE_APP = 'Access to mobile app',
}
