import ClientNotFoundScreen from '../ClientNotFoundScreen'
import ErrorScreen from '../ErrorScreen'
import MembershipBillingAddressScreen from './MembershipBillingAddressScreen'
import MembershipBrowsePlansScreen from './MembershipBrowsePlansScreen'
import MembershipEmailScreen from './MembershipEmailScreen'
import MembershipEmailVerificationScreen from './MembershipEmailVerificationScreen'
import MembershipPatientDetailsScreen from './MembershipPatientDetailsScreen'
import MembershipPaymentMethodScreen from './MembershipPaymentMethodScreen'
import MembershipPhoneNumberScreen from './MembershipPhoneNumberScreen'
import MembershipPlanOverviewScreen from './MembershipPlanOverviewScreen'
import MembershipSignUpErrorScreen from './MembershipSignUpErrorScreen'
import MembershipSuccessScreen from './MembershipSuccessScreen'
import MembershipTermsAndConditionsScreen from './MembershipTermsAndConditionsScreen'
import MembershipPlanDetailsScreen from './plan-details/MembershipPlanDetailsScreen'
import { MembershipSignUpFailScreen } from './sign-up/MembershipSignUpFailScreen'
import MembershipSignUpScreen from './sign-up/MembershipSignUpScreen'
import MembershipPlanSummaryScreen from './summary/MembershipPlanSummaryScreen'

/* root path: /membership */

export default [
  { Component: MembershipSignUpScreen, path: 'sign-up' },
  { Component: MembershipPlanSummaryScreen, path: 'plan-summary' },
  { Component: MembershipBillingAddressScreen, path: 'billing-address' },
  { Component: MembershipPaymentMethodScreen, path: 'payment-method' },
  { Component: MembershipSuccessScreen, path: 'success' },
  { Component: MembershipSignUpFailScreen, path: 'sign-up-failure' },
  { Component: MembershipPatientDetailsScreen, path: 'patient-details' },
  {
    Component: MembershipTermsAndConditionsScreen,
    path: 'terms-and-conditions',
  },
  { Component: MembershipSignUpErrorScreen, path: 'sign-up-error' },
  {
    Component: MembershipBrowsePlansScreen,
    path: 'browse-plans',
    isRoot: true,
  },
  {
    Component: MembershipPlanOverviewScreen,
    path: 'plan-overview',
    isRoot: true,
  },
  {
    Component: MembershipPlanDetailsScreen,
    path: 'plan-details',
    isRoot: true,
  },
  { Component: MembershipPhoneNumberScreen, path: 'phone', isRoot: true },
  { Component: MembershipEmailScreen, path: 'email', isRoot: true },
  { Component: MembershipEmailVerificationScreen, path: 'email-verification' },
  { Component: ClientNotFoundScreen, path: 'not-found', isRoot: true },
  { Component: ErrorScreen, path: 'error', isRoot: true },
  { Component: MembershipBrowsePlansScreen, path: '/*', isRoot: true },
]
