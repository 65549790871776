import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import { MembershipStatus } from '../../../constants/wellnessPlansConstants'
import { getFeatureToggle } from '../../../store/duck/constants'
import {
  checkMembershipSubscriptionStatus,
  confirmFreeMembershipSignUp,
  getMembershipIsFreeSignUp,
  getMembershipIsPortalEmbed,
  getMembershipSelection,
  getMembershipSubscriptionStatus,
  getMembershipToken,
  getWellnessPlansIsLoading,
  // @ts-ignore
} from '../../../store/duck/wellnessPlans'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'

const useHandleFreeSignUp = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()

  const membershipSubscriptionStatus: { state: MembershipStatus } | Nil =
    useSelector(getMembershipSubscriptionStatus)
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const token = useSelector(getMembershipToken)
  const isFreeSignUp = useSelector(getMembershipIsFreeSignUp)
  const selection = useSelector(getMembershipSelection)
  const isWelcomeEmailForOmnichannelEnabled = useSelector(
    getFeatureToggle(Features.WELCOME_EMAIL_FOR_OMNICHANNEL),
  )

  const [isLoading, setIsLoading] = useState(false)
  const [pollingInterval, setPollingInterval] = useState<number>()

  const isCompleted =
    membershipSubscriptionStatus?.state === MembershipStatus.COMPLETED

  useEffect(
    () => () => {
      clearInterval(pollingInterval)
    },
    [],
  )

  useEffect(() => {
    if (isCompleted) {
      setIsLoading(false)
      clearInterval(pollingInterval)
      if (isPortalEmbed) {
        window?.parent?.postMessage(
          {
            type: 'MEMBERSHIP_SIGN_UP_SUCCESS',
            selection: isWelcomeEmailForOmnichannelEnabled
              ? selection
              : undefined,
          },
          '*',
        )
      } else {
        navigateWithQueryString({ url: '/membership/success' })
      }
    }
  }, [membershipSubscriptionStatus?.state])

  const onConfirmed = () => {
    if (!isCompleted) {
      setPollingInterval(
        window.setInterval(
          () => dispatch(checkMembershipSubscriptionStatus(token)),
          Defaults.DEBOUNCE_ACTION_TIME,
        ),
      )
    }
  }

  const setCallbackWhenConfirmedOn = useCallbackWhenLoaded(
    onConfirmed,
    getWellnessPlansIsLoading,
  )

  const handler = () => {
    if (isFreeSignUp) {
      setIsLoading(true)
      setCallbackWhenConfirmedOn()
      dispatch(confirmFreeMembershipSignUp(token))
    } else {
      navigateWithQueryString({ url: '/membership/billing-address' })
    }
  }

  return {
    handler,
    isLoading,
  }
}

export default useHandleFreeSignUp
