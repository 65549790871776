import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFields } from '@pbt/pbt-ui-components'

import { getCurrentClient, getIsCreatingClient } from '../../store/duck/clients'
import { getIsJoinWaitlistFlow } from '../../store/duck/flow'
import {
  createPatient,
  editPatient,
  getCurrentPatient,
  getPatientsIsLoading,
} from '../../store/duck/patients'
import { getCurrentAppointment } from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(4),
    },
    button: {
      width: 104,
      maxWidth: 104,
      height: 40,
      textTransform: 'none',
      boxShadow: 'none',
      '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2),
      },
      border: theme.constants.inactiveBorder,
    },
    secondary: {},
  }),
  { name: 'PatientMicrochippedScreen' },
)

const PatientMicrochippedScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const patient = useSelector(getCurrentPatient) || {}
  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getPatientsIsLoading)
  const isCreateClient = useSelector(getIsCreatingClient)
  const appointment = useSelector(getCurrentAppointment)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)

  const {
    fields: { microchipped },
    validate,
  } = useFields([
    {
      name: 'microchipped',
      type: 'toggle',
      initialValue: patient.microchipped,
    },
  ])

  const onPatientReady = () => {
    if (!isWaitlist && (isCreateClient || !appointment)) {
      if (appointment?.type) {
        navigateWithQueryString({ url: '/primary-complaint' })
      } else {
        navigateWithQueryString({ url: '/appointment-type' })
      }
    } else {
      navigateWithQueryString({ url: '/any-more-pets' })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onPatientReady,
    getPatientsIsLoading,
  )

  const onProceed = () => {
    if (validate()) {
      const newPatient = {
        ...patient,
        microchipped: microchipped.value,
      }

      if (newPatient.id) {
        callbackWhenLoaded()
        dispatch(editPatient(client.id, newPatient))
      } else {
        callbackWhenLoaded()
        dispatch(createPatient(client.id, newPatient))
      }
    }
  }

  const isMicrochipped = microchipped.value === true
  const isNotMicrochipped = microchipped.value === false

  const setMicrochipped = (value) => {
    microchipped.setValue(microchipped.value === value ? null : value)
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonLoading={isLoading}
      title={t('Common:IS_PATIENT_MICROCHIPPED', {
        patientName: patient.name || '',
      })}
      onProceed={onProceed}
    >
      <Grid
        container
        item
        className={classes.root}
        justifyContent="center"
        spacing={3}
        wrap="nowrap"
      >
        <KioskButtonWithLoader
          classes={{
            button: classes.button,
            secondary: classes.secondary,
          }}
          color={isMicrochipped ? 'primary' : 'secondary'}
          onClick={() => setMicrochipped(true)}
        >
          {t('Common:YES')}
        </KioskButtonWithLoader>
        <KioskButtonWithLoader
          classes={{
            button: classes.button,
            secondary: classes.secondary,
          }}
          color={isNotMicrochipped ? 'primary' : 'secondary'}
          onClick={() => setMicrochipped(false)}
        >
          {t('Common:NO')}
        </KioskButtonWithLoader>
      </Grid>
    </KioskScreen>
  )
}

export default PatientMicrochippedScreen
