import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import { getCurrentBusinessId } from '../../../store/duck/businesses'

const useStyles = makeStyles(
  (theme) => ({
    iframe: {
      position: 'absolute', // hey safari
      top: 0,
      left: theme.spacing(1.5),
      right: theme.spacing(1.5),
      height: '100%',
      width: `calc(100% - ${theme.spacing(3)})`,
    },
  }),
  { name: 'DocumentPreview' },
)

const DocumentPreview = ({ document, onIframeLoad }) => {
  const classes = useStyles()
  const businessId = useSelector(getCurrentBusinessId)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (document.fileUrl) {
      setIsLoading(true)
    }
  }, [document.fileUrl])

  const iframeRef = useRef()

  const updateIframeContent = () => {
    const body = iframeRef.current?.contentDocument?.body
    if (!body) {
      return
    }
    if (document && !document.fileUrl) {
      body.innerHTML = document.body
    }
  }

  const onLoad = (event) => {
    setIsLoading(false)
    updateIframeContent()
    onIframeLoad(event)
  }

  return (
    <>
      <CircularProgressOverlay open={isLoading} />
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe
        className={classes.iframe}
        frameBorder="0"
        height="100%"
        ref={iframeRef}
        src={
          document.fileUrl
            ? `${document.fileUrl}?businessId=${businessId}`
            : 'about:blank'
        }
        title="preview-iframe"
        width="100%"
        onLoad={onLoad}
      />
    </>
  )
}

export default DocumentPreview
