import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

// @ts-ignore
import reducer from './reducers'
// @ts-ignore
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer,
  preloadedState: undefined,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['error'],
      },
    }),
    sagaMiddleware,
  ],
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
