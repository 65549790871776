import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

export default class KioskMomentUtils extends AdapterMoment {
  constructor(opts) {
    super(opts)

    const parentFormat = this.format

    this.format = (date, formatKey) => {
      const result = parentFormat(date, formatKey)

      // TODO: remove this once material UI adds support for overriding calendar header label styles
      if (formatKey === 'monthAndYear') {
        return (
          <span
            style={{
              marginLeft: 90,
            }}
          >
            {result}
          </span>
        )
      }

      return result
    }
  }
}
