import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Business, HtmlNotesPreview } from '@pbt/pbt-ui-components'

// @ts-ignore
import Flows from '../../../constants/flows'
import {
  fetchAppointmentTypesList,
  getAppointmentReasons,
  getAppointmentTypesIsLoading,
} from '../../../store/duck/appointmentTypes'
import { getCurrentBusiness } from '../../../store/duck/businesses'
// @ts-ignore
import { updateCurrentFlow } from '../../../store/duck/flow'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
// @ts-ignore
import JoinWaitlistButton from '../../buttons/JoinWaitlistButton'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
// @ts-ignore
import KioskMembershipSignUpButton from '../../buttons/KioskMembershipSignUpButton'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(2),
    },
    button: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(5),
      },
    },
  }),
  { name: 'EmbedWelcomeScreen' },
)

const getCss = (fontSize: string, color: string) => `
  div {
    padding: 0 8px 8px;
    font-size: ${fontSize};
    color: ${color};
  }
  p {
    color: ${color};
  }
`

const getFontSizeForWelcomeMessage = (html: string) => {
  const parser = new DOMParser()
  const htmlDoc = parser.parseFromString(html, 'text/html')

  // The small text should be used in case list or br tags are used, or if we have more than 1 text tag
  const containsListTag = htmlDoc.querySelector('li') !== null
  const checkboxElements = htmlDoc.querySelectorAll('input[type="checkbox"]')
  const containsCheckboxesList = Boolean(checkboxElements.length)
  const containsList = containsListTag || containsCheckboxesList

  const containsNewLine = htmlDoc.querySelector('br') !== null
  const textElements = htmlDoc.querySelectorAll('p, h1, h2, h3, h4, h5, h6')
  const containsMoreThanOneTextElement = textElements.length > 1

  const useSmallSize =
    containsList || containsNewLine || containsMoreThanOneTextElement

  return useSmallSize ? '1.6rem' : '2.6rem'
}

const EmbedWelcomeScreen = () => {
  const business = useSelector(getCurrentBusiness) || ({} as Business)
  const classes = useStyles({ backgroundColor: business.backgroundColor })
  const theme = useTheme()

  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const appointmentReasons = useSelector(getAppointmentReasons)
  const isLoading = useSelector(getAppointmentTypesIsLoading)

  useEffect(() => {
    dispatch(fetchAppointmentTypesList({ from: 0, to: 200 }))
  }, [])
  const KioskAppointmentReasons = appointmentReasons.filter(
    (reason) => reason.name,
  )

  const proceed = () => {
    navigateWithQueryString({ url: '/client-type-selection' })
  }

  const schedule = () => {
    dispatch(updateCurrentFlow(Flows.SCHEDULE_APPOINTMENT))
    proceed()
  }

  const scheduleTelehealth = () => {
    dispatch(updateCurrentFlow(Flows.SCHEDULE_APPOINTMENT))
    navigateWithQueryString({ url: '/telemedicine' })
  }

  const joinWaitlist = () => {
    dispatch(updateCurrentFlow(Flows.JOIN_WAITLIST))
    proceed()
  }

  const fontSize = getFontSizeForWelcomeMessage(
    business.kioskLandingPageMessage!,
  )
  const css = getCss(fontSize, theme.colors.primaryText)

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      title={t('Common:WELCOME_WHAT_WOULD_YOU_LIKE_TO_DO')}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {business.kioskLandingPageMessageEnabled && (
            <Grid pb={2} px={2}>
              <HtmlNotesPreview
                css={css}
                fontSize={fontSize}
                notes={business.kioskLandingPageMessage}
                strong={false}
                variant="hero"
              />
            </Grid>
          )}
          {KioskAppointmentReasons.length > 0 && (
            <KioskButtonWithLoader
              className={classes.button}
              onClick={schedule}
            >
              {t('Common:SCHEDULE_APPOINTMENT')}
            </KioskButtonWithLoader>
          )}
          {business.telemedicineEnabled && (
            <KioskButtonWithLoader
              className={classes.button}
              onClick={scheduleTelehealth}
            >
              {t('Common:SCHEDULE_TELEHEALTH_APPOINTMENT')}
            </KioskButtonWithLoader>
          )}
          <JoinWaitlistButton
            className={classes.button}
            onClick={joinWaitlist}
          />
          {business.wellnessPlansInSchedulingEnabled && (
            <KioskMembershipSignUpButton className={classes.button} />
          )}
        </>
      )}
    </KioskScreen>
  )
}

export default EmbedWelcomeScreen
