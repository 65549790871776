import React from 'react'
import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from '@pbt/pbt-ui-components'

// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
import KioskScreen from '../KioskScreen'

const MOCK_FORM_DATA = [
  {
    id: 1,
    name: 'MEDICAL TREATMENT CONSENT FORM',
    completed: true,
  },
]

const StyledContainer = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(4)};
`

// Bypass our own Text / Typography components for now
// TODO: Make our internal components more flexible and compatible with styled components
const StyledDoneIcon = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.uiSuccess};
  background-color: ${({ theme }) => theme.colors.shadesSuccess};
  padding: 0 ${({ theme }) => theme.spacing()};
`

/**
 * This screen is not currently accessible from any flow
 * For now it serves to display mock data while the BE is being implemented
 */
export function ConsentFormThankYouScreen() {
  const theme = useTheme()
  const navigateWithQueryString = useNavigateWithQueryString()

  return (
    <KioskScreen
      confirmButtonProps={{
        style: { background: `${theme.colors.brandPrimary}` },
      }}
      justifyContent="flex-start"
      onProceed={() => {
        navigateWithQueryString('/')
      }}
    >
      <StyledContainer spacing={2}>
        {/* TODO check pluralization with designer */}
        <Grid item xs={12}>
          <Text variant="h2">Thank you for completing your form</Text>
        </Grid>
        {MOCK_FORM_DATA.filter((form) => form.completed).map((form) => (
          <Grid container item key={form.id}>
            <Grid item xs={6}>
              <Text>{form.name}</Text>
            </Grid>
            <Grid item>
              <StyledDoneIcon>Done</StyledDoneIcon>
            </Grid>
          </Grid>
        ))}
      </StyledContainer>
    </KioskScreen>
  )
}
