import React, { forwardRef } from 'react'
import { PatternFormat } from 'react-number-format'
import { Nil } from '@pbt/pbt-ui-components'

export interface PhoneNumberInputUSProps {
  readonly onChange: (value: object) => void
  readonly value: string | number | Nil
}
const PhoneNumberInputUS = forwardRef(function PhoneNumberInputUS(
  { onChange, value, ...rest }: PhoneNumberInputUSProps,
  ref,
) {
  return (
    <PatternFormat
      {...rest}
      valueIsNumericString
      format="(###) ###-####"
      getInputRef={ref}
      mask="_"
      placeholder="(___) ___-____"
      value={String(value)?.replace(/\D/g, '')}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
    />
  )
})

export default PhoneNumberInputUS
