import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(2),
      position: 'relative',
    },
    iconButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: 2,
      '&:hover': {
        color: theme.colors.iconHover,
      },
    },
  }),
  { name: 'BottomSheet' },
)

const BottomSheet = ({ open, onChange, children }) => {
  const classes = useStyles()

  const handleClose = () => {
    onChange(false)
  }

  return (
    <Drawer anchor="bottom" open={open} onClose={handleClose}>
      <Grid className={classes.root}>
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Grid>
    </Drawer>
  )
}

export default BottomSheet
