import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

// @ts-ignore
import * as API from '../../api'
import {
  fetchAppointmentTypesList,
  fetchAppointmentTypesListFailure,
  fetchAppointmentTypesListSuccess,
} from '../duck/appointmentTypes'
// @ts-ignore
import requestAPI from './requestAPI'

function* fetchAppointmentTypesListSaga({
  payload,
}: ReturnType<typeof fetchAppointmentTypesList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchAppointmentTypes, payload.from, payload.to)
    yield put(
      fetchAppointmentTypesListSuccess({
        list,
        map: entities.appointmentTypes,
        totalCount,
      }),
    )
  } catch (error) {
    yield put(fetchAppointmentTypesListFailure(error as ApiError))
  }
}

function* watchFetchAppointmentTypesList() {
  yield takeLatest(
    fetchAppointmentTypesList.type,
    fetchAppointmentTypesListSaga,
  )
}

export function* appointmentTypesSaga() {
  yield all([watchFetchAppointmentTypesList()])
}
