import { Communications } from '@pbt/pbt-ui-components'

import { SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL } from './schedules'
import { SEND_WELCOME_EMAIL } from './wellnessPlans'

const INITIAL_STATE = {
  lastSentMessageTransport: null,
}

export const communicationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_WELCOME_EMAIL:
      return {
        ...state,
        lastSentMessageTransport: Communications.MessageTransport.EMAIL,
      }
    case SEND_NEW_APPOINTMENT_CONFIRMATION_EMAIL:
      return { ...state, lastSentMessageTransport: action.message?.transport }
    default:
      return state
  }
}

export const getCommunications = (state) => state.communications
export const getCommunicationsLastSentMessageTransport = (state) =>
  getCommunications(state).lastSentMessageTransport
