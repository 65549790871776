import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTextField, Text, useFields } from '@pbt/pbt-ui-components'
import {
  KioskBackgroundCustom,
  Rhapsody,
} from '@pbt/pbt-ui-components/src/icons'
import { toExternalUrl } from '@pbt/pbt-ui-components/src/utils'
import { isWebsiteValid } from '@pbt/pbt-ui-components/src/utils/validation'

import KioskButtonWithLoader from '../buttons/KioskButtonWithLoader'

const useStyles = makeStyles(
  (theme) => ({
    input: {
      maxWidth: 382,
    },
    button: {
      maxWidth: 210,
    },
    link: {
      cursor: 'pointer',
    },
    faq: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.colors.link,
    },
    logo: {
      width: 120,
      height: 40,
      zIndex: 1,
    },
    background: {
      transform: 'rotate(180deg)',
      position: 'absolute',
      zIndex: 0,
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100%',
      minWidth: 1000,
    },
    logoContainer: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
  }),
  { name: 'LocationSelectorScreen' },
)

const LocationSelectorScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const [helpOpened, setHelpOpened] = useState(false)
  const navigate = useNavigate()

  const validateTrimmedUrl = ({ state }) => isWebsiteValid(state.url.trim())

  const {
    fields: { url },
    validate,
  } = useFields(
    [
      {
        name: 'url',
        label: t('Common:ENTER_YOUR_KIOSK_URL'),
        initialValue: '',
        validators: [
          { validator: validateTrimmedUrl, validatorName: 'website' },
        ],
      },
    ],
    false,
  )

  useEffect(() => {
    const selectedLocationUrl = localStorage.getItem('selectedLocation')
    if (selectedLocationUrl) {
      navigate(selectedLocationUrl)
    }
  }, [])

  const onFindPracticeClick = () => {
    if (!validate()) {
      return
    }
    try {
      const urlObject = new URL(toExternalUrl(url.value.trim()))
      if (urlObject) {
        const pathname = urlObject.pathname || '/'
        const search = urlObject.search || ''
        const selectedLocationUrl = `${pathname}${search}`
        localStorage.setItem('selectedLocation', selectedLocationUrl)
        navigate(selectedLocationUrl)
      }
    } catch (error) {
      // error
    }
  }

  const onHelpClick = () => {
    setHelpOpened(!helpOpened)
  }

  const onFaqClick = () => {
    window.open(
      'https://help.rhapsody.vet/en/articles/6310260-how-do-i-use-the-rhapsody-kiosk-on-an-android-device',
      '_blank',
    )
  }

  return (
    <Grid container alignItems="center" direction="column">
      <Grid
        item
        className={classes.logoContainer}
        mb={5}
        pt={3}
        sx={{ pb: { lg: 12, md: 8, xs: 5 } }}
      >
        <KioskBackgroundCustom className={classes.background} fill="#F6F0EB" />
        <Grid container justifyContent="center">
          <Rhapsody className={classes.logo} />
        </Grid>
      </Grid>
      <Text strong variant="subheading2">
        {t('Common:LETS_FIND_YOUR_PRACTICE')}
      </Text>
      <Grid container item justifyContent="center" p={2}>
        <PuiTextField className={classes.input} field={url} label={url.label} />
      </Grid>
      <KioskButtonWithLoader
        className={classes.button}
        disabled={!url.value}
        onClick={onFindPracticeClick}
      >
        {t('Common:OPEN_KIOSK_ACTION')}
      </KioskButtonWithLoader>

      <Text
        underline
        className={classes.link}
        mt={3}
        variant="lowAccent2"
        onClick={onHelpClick}
      >
        {t('Common:WHERE_CAN_I_FIND_THIS')}
      </Text>
      <Collapse in={helpOpened}>
        <Grid container p={6}>
          <Text textAlign="center" variant="lowAccent2">
            {t('Common:SCHEDULER_INSTRUCTIONS')}
            <br /> <br />
            {t('Common:IF_YOU_NEED_HELP')}{' '}
            <span className={classes.faq} onClick={onFaqClick}>
              {t('Common:FAQ')}
            </span>
          </Text>
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default LocationSelectorScreen
