import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import { BillingAddressForm } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getCurrentClient } from '../../../store/duck/clients'

const BillingAddressScreen = forwardRef(function BillingAddressScreen(
  { className, billingAddresses, currentBillingAddress, formClasses },
  ref,
) {
  const client = useSelector(getCurrentClient)
  const business = useSelector(getCurrentBusiness)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Grid
      container
      item
      className={className}
      direction="column"
      spacing={isMobile ? 1 : 2}
    >
      <BillingAddressForm
        billingAddresses={billingAddresses}
        business={business}
        classes={formClasses}
        client={client}
        currentBillingAddress={currentBillingAddress}
        ref={ref}
      />
    </Grid>
  )
})

export default BillingAddressScreen
