'use client'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import makeStyles from '@mui/styles/makeStyles'
import braintree, { HostedFields } from 'braintree-web'

import {
  createBraintreeMembershipPayment,
  getBraintreeToken,
} from '../../../../store/duck/braintree'
import {
  // @ts-ignore
  getMembershipBillingAddress,
  getMembershipToken,
} from '../../../../store/duck/wellnessPlans'
import { BraintreePaymentData } from '../../../../types/entities/memberships'
import { BraintreeField } from '../../../inputs/BraintreeField/BraintreeField'

import '@chewy/kib-layout-styles/dist/styles/kib-grid.css'

interface BraintreePaymentFormProps {
  readonly setIsLoading: (value: React.SetStateAction<boolean>) => void
}

const useStyles = makeStyles(
  () => ({
    container: {
      width: '60%',
    },
  }),
  { name: 'BraintreePaymentForm' },
)

export const BraintreePaymentForm = React.forwardRef<
  HTMLFormElement,
  BraintreePaymentFormProps
>((props: BraintreePaymentFormProps, ref) => {
  const { setIsLoading } = props
  const dispatch = useDispatch()
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null)
  const [cardholderNameError, setCardholderNameError] = useState(false)
  const [cardNumberError, setCardNumberError] = useState(false)
  const [cvvError, setCvvError] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)

  const braintreeToken = useSelector(getBraintreeToken)
  const membershipToken = useSelector(getMembershipToken)
  const classes = useStyles()
  const { t } = useTranslation('Payment')

  useEffect(() => {
    async function initializeBraintree() {
      try {
        const clientInstance = await braintree.client.create({
          authorization: braintreeToken,
        })

        const braintreeFields = await braintree.hostedFields.create({
          fields: {
            cardholderName: {
              container: '#cardholder_name',
            },
            number: {
              container: '#card_number',
              maskInput: true,
            },
            expirationDate: {
              container: '#expiration_date',
              placeholder: 'MM/YY',
            },
            cvv: {
              container: '#cvv',
              maskInput: true,
            },
          },
          styles: {
            input: {
              'padding-left': '1.2rem',
              'font-size': '1rem',
              'line-height': '1.25rem',
            },
          },
          client: clientInstance,
        })
        setHostedFields(braintreeFields)
      } catch (err) {
        // TODO: Handle errors
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    initializeBraintree()
  }, [braintreeToken])

  const billingAddress = useSelector(getMembershipBillingAddress) || {}
  const validateBraintreeState = () => {
    if (hostedFields) {
      const {
        fields: { cardholderName, number: cardNumber, cvv, expirationDate },
      } = hostedFields.getState()
      setCardholderNameError(!cardholderName.isValid)
      setCardNumberError(!cardNumber.isValid)
      setCvvError(!cvv.isValid)
      setExpirationDateError(!expirationDate.isValid)
      return (
        cardholderName.isValid &&
        cardNumber.isValid &&
        cvv.isValid &&
        expirationDate.isValid
      )
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isValidBraintreeState = validateBraintreeState()
    if (!isValidBraintreeState) {
      setIsLoading(false)
    }

    if (hostedFields && isValidBraintreeState) {
      const braintreeData = await hostedFields.tokenize()
      const braintreePaymentData: BraintreePaymentData = {
        brandCode: braintreeData.details.cardType,
        cardBin: braintreeData.details.bin,
        cardLastFour: braintreeData.details.lastFour,
        expirationMonth: braintreeData.details.expirationMonth,
        expirationYear: braintreeData.details.expirationYear,
        nonce: braintreeData.nonce,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        streetAddress: billingAddress.street,
        extendedAddress: billingAddress.apartmentNumber,
        locality: billingAddress.city,
        region: billingAddress.state,
        postalCode: billingAddress.zipCode,
      }
      dispatch(
        createBraintreeMembershipPayment({
          membershipToken,
          braintreePaymentData,
        }),
      )
    }
  }

  return (
    <form className={classes.container} ref={ref} onSubmit={handleSubmit}>
      <BraintreeField
        errorMessage={
          cardholderNameError ? t('Payment:NAME_ON_CARD_ERROR') : ''
        }
        fieldId="cardholder_name"
        labelText={t('Payment:NAME_ON_CARD')}
      />
      <BraintreeField
        errorMessage={cardNumberError ? t('Payment:CARD_NUMBER_ERROR') : ''}
        fieldId="card_number"
        labelText={t('Payment:CARD_NUMBER')}
      />
      <KibGrid>
        <KibGridItem span="4@min-xs 4@min-md">
          <BraintreeField
            errorMessage={
              expirationDateError ? t('Payment:EXPIRATION_DATE_ERROR') : ''
            }
            fieldId="expiration_date"
            labelText={t('Payment:EXPIRATION_DATE')}
          />
        </KibGridItem>

        <KibGridItem span="4@min-xs 4@min-md">
          <BraintreeField
            errorMessage={cvvError ? t('Payment:CVV_ERROR') : ''}
            fieldId="cvv"
            labelText={t('Payment:CVV')}
          />
        </KibGridItem>
      </KibGrid>
    </form>
  )
})
