import Flows from '../../constants/flows'

export const UPDATE_CURRENT_FLOW = 'flow/UPDATE_CURRENT_FLOW'
export const CLEAR_CURRENT_FLOW = 'flow/CLEAR_CURRENT_FLOW'
export const SET_EMBEDDED = 'flow/SET_EMBEDDED'

export const updateCurrentFlow = (flow) => ({ type: UPDATE_CURRENT_FLOW, flow })
export const clearCurrentFlow = () => ({ type: CLEAR_CURRENT_FLOW })
export const setEmbedded = (isEmbedded) => ({ type: SET_EMBEDDED, isEmbedded })

const INITIAL_STATE = {
  currentFlow: null,
  isEmbedded: false,
}

export const flowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_FLOW:
      return { ...state, currentFlow: action.flow }
    case SET_EMBEDDED:
      return { ...state, isEmbedded: action.isEmbedded }
    case CLEAR_CURRENT_FLOW:
      return { ...state, currentFlow: null }
    default:
      return state
  }
}

export const getFlow = (state) => state.flow
export const getCurrentFlow = (state) => getFlow(state).currentFlow
export const getIsCheckInFlow = (state) =>
  getCurrentFlow(state) === Flows.CHECK_IN
export const getIsJoinWaitlistFlow = (state) =>
  getCurrentFlow(state) === Flows.JOIN_WAITLIST
export const getIsScheduleAppointmentFlow = (state) =>
  getCurrentFlow(state) === Flows.SCHEDULE_APPOINTMENT
export const getIsEmbedded = (state) => getFlow(state).isEmbedded
