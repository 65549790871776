import { AnyAction } from 'redux'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import Features from '../../constants/features'
import { KioskClient } from '../../types/entities/clients'
import type { RootState } from '..'
// @ts-ignore
import requestAPI from '../sagas/requestAPI'
import { updateBraintreeToken } from './braintree'
import { updateCurrentBusiness } from './businesses'
import {
  FETCH_CLIENT_BY_EMAIL_SUCCESS,
  FETCH_CLIENT_BY_PHONE_SUCCESS,
  getCurrentClient,
  updateCurrentClient,
} from './clients'
import { getFeatureToggle } from './constants'
import {
  updatePaymentsPublicKey,
  updatePaymentsSecretKey,
  // @ts-ignore
} from './payments'

export const FETCH_WELLNESS_PLANS_VERSION =
  'wellnessPlans/FETCH_WELLNESS_PLANS_VERSION'
export const FETCH_WELLNESS_PLANS_VERSION_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLANS_VERSION_SUCCESS'
export const FETCH_WELLNESS_PLANS_VERSION_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLANS_VERSION_FAILURE'

export const UPDATE_MEMBERSHIP_BILLING_ADDRESSES =
  'wellnessPlans/UPDATE_MEMBERSHIP_BILLING_ADDRESSES'
export const UPDATE_MEMBERSHIP_BILLING_ADDRESS =
  'wellnessPlans/UPDATE_MEMBERSHIP_BILLING_ADDRESS'

export const SET_MEMBERSHIP_IS_PORTAL_EMBED =
  'wellnessPlans/SET_MEMBERSHIP_IS_PORTAL_EMBED'

export const FETCH_MEMBERSHIP_SIGN_UP_DATA =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA'
export const FETCH_MEMBERSHIP_SIGN_UP_DATA_SUCCESS =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA_SUCCESS'
export const FETCH_MEMBERSHIP_SIGN_UP_DATA_FAILURE =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA_FAILURE'

export const FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN'
export const FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_SUCCESS =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_SUCCESS'
export const FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_FAILURE =
  'wellnessPlans/FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_FAILURE'

export const UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION =
  'wellnessPlans/UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION'
export const UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS =
  'wellnessPlans/UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS'
export const UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE =
  'wellnessPlans/UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE'

export const STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION =
  'wellnessPlans/STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION'
export const STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS =
  'wellnessPlans/STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS'
export const STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE =
  'wellnessPlans/STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE'

export const CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS =
  'wellnessPlans/CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS'
export const CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_SUCCESS =
  'wellnessPlans/CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_SUCCESS'
export const CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_FAILURE =
  'wellnessPlans/CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_FAILURE'

export const CLEAR_MEMBERSHIP_SUBSCRIPTION_STATUS =
  'wellnessPlans/CLEAR_MEMBERSHIP_SUBSCRIPTION_STATUS'

export const SEND_WELCOME_EMAIL = 'wellnessPlans/SEND_WELCOME_EMAIL'
export const SEND_WELCOME_EMAIL_SUCCESS =
  'wellnessPlans/SEND_WELCOME_EMAIL_SUCCESS'
export const SEND_WELCOME_EMAIL_FAILURE =
  'wellnessPlans/SEND_WELCOME_EMAIL_FAILURE'

export const UPDATE_ACTIVE_SIGN_UP_FLOW =
  'wellnessPlans/UPDATE_ACTIVE_SIGN_UP_FLOW'

export const CLEAR_MEMBERSHIP_SELECTION =
  'wellnessPlans/CLEAR_MEMBERSHIP_SELECTION'
export const UPDATE_MEMBERSHIP_SELECTION =
  'wellnessPlans/UPDATE_MEMBERSHIP_SELECTION'

export const CLEAR_WELLNESS_PLANS = 'wellnessPlans/CLEAR_WELLNESS_PLANS'

export const CONFIRM_FREE_MEMBERSHIP_SIGN_UP =
  'wellnessPlans/CONFIRM_FREE_MEMBERSHIP_SIGN_UP'
export const CONFIRM_FREE_MEMBERSHIP_SIGN_UP_SUCCESS =
  'wellnessPlans/CONFIRM_FREE_MEMBERSHIP_SIGN_UP_SUCCESS'
export const CONFIRM_FREE_MEMBERSHIP_SIGN_UP_FAILURE =
  'wellnessPlans/CONFIRM_FREE_MEMBERSHIP_SIGN_UP_FAILURE'

export const UPDATE_PAYMENTS_BRAINTREE_TOKEN =
  'payments/UPDATE_PAYMENTS_BRAINTREE_TOKEN'

export const fetchWellnessPlansVersion = (businessId: string) => ({
  type: FETCH_WELLNESS_PLANS_VERSION,
  businessId,
})
export const fetchWellnessPlansVersionSuccess = (
  wellnessPlansVersion: string,
) => ({
  type: FETCH_WELLNESS_PLANS_VERSION_SUCCESS,
  wellnessPlansVersion,
})
export const fetchWellnessPlansVersionFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLANS_VERSION_FAILURE,
  error,
})

export const updateMembershipBillingAddresses = (addresses: string) => ({
  type: UPDATE_MEMBERSHIP_BILLING_ADDRESSES,
  addresses,
})
export const updateMembershipBillingAddress = (address: string) => ({
  type: UPDATE_MEMBERSHIP_BILLING_ADDRESS,
  address,
})

export const setMembershipIsPortalEmbed = (isPortalEmbed: boolean) => ({
  type: SET_MEMBERSHIP_IS_PORTAL_EMBED,
  isPortalEmbed,
})

export const fetchMembershipSignUpData = (clientId: string) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA,
  clientId,
})
export const fetchMembershipSignUpDataSuccess = (data: {
  token: string
  version: string
}) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA_SUCCESS,
  data,
})
export const fetchMembershipSignUpDataFailure = (error: ApiError) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA_FAILURE,
  error,
})

export const fetchMembershipSignUpDataForToken = (token: string) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN,
  token,
})
export const fetchMembershipSignUpDataForTokenSuccess = (data: {
  token: string
  version: string
}) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_SUCCESS,
  data,
})
export const fetchMembershipSignUpDataForTokenFailure = (error: ApiError) => ({
  type: FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_FAILURE,
  error,
})

export const updateMembershipSignUpTokenSelection = (
  token: string,
  selection: string,
) => ({
  type: UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION,
  token,
  selection,
})
export const updateMembershipSignUpTokenSelectionSuccess = (data: {
  free: boolean
}) => ({
  type: UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS,
  data,
})
export const updateMembershipSignUpTokenSelectionFailure = (
  error: ApiError,
) => ({
  type: UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE,
  error,
})

export const storeMembershipSignUpTokenSelection = (
  token: string,
  selection: string,
) => ({
  type: STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION,
  token,
  selection,
})
export const storeMembershipSignUpTokenSelectionSuccess = (data: {
  free: boolean
}) => ({
  type: STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS,
  data,
})
export const storeMembershipSignUpTokenSelectionFailure = (
  error: ApiError,
) => ({
  type: STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE,
  error,
})

export const checkMembershipSubscriptionStatus = (token: string) => ({
  type: CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS,
  token,
})
export const checkMembershipSubscriptionStatusSuccess = (status: string) => ({
  type: CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_SUCCESS,
  status,
})
export const checkMembershipSubscriptionStatusFailure = (error: ApiError) => ({
  type: CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_FAILURE,
  error,
})

export const clearMembershipSubscriptionStatus = () => ({
  type: CLEAR_MEMBERSHIP_SUBSCRIPTION_STATUS,
})

export const clearMembershipSelection = () => ({
  type: CLEAR_MEMBERSHIP_SELECTION,
})
export const updateMembershipSelection = (selection: SelectionType[]) => ({
  type: UPDATE_MEMBERSHIP_SELECTION,
  selection,
})

export const clearWellnessPlans = () => ({ type: CLEAR_WELLNESS_PLANS })

export const sendWelcomeEmail = (
  clientId: string,
  email: string,
  selection: SelectionType[],
) => ({
  type: SEND_WELCOME_EMAIL,
  clientId,
  email,
  selection,
})
export const sendWelcomeEmailSuccess = () => ({
  type: SEND_WELCOME_EMAIL_SUCCESS,
})
export const sendWelcomeEmailFailure = (error: ApiError) => ({
  type: SEND_WELCOME_EMAIL_FAILURE,
  error,
})

export const updateActiveSignUpFlow = (flow: string) => ({
  type: UPDATE_ACTIVE_SIGN_UP_FLOW,
  flow,
})

export const confirmFreeMembershipSignUp = (token: string) => ({
  type: CONFIRM_FREE_MEMBERSHIP_SIGN_UP,
  token,
})
export const confirmFreeMembershipSignUpSuccess = (status: string) => ({
  type: CONFIRM_FREE_MEMBERSHIP_SIGN_UP_SUCCESS,
  status,
})
export const confirmFreeMembershipSignUpFailure = (error: ApiError) => ({
  type: CONFIRM_FREE_MEMBERSHIP_SIGN_UP_FAILURE,
  error,
})
export const updatePaymentsBraintreeToken = (braintreeToken: string) => ({
  type: UPDATE_PAYMENTS_BRAINTREE_TOKEN,
  braintreeToken,
})

export type SelectionType = {
  patientId: string
  planId: string
  planName?: string
  priceTypeId: string
}

type WellnessPlansState = {
  activeSignUpFlow: string | null
  billingAddress: string | null
  billingAddresses: string[]
  braintreeToken: string | null
  error: string | null
  isFetching: boolean
  isFreeSignUp: boolean
  isLoading: boolean
  isMembershipSelectionStoring: boolean
  isPortalEmbed: boolean
  membershipSubscriptionStatus: string | null
  selection: SelectionType[]
  token: string | null
  wellnessPlansVersion: string | null
}

export const INITIAL_STATE: WellnessPlansState = {
  isPortalEmbed: false,
  isLoading: false,
  isFetching: false,
  isMembershipSelectionStoring: false,
  error: null,
  wellnessPlansVersion: null,
  billingAddresses: [],
  billingAddress: null,
  token: null,
  membershipSubscriptionStatus: null,
  selection: [],
  activeSignUpFlow: null,
  isFreeSignUp: false,
  braintreeToken: null,
}

export const wellnessPlansReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
): WellnessPlansState => {
  switch (action.type) {
    case FETCH_WELLNESS_PLANS_VERSION:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_WELLNESS_PLANS_VERSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        wellnessPlansVersion: action.wellnessPlansVersion,
      }
    case FETCH_WELLNESS_PLANS_VERSION_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetching: false,
      }
    case UPDATE_MEMBERSHIP_BILLING_ADDRESSES:
      return { ...state, billingAddresses: action.addresses }
    case UPDATE_MEMBERSHIP_BILLING_ADDRESS:
      return { ...state, billingAddress: action.address }
    case SET_MEMBERSHIP_IS_PORTAL_EMBED:
      return { ...state, isPortalEmbed: action.isPortalEmbed }
    case FETCH_MEMBERSHIP_SIGN_UP_DATA:
    case FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_MEMBERSHIP_SIGN_UP_DATA_SUCCESS:
    case FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        token: action.data.token,
        wellnessPlansVersion: action.data.version,
      }
    case SEND_WELCOME_EMAIL_FAILURE:
    case FETCH_MEMBERSHIP_SIGN_UP_DATA_FAILURE:
    case FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetching: false,
      }
    case UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION:
      return { ...state, isLoading: true, selection: action.selection }
    case UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFreeSignUp: action?.data?.free || false,
      }
    case UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION:
      return {
        ...state,
        isMembershipSelectionStoring: true,
        selection: action.selection,
      }
    case STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_SUCCESS:
      return {
        ...state,
        isMembershipSelectionStoring: false,
        isFreeSignUp: action?.data?.free || false,
      }
    case STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION_FAILURE:
      return {
        ...state,
        error: action.error,
        isMembershipSelectionStoring: false,
      }
    case CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS:
      return { ...state, isLoading: true }
    case CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        membershipSubscriptionStatus: action.status,
      }
    case CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case CLEAR_MEMBERSHIP_SUBSCRIPTION_STATUS:
      return { ...state, membershipSubscriptionStatus: null }
    case CLEAR_MEMBERSHIP_SELECTION:
      return { ...state, selection: [], isFreeSignUp: false }
    case UPDATE_MEMBERSHIP_SELECTION:
      return { ...state, selection: action.selection }
    case CLEAR_WELLNESS_PLANS:
      return {
        ...state,
        selection: [],
        isFreeSignUp: false,
        billingAddress: null,
        token: null,
        membershipSubscriptionStatus: null,
        billingAddresses: [],
      }
    case SEND_WELCOME_EMAIL:
      return { ...state, isLoading: true }
    case SEND_WELCOME_EMAIL_SUCCESS:
      return { ...state, isLoading: false }
    case FETCH_CLIENT_BY_EMAIL_SUCCESS:
    case FETCH_CLIENT_BY_PHONE_SUCCESS:
      return { ...state, billingAddress: null }
    case UPDATE_ACTIVE_SIGN_UP_FLOW:
      return { ...state, activeSignUpFlow: action.flow }
    case CONFIRM_FREE_MEMBERSHIP_SIGN_UP:
      return { ...state, isLoading: true }
    case CONFIRM_FREE_MEMBERSHIP_SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        membershipSubscriptionStatus: action.status,
      }
    case CONFIRM_FREE_MEMBERSHIP_SIGN_UP_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case UPDATE_PAYMENTS_BRAINTREE_TOKEN:
      return { ...state, braintreeToken: action.braintreeToken }
    default:
      return state
  }
}

export const getWellnessPlans = (state: RootState) => state.wellnessPlans
export const getWellnessPlansIsLoading = (state: RootState) =>
  getWellnessPlans(state)?.isLoading
export const getWellnessPlansIsFetching = (state: RootState) =>
  getWellnessPlans(state)?.isFetching
export const getWellnessPlansIsSelectionStoring = (state: RootState) =>
  getWellnessPlans(state)?.isMembershipSelectionStoring
export const getWellnessPlansError = (state: RootState) =>
  getWellnessPlans(state)?.error
export const getWellnessPlansVersion = (state: RootState) =>
  getWellnessPlans(state).wellnessPlansVersion
export const getMembershipIsPortalEmbed = (state: RootState) =>
  getWellnessPlans(state).isPortalEmbed
export const getMembershipBillingAddress = (state: RootState) =>
  getWellnessPlans(state).billingAddress
export const getMembershipBillingAddresses = (state: RootState) =>
  getWellnessPlans(state).billingAddresses
export const getMembershipToken = (state: RootState) =>
  getWellnessPlans(state).token
export const getMembershipSubscriptionStatus = (state: RootState) =>
  getWellnessPlans(state).membershipSubscriptionStatus
export const getMembershipSelection = (state: RootState) =>
  getWellnessPlans(state).selection
export const getMembershipActiveSignUpFlow = (state: RootState) =>
  getWellnessPlans(state).activeSignUpFlow
export const getMembershipIsFreeSignUp = (state: RootState) =>
  getWellnessPlans(state).isFreeSignUp
export const getPaymentsBraintreeToken = (state: RootState) =>
  getWellnessPlans(state).braintreeToken

export function* sagaFetchWellnessPlansVersion({
  businessId,
}: ReturnType<typeof fetchWellnessPlansVersion>) {
  try {
    const wellnessPlansVersion: string = yield call(
      API.fetchWellnessPlansVersion,
      businessId,
    )
    yield put(fetchWellnessPlansVersionSuccess(wellnessPlansVersion))
  } catch (error) {
    yield put(fetchWellnessPlansVersionFailure(error as ApiError))
  }
}

export function* sagaFetchMembershipSignUpData({
  clientId,
}: ReturnType<typeof fetchMembershipSignUpData>) {
  try {
    const { business, client, publicKey, ...rest } = yield* requestAPI(
      API.fetchMembershipSignUpData,
      clientId,
    )
    if (business) {
      yield put(updateCurrentBusiness(business))
    }
    if (client) {
      const currentClient: KioskClient = yield select(getCurrentClient) || {}
      yield put(updateCurrentClient({ ...currentClient, ...client }))
    }
    if (publicKey) {
      yield put(updatePaymentsPublicKey(publicKey))
    }
    yield put(fetchMembershipSignUpDataSuccess(rest))
  } catch (error) {
    yield put(fetchMembershipSignUpDataFailure(error as ApiError))
  }
}

export function* sagaFetchMembershipSignUpDataForToken({
  token,
}: ReturnType<typeof fetchMembershipSignUpDataForToken>) {
  try {
    const { business, client, publicKey, ...rest } = yield call(
      API.fetchMembershipSignUpDataForToken,
      token,
    )
    if (business) {
      yield put(updateCurrentBusiness(business))
    }
    if (client) {
      const currentClient: KioskClient = yield select(getCurrentClient) || {}
      yield put(updateCurrentClient({ ...currentClient, ...client }))
    }
    if (publicKey) {
      yield put(updatePaymentsPublicKey(publicKey))
    }
    yield put(fetchMembershipSignUpDataForTokenSuccess(rest))
  } catch (error) {
    yield put(fetchMembershipSignUpDataForTokenFailure(error as ApiError))
  }
}

export function* sagaUpdateMembershipSignUpTokenSelection({
  token,
  selection,
}: ReturnType<typeof updateMembershipSignUpTokenSelection>) {
  try {
    const { stripeSecret, publicKey, braintreeToken, ...rest } = yield call(
      API.updateMembershipSignUpTokenSelection,
      token,
      selection,
    )
    if (stripeSecret) {
      yield put(updatePaymentsSecretKey(stripeSecret))
    }
    if (publicKey) {
      yield put(updatePaymentsPublicKey(publicKey))
    }
    if (braintreeToken) {
      yield put(updateBraintreeToken(braintreeToken))
    }
    yield put(updateMembershipSignUpTokenSelectionSuccess(rest))
  } catch (error) {
    yield put(updateMembershipSignUpTokenSelectionFailure(error as ApiError))
  }
}

export function* sagaStoreMembershipSignUpTokenSelection({
  token,
  selection,
}: ReturnType<typeof storeMembershipSignUpTokenSelection>) {
  try {
    const { stripeSecret, publicKey, ...rest } = yield call(
      API.storeMembershipSignUpTokenSelection,
      token,
      selection,
    )
    if (stripeSecret) {
      yield put(updatePaymentsSecretKey(stripeSecret))
    }
    if (publicKey) {
      yield put(updatePaymentsPublicKey(publicKey))
    }
    yield put(storeMembershipSignUpTokenSelectionSuccess(rest))
  } catch (error) {
    yield put(storeMembershipSignUpTokenSelectionFailure(error as ApiError))
  }
}

export function* sagaCheckMembershipSubscriptionStatus({
  token,
}: ReturnType<typeof checkMembershipSubscriptionStatus>) {
  try {
    const status: string = yield call(
      API.checkMembershipSubscriptionStatus,
      token,
    )
    yield put(checkMembershipSubscriptionStatusSuccess(status))
  } catch (error) {
    yield put(checkMembershipSubscriptionStatusFailure(error as ApiError))
  }
}

export function* sagaSendWelcomeEmail({
  clientId,
  email,
  selection,
}: ReturnType<typeof sendWelcomeEmail>) {
  try {
    const isWelcomeEmailForOmnichannelEnabled: boolean = yield select(
      getFeatureToggle(Features.WELCOME_EMAIL_FOR_OMNICHANNEL),
    )
    yield call(
      isWelcomeEmailForOmnichannelEnabled
        ? API.sendMembershipWelcomeMessageV2
        : API.sendMembershipWelcomeMessage,
      clientId,
      email,
      selection,
    )
    yield put(sendWelcomeEmailSuccess())
  } catch (error) {
    yield put(sendWelcomeEmailFailure(error as ApiError))
  }
}

export function* sagaConfirmFreeMembershipSignUp({
  token,
}: ReturnType<typeof confirmFreeMembershipSignUp>) {
  try {
    const status: string = yield call(API.confirmFreeMembershipSignUp, token)
    yield put(confirmFreeMembershipSignUpSuccess(status))
  } catch (error) {
    yield put(confirmFreeMembershipSignUpFailure(error as ApiError))
  }
}

function* watchFetchWellnessPlansVersion() {
  yield takeLatest(FETCH_WELLNESS_PLANS_VERSION, sagaFetchWellnessPlansVersion)
}

function* watchFetchMembershipSignUpData() {
  yield takeLatest(FETCH_MEMBERSHIP_SIGN_UP_DATA, sagaFetchMembershipSignUpData)
}

function* watchFetchMembershipSignUpDataForToken() {
  yield takeLatest(
    FETCH_MEMBERSHIP_SIGN_UP_DATA_FOR_TOKEN,
    sagaFetchMembershipSignUpDataForToken,
  )
}

function* watchUpdateMembershipSignUpTokenSelection() {
  yield takeLatest(
    UPDATE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION,
    sagaUpdateMembershipSignUpTokenSelection,
  )
}

function* watchStoreMembershipSignUpTokenSelection() {
  yield takeLatest(
    STORE_MEMBERSHIP_SIGN_UP_TOKEN_SELECTION,
    sagaStoreMembershipSignUpTokenSelection,
  )
}

function* watchCheckMembershipSubscriptionStatus() {
  yield takeLatest(
    CHECK_MEMBERSHIP_SUBSCRIPTION_STATUS,
    sagaCheckMembershipSubscriptionStatus,
  )
}

function* watchSendWelcomeEmail() {
  yield takeLatest(SEND_WELCOME_EMAIL, sagaSendWelcomeEmail)
}

function* watchConfirmFreeMembershipSignUp() {
  yield takeLatest(
    CONFIRM_FREE_MEMBERSHIP_SIGN_UP,
    sagaConfirmFreeMembershipSignUp,
  )
}

export function* wellnessPlansSaga() {
  yield all([
    watchFetchWellnessPlansVersion(),
    watchFetchMembershipSignUpData(),
    watchFetchMembershipSignUpDataForToken(),
    watchUpdateMembershipSignUpTokenSelection(),
    watchStoreMembershipSignUpTokenSelection(),
    watchCheckMembershipSubscriptionStatus(),
    watchSendWelcomeEmail(),
    watchConfirmFreeMembershipSignUp(),
  ])
}
