import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Link, Theme, useMediaQuery } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
import KioskScreen from './KioskScreen'

const GdprTermsEsScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const business = useSelector(getCurrentBusiness)

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const agpdLink = (
    <Link href="https://www.aepd.es" rel="noopener noreferrer" target="_blank">
      www.agpd.es
    </Link>
  )

  const onProceed = () => {
    navigateWithQueryString({ url: '/client-details' })
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonLabel={t('Common:AGREE_ACTION')}
      proceedLabel={t(
        'Common:IF_YOU_DO_NOT_AGREE_PLEASE_CHECK_IN_WITH_TEAM_MEMBER',
      )}
      onProceed={onProceed}
    >
      <Grid container item p={isMobile ? 2 : 4}>
        <Text inline>
          {`Los datos personales recogidos en este documento serán recogidos por ${business?.name} ` +
            'con la finalidad de prestar los servicios veterinarios solicitados. La base jurídica que legitima este ' +
            'tratamiento es la relación contractual e interés legítimo del centro para hacer el seguimiento de los actos ' +
            'clínicos realizados y ofrecer servicios clínicos relacionados. El Centro veterinario conservará sus datos ' +
            'durante la vigencia de la relación contractual y, posteriormente mientras no hayan prescrito las responsabilidades ' +
            'de carácter legal o contractual derivadas de esta relación, manteniéndose debidamente bloqueados de manera que sólo ' +
            'estén disponibles para las administraciones públicas, los jueces y tribunales que los puedan requerir para atender ' +
            'las posibles responsabilidades. Los afectados podrán ejercer sus derechos de acceso, rectificación, ' +
            'cancelación/supresión, oposición, limitación de tratamiento y portabilidad a través de comunicación por escrito al ' +
            'domicilio indicado, aportando fotocopia de su DNI o documento equivalente e identificando el derecho que se ' +
            'solicita. Asimismo, en caso de considerar vulnerado su derecho a la protección de datos personales, podrán ' +
            'interponer una reclamación ante la Agencia Española de Protección de Datos '}
          ({agpdLink}).
        </Text>
      </Grid>
    </KioskScreen>
  )
}

export default GdprTermsEsScreen
