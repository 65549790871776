import React, { forwardRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  GridProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  ClassesType,
  ErrorTooltip,
  Text,
} from '@pbt/pbt-ui-components'

import KioskButtonWithLoader, {
  KioskButtonWithLoaderProps,
} from '../buttons/KioskButtonWithLoader'
import KioskLinkButton from '../buttons/KioskLinkButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 'calc(100% - 64px)',
      flex: 1,
      borderRadius: 4,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
    },
    title: {
      whiteSpace: 'pre-wrap',
      padding: theme.spacing(3, 5, 0),
      color: theme.colors.primaryText,
      fontSize: '2.6rem',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '1.6rem',
        padding: theme.spacing(2),
      },
    },
    content: {
      position: 'relative',
      height: `calc(100% - ${theme.spacing(12)})`,
      flex: 1,
    },
    wrapper: {
      height: `calc(100% - ${theme.spacing(8)})`,
      flex: 1,
    },
    confirmButton: {
      width: 220,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonsContainer: {
      zIndex: 2,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      padding: theme.spacing(2, 5, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2, 3),
      },
    },
    backButton: {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
      marginRight: theme.spacing(3),
      fontSize: '2.1rem',
    },
    additionalLabelContainer: {},
    backArrow: {
      fontSize: '3.4rem',
    },
    secondaryButton: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'KioskScreen' },
)

interface KioskScreenProps {
  readonly additionalLabel?: ReactNode
  readonly alignItems?: GridProps['alignItems']
  readonly children: React.ReactNode
  readonly classes?: ClassesType<typeof useStyles>
  readonly confirmButtonProps?: KioskButtonWithLoaderProps
  readonly errorMessage?: string
  readonly hideBackButton?: boolean
  readonly justifyContent?: GridProps['justifyContent']
  readonly onBack?: () => void
  readonly onBeforeBack?: () => void
  readonly onProceed?: () => void
  readonly onSecondaryButtonClick?: () => void
  readonly proceedButtonDisabled?: boolean
  readonly proceedButtonLabel?: string
  readonly proceedButtonLoading?: boolean
  readonly proceedLabel?: string
  readonly secondaryButtonDisabled?: boolean
  readonly secondaryButtonLabel?: string
  readonly title?: string
}

const KioskScreen = forwardRef<HTMLInputElement, KioskScreenProps>(
  function KioskScreen(
    {
      title,
      children,
      alignItems = 'center',
      justifyContent = 'center',
      onProceed,
      onSecondaryButtonClick,
      onBeforeBack,
      onBack,
      hideBackButton = false,
      secondaryButtonLabel,
      secondaryButtonDisabled = false,
      proceedButtonLabel,
      proceedButtonDisabled = false,
      proceedButtonLoading = false,
      errorMessage,
      additionalLabel,
      proceedLabel,
      classes: classesProp,
      confirmButtonProps = {},
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })
    const navigate = useNavigate()
    const { t } = useTranslation('Common')

    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('sm'),
    )
    const isTablet = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('md'),
    )

    const onBackButtonClick = () => {
      if (onBeforeBack) {
        onBeforeBack()
      }
      if (onBack) {
        onBack()
      } else {
        navigate(-1)
      }
    }

    return (
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        wrap="nowrap"
      >
        {title && <Typography className={classes.title}>{title}</Typography>}
        <Grid
          container
          item
          alignItems="center"
          className={classes.wrapper}
          direction="column"
        >
          <Grid
            container
            item
            alignItems={alignItems}
            className={classes.content}
            direction="column"
            justifyContent={justifyContent}
            ref={ref}
            wrap="nowrap"
          >
            {children}
          </Grid>
          {onProceed && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.buttonsContainer}
              direction="column"
            >
              {isMobile && additionalLabel && (
                <Grid
                  container
                  item
                  className={classes.additionalLabelContainer}
                >
                  {additionalLabel}
                </Grid>
              )}
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid item>
                  {!hideBackButton && (
                    <BackButton
                      classes={{
                        backArrow: classes.backArrow,
                        button: classes.backButton,
                      }}
                      label={t('Common:BACK_ACTION')}
                      onClick={onBackButtonClick}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  wrap="nowrap"
                >
                  {!isMobile && additionalLabel}
                  {!isTablet && !additionalLabel && proceedLabel && (
                    <Text maxWidth={270} px={2} variant="lowAccent2">
                      {proceedLabel}
                    </Text>
                  )}
                  {onSecondaryButtonClick && secondaryButtonLabel && (
                    <Grid item xs sm="auto">
                      <KioskLinkButton
                        className={classes.secondaryButton}
                        disabled={secondaryButtonDisabled}
                        onClick={onSecondaryButtonClick}
                      >
                        {secondaryButtonLabel}
                      </KioskLinkButton>
                    </Grid>
                  )}
                  <ErrorTooltip
                    message={errorMessage}
                    open={Boolean(errorMessage)}
                    placement="top"
                  >
                    <Grid item xs sm="auto">
                      <KioskButtonWithLoader
                        className={classes.confirmButton}
                        disabled={proceedButtonLoading || proceedButtonDisabled}
                        loading={proceedButtonLoading}
                        onClick={onProceed}
                        {...confirmButtonProps}
                      >
                        {proceedButtonLabel || t('Common:CONFIRM_ACTION')}
                      </KioskButtonWithLoader>
                    </Grid>
                  </ErrorTooltip>
                </Grid>
              </Grid>
              {isTablet && !additionalLabel && proceedLabel && (
                <Text mt={3} px={2} textAlign="center" variant="lowAccent2">
                  {proceedLabel}
                </Text>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  },
)

export default KioskScreen
