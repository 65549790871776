import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as API from '../../api'
import requestAPI from '../sagas/requestAPI'

export const FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN =
  'appointmentConfirmation/FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN'
export const FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_SUCCESS =
  'appointmentConfirmation/FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_SUCCESS'
export const FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_FAILURE =
  'appointmentConfirmation/FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_FAILURE'

export const EDIT_CONFIRMABLE_APPOINTMENT =
  'appointmentConfirmation/EDIT_CONFIRMABLE_APPOINTMENT'
export const EDIT_CONFIRMABLE_APPOINTMENT_SUCCESS =
  'appointmentConfirmation/EDIT_CONFIRMABLE_APPOINTMENT_SUCCESS'
export const EDIT_CONFIRMABLE_APPOINTMENT_FAILURE =
  'appointmentConfirmation/EDIT_CONFIRMABLE_APPOINTMENT_FAILURE'

export const CLEAR_APPOINTMENT_CONFIRMATION_ERROR =
  'appointmentConfirmation/CLEAR_APPOINTMENT_CONFIRMATION_ERROR'

export const fetchAppointmentConfirmationDataForToken = (
  businessId,
  token,
) => ({
  type: FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN,
  businessId,
  token,
})
export const fetchAppointmentConfirmationDataForTokenSuccess = (data) => ({
  type: FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_SUCCESS,
  data,
})
export const fetchAppointmentConfirmationDataForTokenFailure = (error) => ({
  type: FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_FAILURE,
  error,
})

export const editConfirmableAppointment = (appointment) => ({
  type: EDIT_CONFIRMABLE_APPOINTMENT,
  appointment,
})
export const editConfirmableAppointmentSuccess = (appointment) => ({
  type: EDIT_CONFIRMABLE_APPOINTMENT_SUCCESS,
  appointment,
})
export const editConfirmableAppointmentFailure = (error) => ({
  type: EDIT_CONFIRMABLE_APPOINTMENT_FAILURE,
  error,
})

export const clearAppointmentConfirmationError = () => ({
  type: CLEAR_APPOINTMENT_CONFIRMATION_ERROR,
})

export const INITIAL_STATE = {
  isLoading: false,
  isFetching: false,
  error: null,
  token: null,
  appointment: null,
}

export const appointmentConfirmationReducer = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        token: action.data.token,
        appointment: action.data.appointment,
      }
    case FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetching: false,
      }
    case EDIT_CONFIRMABLE_APPOINTMENT:
      return { ...state, isLoading: true }
    case EDIT_CONFIRMABLE_APPOINTMENT_SUCCESS:
      return { ...state, isLoading: false, appointment: action.appointment }
    case EDIT_CONFIRMABLE_APPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case CLEAR_APPOINTMENT_CONFIRMATION_ERROR:
      return { ...state, error: null }
    default:
      return state
  }
}

export const getAppointmentConfirmation = (state) =>
  state.appointmentConfirmation
export const getAppointmentConfirmationIsLoading = (state) =>
  getAppointmentConfirmation(state).isLoading
export const getAppointmentConfirmationIsFetching = (state) =>
  getAppointmentConfirmation(state).isFetching
export const getAppointmentConfirmationError = (state) =>
  getAppointmentConfirmation(state).error
export const getAppointmentConfirmationToken = (state) =>
  getAppointmentConfirmation(state).token
export const getAppointmentConfirmationAppointment = (state) =>
  getAppointmentConfirmation(state).appointment

export function* sagaFetchAppointmentConfirmationDataForToken({
  businessId,
  token,
}) {
  try {
    const data = yield call(
      API.fetchAppointmentConfirmationDataForToken,
      businessId,
      token,
    )
    const result = {
      ...data,
      token,
    }
    yield put(fetchAppointmentConfirmationDataForTokenSuccess(result))
  } catch (error) {
    yield put(fetchAppointmentConfirmationDataForTokenFailure(error))
  }
}

export function* sagaEditConfirmableAppointment({ appointment }) {
  try {
    const token = yield select(getAppointmentConfirmationToken)

    const result = yield* requestAPI(
      API.editConfirmableAppointment,
      appointment,
      token,
    )
    yield put(editConfirmableAppointmentSuccess(result))
  } catch (error) {
    yield put(editConfirmableAppointmentFailure(error))
  }
}

function* watchFetchAppointmentConfirmationDataForToken() {
  yield takeLatest(
    FETCH_APPOINTMENT_CONFIRMATION_DATA_FOR_TOKEN,
    sagaFetchAppointmentConfirmationDataForToken,
  )
}

function* watchEditConfirmableAppointment() {
  yield takeLatest(EDIT_CONFIRMABLE_APPOINTMENT, sagaEditConfirmableAppointment)
}

export function* appointmentConfirmationSaga() {
  yield all([
    watchFetchAppointmentConfirmationDataForToken(),
    watchEditConfirmableAppointment(),
  ])
}
