import React from 'react'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Utils } from '@pbt/pbt-ui-components'

import Features from '../../../../constants/features'
import i18nKiosk from '../../../../locales/i18n'
import {
  getFeatureToggle,
  getWellnessPlanPriceType,
} from '../../../../store/duck/constants'
import WellnessPlanPriceLabel from '../WellnessPlanPriceLabel'

const PaymentFrequencyType = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
}

const PaymentFrequencyTypeLabel = {
  [PaymentFrequencyType.ANNUAL]: i18nKiosk.t('Payment:PAY_YEARLY'),
  [PaymentFrequencyType.MONTHLY]: i18nKiosk.t('Payment:PAY_MONTHLY'),
}

const useStyles = makeStyles(
  (theme) => ({
    label: {
      flex: 1,
      fontSize: '1.6rem',
    },
    planLabel: {
      marginTop: theme.spacing(1.25),
    },
    priceText: {
      display: 'flex',
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
    },
    radioGroup: {
      flexWrap: 'nowrap',
    },
    radio: {
      padding: theme.spacing(0.75),
    },
  }),
  { name: 'WellnessPlanControlLabel' },
)

const WellnessPlanControlLabel = ({
  plan,
  priceTypeId,
  selected,
  wellnessPlanVersion,
  onPlanPriceTypeChange,
  ...rest
}) => {
  const classes = useStyles()

  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)
  const isWpMultiplePricesFeatureEnabled = useSelector(
    getFeatureToggle(Features.WP_MULTIPLE_PRICES),
  )

  const prices = plan.prices || []
  const shouldShowPrices =
    selected && prices.length > 1 && isWpMultiplePricesFeatureEnabled

  return (
    <FormControlLabel
      classes={{ label: classNames(classes.label, classes.planLabel) }}
      label={
        <>
          {plan.name}
          <WellnessPlanPriceLabel
            className={classes.priceText}
            plan={plan}
            wellnessPlansVersion={wellnessPlanVersion}
          />
          {shouldShowPrices && (
            <RadioGroup
              row
              className={classes.radioGroup}
              name={plan.id}
              value={priceTypeId}
              onChange={(_, value) => onPlanPriceTypeChange(value)}
            >
              {prices.map(({ priceTypeId }) => {
                const PriceType = Utils.findById(
                  priceTypeId,
                  WellnessPlanPriceType,
                )

                return (
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    control={<Radio className={classes.radio} />}
                    key={priceTypeId}
                    label={PaymentFrequencyTypeLabel[PriceType?.name]}
                    value={priceTypeId}
                  />
                )
              })}
            </RadioGroup>
          )}
        </>
      }
      value={plan.id}
      {...rest}
    />
  )
}

export default WellnessPlanControlLabel
