import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, moment, Utils } from '@pbt/pbt-ui-components'

import {
  editConfirmableAppointment,
  getAppointmentConfirmationAppointment,
  getAppointmentConfirmationIsFetching,
  getAppointmentConfirmationIsLoading,
} from '../../../store/duck/appointmentConfirmation'
import { getEventState } from '../../../store/duck/constants'
import { useNavigateWithQueryString } from '../../../utils'
import { getPatchedAppointment } from '../../../utils/appointmentUtils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'
import KioskLinkButton from '../../buttons/KioskLinkButton'
import AppointmentInfoSection from '../../common/AppointmentInfoSection'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(11, 4, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    linkButtonsContainer: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    button: {
      width: 224,
      marginTop: theme.spacing(5),
    },
    linkButton: {
      fontSize: '1.8rem',
    },
    progress: {
      marginTop: theme.spacing(5),
    },
  }),
  { name: 'AppointmentConfirmationWelcomeScreen' },
)

const AppointmentConfirmationWelcomeScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const isFetching = useSelector(getAppointmentConfirmationIsFetching)
  const isLoading = useSelector(getAppointmentConfirmationIsLoading)
  const appointment = useSelector(getAppointmentConfirmationAppointment)
  const EventState = useSelector(getEventState)

  const ConfirmedStateId = Utils.findConstantIdByName('Confirmed', EventState)
  const CancelledStateId = Utils.findConstantIdByName('Cancelled', EventState)

  const appointmentStart = moment(appointment?.scheduledStartDatetime)

  const date = appointmentStart.format(DateFormat.DAY_OF_WEEK_MONTH_DAY)
  const time = appointmentStart.format(DateFormat.FULL_TIME_WITH_MERIDIAN)

  const onAppointmentConfirmed = () => {
    navigateWithQueryString({ url: '/appointment-confirmation/confirmed' })
  }

  const onAppointmentCancelled = (isSelfCancellation = true) => {
    navigateWithQueryString({
      url: '/appointment-confirmation/cancelled',
      options: { state: { isSelfCancellation } },
    })
  }

  useEffect(() => {
    if (
      !isLoading &&
      appointment &&
      appointment.state.id === CancelledStateId
    ) {
      onAppointmentCancelled(false)
    }
  }, [isLoading, appointment])

  const setCallbackWhenConfirmedAppointment = useCallbackWhenLoaded(
    onAppointmentConfirmed,
    getAppointmentConfirmationIsLoading,
  )
  const setCallbackWhenCancelledAppointment = useCallbackWhenLoaded(
    onAppointmentCancelled,
    getAppointmentConfirmationIsLoading,
  )

  const handleConfirm = () => {
    setCallbackWhenConfirmedAppointment()

    const newAppointment = {
      ...getPatchedAppointment(appointment),
      state: ConfirmedStateId,
    }
    dispatch(editConfirmableAppointment(newAppointment))
  }

  const handleReschedule = () => {
    navigateWithQueryString({ url: '/appointment-confirmation/reschedule' })
  }

  const handleCancel = () => {
    setCallbackWhenCancelledAppointment()

    const newAppointment = {
      ...getPatchedAppointment(appointment),
      state: CancelledStateId,
    }
    dispatch(editConfirmableAppointment(newAppointment))
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      title={
        appointment
          ? t('Common:YOUR_APPOINTMENT_IS_SCHEDULED_FOR_DATE_AT_TIME', {
              date,
              time,
            })
          : ''
      }
    >
      {isFetching ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid
          container
          item
          alignItems="center"
          className={classes.root}
          justifyContent="center"
        >
          <AppointmentInfoSection appointment={appointment} />
          <KioskButtonWithLoader
            className={classes.button}
            loading={isLoading}
            onClick={handleConfirm}
          >
            {t('Common:CONFIRM_ACTION')}
          </KioskButtonWithLoader>
          <Grid
            container
            item
            className={classes.linkButtonsContainer}
            justifyContent="center"
            spacing={3}
            wrap="nowrap"
          >
            <KioskLinkButton
              className={classes.linkButton}
              color="secondary"
              onClick={handleReschedule}
            >
              {t('Common:RESCHEDULE_ACTION')}
            </KioskLinkButton>
            <KioskLinkButton
              className={classes.linkButton}
              color="secondary"
              loading={isLoading}
              onClick={handleCancel}
            >
              {t('Common:CANCEL_ACTION')}
            </KioskLinkButton>
          </Grid>
        </Grid>
      )}
    </KioskScreen>
  )
}

export default AppointmentConfirmationWelcomeScreen
