import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

// @ts-ignore
import * as API from '../../api'
import { KioskBusiness as Business } from '../../types/entities/business'
import type { RootState } from '../index'

export const FETCH_BUSINESS = 'businesses/FETCH_BUSINESS'
export const FETCH_BUSINESS_SUCCESS = 'businesses/FETCH_BUSINESS_SUCCESS'
export const FETCH_BUSINESS_FAILURE = 'businesses/FETCH_BUSINESS_FAILURE'

export const UPDATE_CURRENT_BUSINESS = 'businesses/UPDATE_CURRENT_BUSINESS'

export const fetchBusiness = (businessId: string) => ({
  type: FETCH_BUSINESS,
  businessId,
})
export const fetchBusinessSuccess = (business: Partial<Business>) => ({
  type: FETCH_BUSINESS_SUCCESS,
  business,
})
export const fetchBusinessFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_FAILURE,
  error,
})

export const updateCurrentBusiness = (business: Partial<Business>) => ({
  type: UPDATE_CURRENT_BUSINESS,
  business,
})

export type BusinessesState = {
  currentBusiness: Business | null
  error: string | null
  isLoading: boolean
}

type NPSBusinessProps = {
  address1?: string
  address2?: string
  businessId: string
  primaryPhone?: string
  secondaryPhone?: string
}

const INITIAL_STATE: BusinessesState = {
  currentBusiness: null,
  error: null,
  isLoading: false,
}

const mapBusiness = (business: Business & NPSBusinessProps) => ({
  ...business,
  phone: business.phone || business.primaryPhone || business.secondaryPhone,
  address: business.address || business.address1 || business.address2,
  id: business.id || business.businessId,
})

export const businessesReducer = (
  state: BusinessesState = INITIAL_STATE,
  action: AnyAction,
): BusinessesState => {
  switch (action.type) {
    case FETCH_BUSINESS:
      return { ...state, isLoading: true }
    case FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentBusiness: mapBusiness(action.business),
      }
    case FETCH_BUSINESS_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case UPDATE_CURRENT_BUSINESS:
      return {
        ...state,
        currentBusiness: { ...state.currentBusiness, ...action.business },
      }
    default:
      return state
  }
}

export const getBusinesses = (state: RootState): BusinessesState =>
  state.businesses
export const getCurrentBusiness = (state: RootState) =>
  getBusinesses(state).currentBusiness
export const getCurrentBusinessButtonsColor = (state: RootState) =>
  getCurrentBusiness(state)?.buttonsColor
export const getCurrentBusinessHasOpenBoop = (state: RootState) =>
  getCurrentBusiness(state)?.openBoop
export const getCurrentBusinessId = (state: RootState) =>
  getBusinesses(state).currentBusiness?.id
export const getCurrentBusinessTimeZone = (state: RootState) =>
  getCurrentBusiness(state)?.timeZone
export const getCurrentBusinessLanguageCodes = (state: RootState) =>
  getCurrentBusiness(state)?.supportedLanguageCodes || []
export const getCurrentBusinessIsOmniChannel = (state: RootState) =>
  getCurrentBusiness(state)?.omniChannel
export const getBusinessesIsLoading = (state: RootState) =>
  getBusinesses(state).isLoading
export const getBusinessError = (state: RootState) => getBusinesses(state).error

export function* sagaFetchBusiness({
  businessId,
}: ReturnType<typeof fetchBusiness>) {
  try {
    const business: Business = yield call(API.fetchBusiness, businessId)
    yield put(fetchBusinessSuccess(business))
  } catch (error) {
    yield put(fetchBusinessFailure(error as ApiError))
  }
}

function* watchFetchBusiness() {
  yield takeLatest(FETCH_BUSINESS, sagaFetchBusiness)
}

export function* businessesSaga() {
  yield all([watchFetchBusiness()])
}
