import React from 'react'

import EmailScreen from '../EmailScreen'

const MembershipEmailScreen = (props) => (
  <EmailScreen
    enableUpdateClientFlow={false}
    failureRoute="/membership/not-found"
    successRoute="/membership/sign-up"
    {...props}
  />
)

export default MembershipEmailScreen
